import moment from 'moment';
import PreApplication from '../value-objects/pre-application';
import User from '../value-objects/user';
import { DifferentialUtil } from '../../utils/differential-utils';

/**
 * 予約情報のプロパティ
 */
export interface ReservationProperties {
  visitDateTime: Date;
  location: string;
  remark?: string;
  reservationNumber?: string;
  user?: User;
  preApplication?: PreApplication;
}

/**
 * 予約情報
 */
export default class Reservation {
  private constructor(
    private readonly _visitDateTime: Date, // 来庁日時
    private readonly _location: string, // 来庁場所
    private _remark: string, // 備考
    private readonly _reservationNumber: string | null, // 予約番号
    private readonly _user: User | null, // ユーザー
    private readonly _preApplication: PreApplication | null // 事前申請情報
  ) {}

  /**
   * 同一オブジェクトとの比較
   * @param other 比較対象
   * @returns 一致:true、不一致:false
   */
  equals(other?: Reservation | null): boolean {
    if (other == null) {
      return false;
    }

    // 必須プロパティの比較
    const exactRequired =
      this._visitDateTime.getTime() === other.visitDateTime.getTime() &&
      this._location === other.location;

    // オプショナルプロパティの比較
    const exactReservationNumber = DifferentialUtil.exactOptional(
      this._reservationNumber,
      other.reservationNumber
    );
    const exactUser = DifferentialUtil.exactExistence(this._user, other.user)
      ? true
      : this._user == null
      ? false
      : this._user.equals(other?.user);
    const exactPreApplication = DifferentialUtil.exactExistence(
      this._preApplication,
      other.preApplication
    )
      ? true
      : this._preApplication == null
      ? false
      : this._preApplication.equals(other?.preApplication);

    return exactRequired && exactReservationNumber && exactUser && exactPreApplication;
  }

  /**
   * 予約番号
   */
  get reservationNumber(): string | null {
    return this._reservationNumber ?? null;
  }

  /**
   * 来庁日時
   */
  get visitDateTime(): Date {
    return new Date(this._visitDateTime);
  }

  /**
   * 来庁日時で画面表示用の文字列を作成する
   */
  createViewDateTimeText(): string {
    const dateTime = moment(this._visitDateTime.getTime());
    const weeks = ['日', '月', '火', '水', '木', '金', '土'];
    const weekNumber = Number(dateTime.format('d'));
    const yyyymmdd = dateTime.format('yyyy年MM月DD日');
    const hhmm = dateTime.format('HH時mm分');
    return `${yyyymmdd}(${weeks[weekNumber]}) ${hhmm}`;
  }

  /**
   * 来庁日時で文字列を作成する
   */
  createDateTimeText(format: string = 'yyyy-MM-DD HH:mm'): string {
    return moment(this._visitDateTime.getTime()).format(format);
  }

  /**
   * 来庁日だけを文字列で取得する
   */
  createDateText(): string {
    return moment(this._visitDateTime.getTime()).format('yyyy-MM-DD');
  }

  /**
   * 来庁日時＋タイムゾーンで文字列で取得する
   */
  createAddDateTimeText(): string {
    return `${moment(this._visitDateTime.getTime()).format('yyyy-MM-DD HH:mm Z')}`;
  }

  /**
   * 来庁時間だけを文字列で取得する
   */
  createTimeText(): string {
    return moment(this._visitDateTime.getTime()).format('HH:mm');
  }

  /**
   * 予約が変更可能期限切れかどうか
   */
  isExpired(): boolean {
    // 前日の23時59分まで変更可能
    const nowtime = new Date().getTime();
    const expiredtime = moment(this._visitDateTime.getTime())
      .subtract(1, 'day')
      .toDate()
      .setHours(23, 59, 59, 999);

    return nowtime > expiredtime;
  }

  /**
   * 来庁場所
   */
  get location(): string {
    return this._location;
  }

  /**
   * 備考
   */
  get remark(): string {
    return this._remark;
  }
  set remark(value: string) {
    this._remark = value;
  }

  /**
   * ユーザー
   */
  get user(): User | null {
    return this._user ? User.copy(this._user) : null;
  }

  /**
   * 事前申請情報
   */
  get preApplication(): PreApplication | null {
    return this._preApplication ? PreApplication.copy(this._preApplication) : null;
  }

  /**
   * 新規作成
   */
  static create(properties: ReservationProperties) {
    return new Reservation(
      new Date(properties.visitDateTime),
      properties.location,
      properties.remark ?? '',
      properties.reservationNumber ?? null,
      properties.user ? User.copy(properties.user) : null,
      properties.preApplication ? PreApplication.copy(properties.preApplication) : null
    );
  }

  /**
   * コピー
   */
  static copy(previos: Reservation) {
    return this.create({
      visitDateTime: previos.visitDateTime,
      location: previos.location,
      remark: previos.remark,
      reservationNumber: previos.reservationNumber ?? undefined,
      user: previos.user ?? undefined,
      preApplication: previos.preApplication ?? undefined,
    });
  }
}
