import moment from 'moment';

/**
 * 権限（役割）
 */
export type Role = 'general' | 'administrator';

export interface StaffProperties {
  userName: string;
  password: string;
  permission: Role;
  publicEntityCode: string;
  expirationFrom: Date;
  expirationTo: Date;
}

/**
 * 職員
 */
export class Staff {
  /**
   * コンストラクタ
   * @param _userName ユーザー名
   * @param _password パスワード
   * @param _permission 権限
   * @param _publicEntityCode 自治体コード
   * @param _expirationFrom 有効期限開始日時
   * @param _expirationTo 有効期限終了日時
   */
  private constructor(
    private _userName: string, // ログインID
    private _password: string, // パスワード
    private _permission: Role, // 権限
    private _publicEntityCode: string, // 自治体コード
    private _expirationFrom: Date, // 有効期限開始日時
    private _expirationTo: Date // 有効期限終了日時
  ) {}

  /**
   * ログインID取得
   */
  get loginId(): string {
    return Staff.extractionLoginIdFromUserName(this._userName);
  }

  /**
   * ユーザー名取得
   */
  get userName(): string {
    return this._userName;
  }

  /**
   * ユーザー名更新
   */
  set userName(userName: string) {
    this._userName = userName;
  }

  /**
   * パスワード
   */
  get password(): string {
    return this._password;
  }

  /**
   * パスワードの更新
   */
  set password(password: string) {
    this._password = password;
  }
  /**
   * 権限
   */
  get permission(): Role {
    return this._permission;
  }
  /**
   * 権限の更新
   */
  set permission(permission: Role) {
    this._permission = permission;
  }
  /**
   * 自治体コード
   */
  get publicEntityCode(): string {
    return this._publicEntityCode;
  }
  /**
   * 有効期限開始日時
   */
  get expirationFrom(): Date {
    return this._expirationFrom;
  }

  /**
   * 有効期限開始日＋タイムゾーンで文字列で取得する
   */
  createExpirationFromDateText(): string {
    return `${moment(this._expirationFrom.getTime()).format('yyyy/MM/DD HH:mm Z')}`;
  }

  /**
   * 有効期限開始日表示用の文字列で取得する
   */
  createExpirationFromDateViewText(): string {
    return `${moment(this._expirationFrom.getTime()).format('yyyy-MM-DD')}`;
  }

  /**
   * 有効期限開始日時の更新
   */
  set expirationFrom(expirationFrom: Date) {
    this._expirationFrom = expirationFrom;
  }
  /**
   * 有効期限終了日時
   */
  get expirationTo(): Date {
    return this._expirationTo;
  }
  /**
   * 有効期限終了日＋タイムゾーンで文字列で取得する
   */
  createExpirationToDateText(): string {
    return `${moment(this._expirationTo.getTime()).format('yyyy/MM/DD HH:mm Z')}`;
  }
  /**
   * 有効期限終了日表示用の文字列で取得する
   */
  createExpirationToDateViewText(): string {
    return `${moment(this._expirationTo.getTime()).format('yyyy-MM-DD')}`;
  }

  /**
   * 有効期限終了日時
   */
  set expirationTo(expirationTo: Date) {
    this._expirationTo = expirationTo;
  }

  /**
   * 同一オブジェクトとの比較
   * @param other 比較対象
   * @returns 一致:true、不一致:false
   */
  equals(other?: StaffProperties | null): boolean {
    if (other == null) {
      return false;
    }
    return (
      this._userName === other.userName &&
      this._password === other.password &&
      this._permission === other.permission &&
      this._publicEntityCode === other.publicEntityCode &&
      this._expirationFrom === other.expirationFrom &&
      this._expirationTo === other.expirationTo
    );
  }

  /**
   * 新規作成
   * @param properties Staffプロパティ
   * @returns 職員情報
   */
  static create(properties: StaffProperties) {
    return new Staff(
      properties.userName,
      properties.password,
      properties.permission,
      properties.publicEntityCode,
      properties.expirationFrom,
      properties.expirationTo
    );
  }

  /**
   * コピーを作成
   */
  static copy(previos: StaffProperties) {
    return new Staff(
      previos.userName,
      previos.password,
      previos.permission,
      previos.publicEntityCode,
      previos.expirationFrom,
      previos.expirationTo
    );
  }

  /**
   * 指定されたログインIDに自治体コードを付与する
   * @param publicEntityCode
   * @param loginId
   */
  static generateUserName(publicEntityCode: string, loginId: string): string {
    return `${publicEntityCode}|${loginId}`;
  }

  /**
   * ユーザー名からログインIDを抽出する
   * @param userName
   */
  static extractionLoginIdFromUserName(userName: string): string {
    // ログインIDは必ず'|'を区切り文字で自治体コードとセットになっているものとする
    const name = userName.split('|');
    return name.length > 1 ? name[1] : name[0];
  }
}
