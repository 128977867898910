import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Session } from '../../../../libs/domains/entitis/session';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PageUrl } from '../../../../libs/domains/services/page-url';
import CommonDialog from '../../molecule/common-dialog';
import { Stack } from '@mui/material';
import ExecuteButton from '../../molecule/execute-button';
import { buttonWidth } from '../../../styles/size';
import { ToParent } from '../../../../libs/domains/services/to-parent';
import ClosePageDaialog from '../close-page-daialog';
const { QUERY_KEYS, ENDPOINT } = PageUrl;

interface PropsType {
  isSessionCleanUp?: boolean;
}

const SessionControl = ({ isSessionCleanUp }: PropsType) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const userId = searchParams.get(QUERY_KEYS.USER_ID) ?? '';
  const sessionManager = useMemo(
    () => Session.create(isSessionCleanUp ?? true),
    [isSessionCleanUp]
  );

  const [events] = useState(['click', 'load', 'scroll']);
  const [isPreTimeOut, setIsPreTimeOut] = useState(false);
  const [isTimeOut, setIsTimeOut] = useState(false);
  const [isOpenPreTimeOut, setIsOpenPreTimeOut] = useState(false);
  const [isOpenTimeOut, setIsOpenTimeOut] = useState(false);
  const [isNotExtendTime, setIsNotExtendTime] = useState(false);

  // セッション時間を更新する
  const updateValidityTime = useCallback(() => {
    if (sessionManager.isTimeout(userId) || sessionManager.isPreTimeout(userId)) {
      return;
    }
    sessionManager.updateValidityTime(userId, new Date());
  }, [sessionManager, userId]);

  const onContinue = useCallback(
    (isContinue: boolean) => {
      setIsOpenPreTimeOut(false);
      if (!isContinue || isTimeOut) {
        setIsNotExtendTime(true);
        if (isTimeOut) {
          setIsOpenTimeOut(true);
        }
        return;
      }
      sessionManager.updateValidityTime(userId, new Date());
      setIsPreTimeOut(false);
      setIsTimeOut(false);
    },
    [sessionManager, setIsTimeOut, setIsPreTimeOut, isTimeOut, userId, setIsOpenTimeOut]
  );

  const onClose = useCallback(
    () => (event: React.MouseEvent<HTMLInputElement>, reason: 'backdropClick') => {
      if (reason === 'backdropClick') {
        // バックドロップのクリックを無効化
        return;
      }
    },
    []
  );

  // 「閉じる」警告を開くかどうか
  const [isCloseWarningOpen, setIsCloseWarningOpen] = useState<boolean>(false);
  const onTimeOutClose = useCallback(() => {
    ToParent.requestClosing();
    if (window.history.length > 1) {
      setIsCloseWarningOpen(true);
    } else {
      // 閉じる
      window.close();
    }
  }, []);

  // 初期化
  useEffect(() => {
    if (sessionManager.readToken(userId) == null) {
      // 未認証の場合はアクセスできない
      navigate(ENDPOINT.RESERVATION_SESSION_TIMEOUT, { replace: true });
    }

    // 操作イベントを受けるごとに時間を保存していく
    events.forEach((event) => {
      window.addEventListener(event, updateValidityTime);
    });

    // セッションタイムアウトかどうかをチェック
    const timer = window.setInterval(() => {
      setIsPreTimeOut(sessionManager.isPreTimeout(userId));
      setIsTimeOut(sessionManager.isTimeout(userId));
    }, 1000);

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, updateValidityTime);
      });
      window.clearInterval(timer);
    };
  }, [userId, events, navigate, sessionManager, updateValidityTime]);

  // セッション更新するかどうか
  useEffect(() => {
    if (isTimeOut) {
      if (isOpenPreTimeOut) {
        // 事前確認表示中はそのまま表示を継続
        return;
      }
      // タイムアウトの場合は強制でタイムアウトをダイアログ表示
      setIsOpenTimeOut(isTimeOut);
      return;
    }
    if (isNotExtendTime) {
      // 延長しない場合は確認ダイアログの表示判定をしない
      return;
    }
    // 確認ダイアログ表示判定
    setIsOpenTimeOut(isTimeOut);
    setIsOpenPreTimeOut(isPreTimeOut);
  }, [isPreTimeOut, isTimeOut, isOpenPreTimeOut, isNotExtendTime]);

  return (
    <>
      {/* 継続確認 */}
      <CommonDialog
        open={isOpenPreTimeOut}
        onClose={onClose}
        content={
          <Stack
            className="w-full h-full pt-8"
            justifyContent="space-between"
            alignItems="center"
            spacing={6}
          >
            <Stack spacing={1}>
              <div>もうすぐ10分経過いたします。</div>
              <div>セッションタイムを延長しますか？</div>
            </Stack>
            <Stack
              className="w-full flex-wrap"
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <ExecuteButton
                className="mx-2 my-1"
                text="いいえ"
                onClick={() => onContinue(false)}
                isPrimary={false}
                width={buttonWidth}
              />
              <ExecuteButton
                className="mx-2 my-1"
                text="はい"
                onClick={() => onContinue(true)}
                isPrimary={true}
                width={buttonWidth}
              />
            </Stack>
          </Stack>
        }
      />
      {/* セッション切れ */}
      <CommonDialog
        open={isOpenTimeOut}
        onClose={onClose}
        content={
          <Stack
            className="w-full h-full pt-8"
            justifyContent="center"
            alignItems="center"
            spacing={6}
          >
            <Stack spacing={1}>
              <div>セッションが切れました。ページを閉じてください。</div>
            </Stack>
            <Stack
              className="w-full flex-wrap"
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={6}
            >
              <ExecuteButton
                className="mx-2 my-1"
                text="はい"
                onClick={onTimeOutClose}
                isPrimary={true}
                width={buttonWidth}
              />
            </Stack>
          </Stack>
        }
      />
      <ClosePageDaialog isOpen={isCloseWarningOpen} setIsOpen={setIsCloseWarningOpen} />
    </>
  );
};

export default SessionControl;
