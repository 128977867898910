import { AppBar, Stack } from '@mui/material';
import React, { ReactNode } from 'react';
import ReservationHeader from '../reservation-header';
import ReservationFooter from '../reservation-footer';
import style from './InsideFrame.module.scss';

interface Props {
  children: ReactNode;
}

const InsideFrame = ({ children }: Props) => {
  return (
    <>
      <Stack className={style.frame}>
        <AppBar className="grow-0" position="sticky">
          <ReservationHeader />
        </AppBar>
        <Stack className={`grow ${style.inbody}`} spacing={4}>
          <div className="py-8 px-4 grow-0">{children}</div>
          <div className="grow bg-blue-900">
            <ReservationFooter />
          </div>
        </Stack>
      </Stack>
    </>
  );
};

export default InsideFrame;
