import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useContainer } from 'inversify-react';
import { InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField } from '@mui/material';
import Calendar from 'react-calendar';
import { Value } from 'react-calendar/dist/cjs/shared/types';
import moment from 'moment';

import ExecuteButton from '../../molecule/execute-button/ExecuteButton';
import ReservationSlotUsecase from '../../../../libs/usecases/interfaces/reservation-slot.usecase.interface';
import ReservationSlot from '../../../../libs/domains/entitis/reservation-slot';
import { TYPES } from '../../../../types';
import { useReservationContext } from '../../../contexts/revervation-context';
import Reservation from '../../../../libs/domains/entitis/reservation';
import { useIsWaitingContext } from '../../../contexts/is-waiting-context';
import SettingsLocationUsecase from '../../../../libs/usecases/interfaces/settings-location.usecase.interface';
import { useIsChangeReservationContext } from '../../../contexts/is-change-reservation-context';
import { usePreReservationContext } from '../../../contexts/pre-revervation-context';
import CommonDialog from '../../molecule/common-dialog';
import { PageUrl } from '../../../../libs/domains/services/page-url';
import { useAgentIdContext } from '../../../contexts/agent-id-context';
import { useSelectedManagementPageViewContext } from '../../../contexts/selected-managemaent-page-view-context';
import { ErrorHandler } from '../../../../libs/domains/services/error-handler';
import { buttonWidth } from '../../../styles/size';
import { useIsPreparedReservationContext } from '../../../contexts/is-prepared-reservation-context';
import SessionControl from '../../organism/session-control';
import SettingsReservationableTimeUsecase from '../../../../libs/usecases/interfaces/settings-reservationable-time.usecase.interface';
import SettingsCounterUniqueClosingDaysUsecase from '../../../../libs/usecases/interfaces/settings-counter-unique-closing-days.usecase.interface';
import { TimeControl } from '../../../../libs/utils/time-control';
import RemarkField from '../../molecule/remark-field/RemarkField';
const { QUERY_KEYS, ENDPOINT } = PageUrl;

const ReservationEditPage = () => {
  const navigate = useNavigate();
  const container = useContainer();
  const [searchParams] = useSearchParams();

  // 代理予約者
  const { agentId } = useAgentIdContext();

  const userId = searchParams.get(QUERY_KEYS.USER_ID) ?? agentId ?? '';
  const publicEntityCode = searchParams.get(QUERY_KEYS.PUBLIC_ENTITY_CODE) ?? '';
  if (userId === '' || publicEntityCode === '') {
    navigate(ENDPOINT.NOT_FOUND, { replace: true });
  }
  const queryPublicEntityCode = {
    key: QUERY_KEYS.PUBLIC_ENTITY_CODE,
    value: publicEntityCode,
  };
  const queryUserId = {
    key: QUERY_KEYS.USER_ID,
    value: userId,
  };

  // 予約前準備完了しているかどうか
  const { isPreparedReservation } = useIsPreparedReservationContext();
  if (!isPreparedReservation) {
    // 事前準備のページへ飛ばす
    navigate(PageUrl.generate(ENDPOINT.RESERVATION, queryPublicEntityCode, queryUserId), {
      replace: true,
    });
  }

  // 再予約かどうか
  const { isChangeReservation } = useIsChangeReservationContext();

  // 管理画面の表示内容
  const { setSelectedView } = useSelectedManagementPageViewContext();

  // 予約可能期間
  const settingsRereservationableTimeUsecase = useMemo(
    () => container.get<SettingsReservationableTimeUsecase>(TYPES.SettingsReservationableTime),
    [container]
  );
  const [startDate, setStartDate] = useState<number>(-1);

  // 窓口閉鎖と営業日
  const settingsCounterUniqueClosingDaysUsecase = useMemo(
    () =>
      container.get<SettingsCounterUniqueClosingDaysUsecase>(
        TYPES.SettingsCounterUniqueClosingDays
      ),
    [container]
  );

  // 予約枠
  const reservationSlotUsecase = useMemo(
    () => container.get<ReservationSlotUsecase>(TYPES.ReservationSlot),
    [container]
  );
  const [emptySlotTimes, setEmptySlotTimes] = useState<number[]>([]);

  // 予約情報
  const { reservation, setReservation } = useReservationContext();
  const { preReservation } = usePreReservationContext();

  // 読み込み中
  const { setIsWaiting } = useIsWaitingContext();

  // 選択された日付の初期値
  const defaultSelectedDate = reservation
    ? new Date(reservation.createDateText().replace(/-/g, '/'))
    : null;

  // 選択された日付
  const [selectedDay, setSelectedDay] = useState<Value>(defaultSelectedDate);

  // 来庁場所
  const settingsLocationUsecase = container.get<SettingsLocationUsecase>(TYPES.SettingsLocation);
  const [locations, setLocations] = useState<string[]>([]);
  const [location, setLocation] = useState(reservation ? reservation.location : '');

  // 来庁日
  const [visitDate, setVisitDate] = useState<Value>(defaultSelectedDate);

  // 来庁時間
  const [visitTimes, setVisitTimes] = useState<string[]>([]);
  const [visitTime, setVisitTime] = useState<string>(
    reservation ? reservation.createTimeText() : ''
  );

  // 備考
  const [remark, setRemark] = useState<string>(reservation?.remark ? reservation.remark : '');

  // 予約可能で一番近い日
  const [activeStartDate, setActiveStartDate] = useState<Date>(new Date());

  // カレンダー変更時に選びなおす
  const onChange = (nextValue: Value) => {
    setSelectedDay(nextValue);
  };

  // 次のページへ遷移できるかどうか
  const canExecute = () => {
    return selectedDay != null && visitTime != null && visitTime !== '';
  };

  // 日付が選べる状態かどうか
  const canSelectedDays = () => {
    return visitTimes.length > 0;
  };

  // 来庁日変更時の処理
  const handleLocationChange = (event: SelectChangeEvent) => {
    const selected = event.target.value as string;
    if (selected === '' || location === selected) {
      return;
    }
    setLocation(selected);
    setSelectedDay(null);
    setVisitDate(null);
    setVisitTime('');
    setVisitTimes([]);
  };

  // 来庁時間変更時の処理
  const handleVisitTimeChange = (event: SelectChangeEvent) => {
    setVisitTime(event.target.value as string);
  };

  // 備考欄変更時の処理
  const handleRemarksChange = (value: string) => {
    setRemark(value);
  };

  // カレンダーの日付押下時の処理
  const onClickCalendar = (date: Date) => {
    // 日付の変更
    setVisitDate(date);
    // 受付時間の一覧を更新
    const times = reservationSlotUsecase.readEnableReceptionTime(location, date);
    setVisitTimes(times);

    // 時間の更新
    const defaultSelectTime = times.length > 0 ? times[0] : '';
    setVisitTime(defaultSelectTime);
  };

  // 空き枠の時間を取得（UNIX時間）
  const createEmptySlots = useCallback(
    (slots: ReservationSlot[]) => {
      const closingDays = settingsCounterUniqueClosingDaysUsecase.closingDays;
      const skipDays = reservationSlotUsecase.extractionUnreservableDays(
        settingsRereservationableTimeUsecase.startDate,
        location
      );
      return slots
        .filter((emptySlot) => emptySlot.reservationNumber == null)
        .filter((emptySlot) => {
          const isTarget = emptySlot.visitDateTime.getTime() >= new Date().getTime();
          if (agentId == null) {
            const targetDate = emptySlot.createDateText();
            return isTarget && !skipDays.includes(targetDate) && !closingDays.includes(targetDate);
          }
          return isTarget;
        })
        .filter((emptySlot) => (agentId == null ? !emptySlot.isStaffOnly : true))
        .map((emptySlot) => new Date(emptySlot.visitDateTime).setHours(0, 0, 0, 0));
    },
    [
      agentId,
      settingsCounterUniqueClosingDaysUsecase,
      settingsRereservationableTimeUsecase,
      reservationSlotUsecase,
      location,
    ]
  );

  // 残件数または×を表示するための判定処理
  const isDateMarked = (date: Date) => {
    return emptySlotTimes.includes(date.getTime());
  };

  // 予約内容確認ページへ遷移
  const moveNextPage = () => {
    // 予約済みかどうかの確認
    const selectedDate = moment((visitDate as Date).getTime()).format('YYYY-MM-DD');
    const visitDateTime = new Date(`${selectedDate} ${visitTime}`);
    const preReservationNumber = preReservation?.reservationNumber ?? undefined;
    setIsWaiting(true);
    reservationSlotUsecase
      .isCapacityOver(publicEntityCode, location, visitDateTime, preReservationNumber)
      .then((result) => {
        if (result) {
          openDialog();
          return;
        }
        // 選択した予約情報の受け渡し
        setReservation(Reservation.create({ visitDateTime, location, remark }));

        if (agentId == null) {
          // 通常の確認画面へ遷移
          navigate(
            PageUrl.generate(
              ENDPOINT.RESERVATION_ENTRY_CONFIRMATION,
              queryPublicEntityCode,
              queryUserId
            ),
            { replace: true }
          );
        } else {
          // 代理予約の確認画面へ遷移
          setSelectedView('reservation-entry-confirmation-view');
        }
      })
      .finally(() => setIsWaiting(false));
  };

  // 予約エラーダイアログ表示
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openDialog = () => {
    setIsDialogOpen(true);
  };
  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  // 来庁場所一覧取得
  const fetchLocations = useCallback(async () => {
    await settingsLocationUsecase.fetch(publicEntityCode).catch(() => {});
    const readlocations = settingsLocationUsecase.read();
    setLocations(readlocations);
    if (readlocations.length > 0) {
      setLocation(reservation ? reservation.location : readlocations[0]);
    }
  }, [setLocations, setLocation, settingsLocationUsecase, reservation, publicEntityCode]);

  // 予約枠の取得
  const fetchSlots = useCallback(async () => {
    // 予約枠の取得
    await reservationSlotUsecase.fetchSlots(publicEntityCode, location).catch(() => {});
    const originals = reservationSlotUsecase.readSlots();
    const isRederved = isChangeReservation && preReservation != null;
    const slots = isRederved
      ? originals.map((slot) => {
          const reservationNumber =
            slot.reservationNumber === preReservation.reservationNumber
              ? null
              : slot.reservationNumber;
          slot.reservationNumber = reservationNumber;
          return slot;
        })
      : originals;

    reservationSlotUsecase.replacement(slots);
    setEmptySlotTimes(createEmptySlots(slots));
    setActiveStartDate(reservationSlotUsecase.minReserveDay());

    if (reservation) {
      // 受付時間の一覧を更新
      const times = reservationSlotUsecase.readEnableReceptionTime(
        location,
        reservation.visitDateTime
      );
      setVisitTimes(times);
      setVisitTime((rev) => (times.includes(rev) ? rev : ''));
    }
  }, [
    reservationSlotUsecase,
    publicEntityCode,
    location,
    reservation,
    preReservation,
    isChangeReservation,
    createEmptySlots,
  ]);

  // 指定した日付の空き枠数を取得
  const getEmptySlotLengthByDate = (date: Date) => {
    const emptySlotByDateTimes = emptySlotTimes
      .filter((emptySlot) => emptySlot === date.setHours(0, 0, 0, 0))
      .map((emptySlot) => moment(new Date(emptySlot).getTime()).format('HH:mm'));
    return emptySlotByDateTimes.length;
  };

  // 非推奨な予約枠かどうか
  const isDeprecationDate = (today: Date) => {
    const closingDays = settingsCounterUniqueClosingDaysUsecase.closingDays;
    const skipDays = reservationSlotUsecase.extractionUnreservableDays(
      settingsRereservationableTimeUsecase.startDate,
      location ?? ''
    );

    const targetDate = TimeControl.createDateText(today);

    return (
      reservationSlotUsecase.isDeprecationDate(today) ||
      skipDays.includes(targetDate) ||
      closingDays.includes(targetDate)
    );
  };

  // 初期値取得
  useEffect(() => {
    setIsWaiting(true);
    const run = async () => {
      await fetchLocations();
      await settingsRereservationableTimeUsecase.fetch(publicEntityCode).catch(() => {});
      setStartDate(settingsRereservationableTimeUsecase.startDate);
    };
    run().finally(() => setIsWaiting(false));
  }, [fetchLocations, setIsWaiting, settingsRereservationableTimeUsecase, publicEntityCode]);

  // 予約枠の取得
  useEffect(() => {
    if (location === '' || locations.length <= 0 || startDate < 0) {
      // 場所が未設定の状態では更新しない
      return;
    }
    setIsWaiting(true);
    const run = async () => {
      await settingsCounterUniqueClosingDaysUsecase
        .fetch(publicEntityCode, location)
        .catch(() => {});
      await fetchSlots();
    };
    run().finally(() => setIsWaiting(false));
  }, [
    fetchSlots,
    setIsWaiting,
    location,
    locations.length,
    startDate,
    settingsCounterUniqueClosingDaysUsecase,
    publicEntityCode,
  ]);

  return (
    <>
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
        <Stack className="mt-4" justifyContent="center" alignItems="center">
          <div>予約したい場所と日時を選んでください。</div>
          <div className="mt-4">
            {agentId != null
              ? '※グレー表示の日は、開庁しているが原則予約不可としている日です。'
              : '※お亡くなりになられてから、7日後以降のご予約をおすすめいたします。'}
          </div>
        </Stack>
        <Stack className="w-3/5 max-w-md z-0" spacing={1}>
          <InputLabel>来庁場所</InputLabel>
          <Select value={location} onChange={handleLocationChange}>
            {locations.map((location, index) => (
              <MenuItem key={index} value={location}>
                {location}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        <div>
          <Calendar
            locale="jp"
            calendarType="gregory"
            onChange={onChange}
            prev2Label={null}
            next2Label={null}
            minDetail="month"
            defaultActiveStartDate={activeStartDate}
            minDate={activeStartDate}
            value={selectedDay}
            formatDay={(locale, date) => date.getDate().toString()}
            onClickDay={(value) => onClickCalendar(value)}
            tileContent={({ date, view }) => {
              if (view !== 'month') {
                return;
              }
              const slotLength = getEmptySlotLengthByDate(date);
              if (slotLength <= 0) {
                return <p>×</p>;
              }
              return (
                <p
                  className={`${isDeprecationDate(date) && 'text-gray-300'}`}
                >{`残${slotLength}件`}</p>
              );
            }}
            tileDisabled={({ date, view }) => view === 'month' && !isDateMarked(date)}
          />
        </div>
        <Stack className="w-3/5 max-w-md" spacing={0.25}>
          <InputLabel>来庁日</InputLabel>
          <TextField
            className="bg-gray-300"
            fullWidth
            value={
              visitDate == null
                ? ''
                : moment((visitDate as Date).getTime()).format('yyyy年MM月DD日')
            }
            InputProps={{
              readOnly: true,
            }}
          />

          <InputLabel>来庁時間</InputLabel>
          <Select
            className={canSelectedDays() ? '' : 'bg-gray-300'}
            value={visitTime}
            onChange={handleVisitTimeChange}
            disabled={!canSelectedDays()}
          >
            {visitTimes.map((visitTime, index) => (
              <MenuItem key={index} value={visitTime}>
                {visitTime}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        <Stack
          className="w-full flex-wrap"
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {isChangeReservation && (
            <ExecuteButton
              className="mx-2 my-1"
              text="戻る"
              onClick={() => {
                if (agentId == null) {
                  // 通常の確認画面へ遷移
                  navigate(
                    PageUrl.generate(
                      ENDPOINT.CHANGE_RESERVATION,
                      queryPublicEntityCode,
                      queryUserId
                    ),
                    { replace: true }
                  );
                } else {
                  // 代理予約の確認画面へ遷移
                  setSelectedView('change-reservation-view');
                }
              }}
              isPrimary={false}
              width={buttonWidth}
            />
          )}

          <ExecuteButton
            text="予約を決定する"
            onClick={async () => {
              moveNextPage();
            }}
            isPrimary={true}
            type="button"
            width={buttonWidth}
            isDisabled={!canExecute()}
          />
        </Stack>

        {/* 備考 */}
        {agentId != null && (
          <Stack className="w-96">
            <RemarkField text={remark} setText={handleRemarksChange} isReadOnly={false} />
          </Stack>
        )}

        {/* エラー表示ダイアログ */}
        <CommonDialog
          open={isDialogOpen}
          onClose={closeDialog}
          content={
            <Stack height={200} justifyContent="center" alignItems="center" spacing={8}>
              <div>{ErrorHandler.getErrorMessage(ErrorHandler.CODE.RESERVATION_OVER_CAPACITY)}</div>
              <ExecuteButton
                text="閉じる"
                onClick={() => {
                  setSelectedDay(null);
                  setVisitDate(null);
                  setVisitTime('');
                  setVisitTimes([]);
                  fetchSlots();
                  closeDialog();
                }}
                isPrimary={true}
              />
            </Stack>
          }
        />
      </Stack>
      {/* セッション管理 */}
      {agentId == null && <SessionControl />}
    </>
  );
};

export default ReservationEditPage;
