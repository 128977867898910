export namespace ToParent {
  /**
   * 親ページにユーザーIDを渡す
   * @param userId ユーザーID
   */
  export const sendUserId = (userId?: string) => {
    const body: { type: string; data?: string } = {
      type: 'userId',
    };
    if (userId != null) {
      body.data = userId;
    }
    window.parent.postMessage(body, '*');
  };

  /**
   * 親ページに認証情報を渡す
   * @param session 認証時のセッション情報
   */
  export const sendUserAuthenticated = (session: string) => {
    window.parent.postMessage(
      {
        type: 'userAuthenticated',
        data: session,
      },
      '*'
    );
  };

  /**
   * 親にタブを閉じるを要求する
   */
  export const requestClosing = () => {
    window.parent.postMessage(
      {
        type: 'close',
      },
      '*'
    );
  };
}
