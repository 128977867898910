import { injectable } from 'inversify';
import StaffManagementUsecase from '../../usecases/interfaces/staff-management.usecase.interface';
import { Role, Staff } from '../../domains/entitis/staff';
import { HttpRequest } from '../../utils/http-request';
import { ErrorHandler } from '../../domains/services/error-handler';

interface ResponseType {
  id: string;
  password: string;
  expirationFrom: string;
  expirationTo: string;
  permission: Role;
  publicEntityCode: string;
}

@injectable()
export default class StaffManagementRepository implements StaffManagementUsecase {
  private _staffs: Staff[] = [];

  private readonly _endpoint = 'staff';

  async fetch(entityCode: string): Promise<void> {
    const requestUrl = HttpRequest.generateUrl(this._endpoint);
    const response = await HttpRequest.requestGet<ResponseType[]>(requestUrl);
    if (
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    ) {
      return;
    }

    const staffs = response.data?.data ?? [];
    this._staffs = staffs
      .map((staff) =>
        Staff.create({
          userName: staff.id,
          password: staff.password,
          permission: staff.permission,
          publicEntityCode: staff.publicEntityCode,
          expirationFrom: new Date(staff.expirationFrom),
          expirationTo: new Date(staff.expirationTo),
        })
      )
      .filter((staff) => staff.publicEntityCode === entityCode);
    return;
  }

  read(): Staff[] {
    return [...this._staffs];
  }

  async add(staff: Staff, accsessToken: string): Promise<boolean> {
    const requestUrl = HttpRequest.generateUrl(this._endpoint);
    const response = await HttpRequest.requestPost<null>(requestUrl, {
      id: staff.userName,
      password: staff.password,
      permission: staff.permission,
      publicEntityCode: staff.publicEntityCode,
      expirationFrom: staff.createExpirationFromDateText(),
      expirationTo: staff.createExpirationToDateText(),
      accsessToken,
    });
    return !(
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    );
  }

  async update(staff: Staff, accsessToken: string): Promise<boolean> {
    const requestUrl = HttpRequest.generateUrl(this._endpoint);
    const response = await HttpRequest.requestPut<null>(requestUrl, {
      id: staff.userName,
      password: staff.password,
      permission: staff.permission,
      publicEntityCode: staff.publicEntityCode,
      expirationFrom: staff.createExpirationFromDateText(),
      expirationTo: staff.createExpirationToDateText(),
      accsessToken,
    });

    return !(
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    );
  }

  async remove(userName: string): Promise<boolean> {
    const requestUrl = HttpRequest.generateUrl(this._endpoint);
    HttpRequest.addUrlParamater(requestUrl, 'id', userName);
    const response = await HttpRequest.requestDelete<null>(requestUrl);
    return !(
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    );
  }
}
