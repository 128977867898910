import { injectable } from 'inversify';
import SettingsLocationUsecase from '../../usecases/interfaces/settings-location.usecase.interface';
import { HttpRequest } from '../../utils/http-request';
import { ErrorHandler } from '../../domains/services/error-handler';

@injectable()
export default class SettingsLocationRepository implements SettingsLocationUsecase {
  private _locations: string[] = [];
  private readonly _endpoint = 'location';

  async fetch(entityCode: string): Promise<void> {
    const requestUrl = HttpRequest.generateUrl(this._endpoint);
    HttpRequest.addUrlParamater(requestUrl, 'public-entity-code', entityCode);
    const response = await HttpRequest.requestGet<string[]>(requestUrl);
    if (
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    ) {
      return;
    }
    this._locations = response.data.data ?? [];
    return;
  }

  read(): string[] {
    return [...this._locations];
  }
  async add(entityCode: string, location: string): Promise<boolean> {
    const requestUrl = HttpRequest.generateUrl(this._endpoint);
    const response = await HttpRequest.requestPost<null>(requestUrl, {
      publicEntityCode: entityCode,
      location,
    });

    return !(
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    );
  }
  async remove(entityCode: string, location: string): Promise<boolean> {
    const requestUrl = HttpRequest.generateUrl(this._endpoint);
    HttpRequest.addUrlParamater(requestUrl, 'public-entity-code', entityCode);
    HttpRequest.addUrlParamater(requestUrl, 'location', location);
    const response = await HttpRequest.requestDelete<null>(requestUrl);

    return !(
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    );
  }
}
