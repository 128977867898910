import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useContainer } from 'inversify-react';
import { InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField } from '@mui/material';
import ReservationSlotUsecase from '../../../../libs/usecases/interfaces/reservation-slot.usecase.interface';
import { TYPES } from '../../../../types';
import ReservationSlot from '../../../../libs/domains/entitis/reservation-slot';
import Calendar from 'react-calendar';
import { Value } from 'react-calendar/dist/cjs/shared/types';
import ExecuteButton from '../../molecule/execute-button/ExecuteButton';
import { PageUrl } from '../../../../libs/domains/services/page-url';
import { useIsWaitingContext } from '../../../contexts/is-waiting-context';
import SettingsLocationUsecase from '../../../../libs/usecases/interfaces/settings-location.usecase.interface';
import style from './ViewReservationStatusPage.module.scss';
import { buttonWidth } from '../../../styles/size';
import { ToParent } from '../../../../libs/domains/services/to-parent';
import SettingsReservationableTimeUsecase from '../../../../libs/usecases/interfaces/settings-reservationable-time.usecase.interface';
import SettingsCounterUniqueClosingDaysUsecase from '../../../../libs/usecases/interfaces/settings-counter-unique-closing-days.usecase.interface';
import ClosePageDaialog from '../../organism/close-page-daialog';
import CommonDialog from '../../molecule/common-dialog';
import { TimeControl } from '../../../../libs/utils/time-control';

const { QUERY_KEYS } = PageUrl;

const ViewReservationStatusPage = () => {
  const [searchParams] = useSearchParams();
  // 自治体コードを取得
  const publicEntityCode = searchParams.get(QUERY_KEYS.PUBLIC_ENTITY_CODE) ?? '';

  const container = useContainer();
  const reservationSlotUsecase = container.get<ReservationSlotUsecase>(TYPES.ReservationSlot);

  // 来庁場所
  const settingsLocationUsecase = useMemo(
    () => container.get<SettingsLocationUsecase>(TYPES.SettingsLocation),
    [container]
  );

  const [locations, setLocations] = useState<string[]>([]);
  const [location, setLocation] = useState('');
  // 読み込み中
  const { setIsWaiting } = useIsWaitingContext();

  const [slots, setSlots] = useState<ReservationSlot[]>([]);

  // 選択された日付
  const [selectedDay, setSelectedDay] = useState<Value | null>(null);
  // 選択された日付の時間
  const [times, setTimes] = useState<string[]>([]);

  // 「時間一覧」ダイアログを開くかどうか
  const [isDataListDialogOpen, setIsDataListDialogOpen] = useState<boolean>(false);

  // 「閉じる」警告を開くかどうか
  const [isCloseWarningOpen, setIsCloseWarningOpen] = useState<boolean>(false);

  // 予約可能期間
  const settingsRereservationableTimeUsecase = useMemo(
    () => container.get<SettingsReservationableTimeUsecase>(TYPES.SettingsReservationableTime),
    [container]
  );
  const [startDate, setStartDate] = useState<number>(-1);

  // 窓口閉鎖と営業日
  const settingsCounterUniqueClosingDaysUsecase = useMemo(
    () =>
      container.get<SettingsCounterUniqueClosingDaysUsecase>(
        TYPES.SettingsCounterUniqueClosingDays
      ),
    [container]
  );

  // カレンダー変更時に選びなおす
  const onChange = (nextValue: Value) => {
    setSelectedDay(nextValue);
    setTimes(createTimes(nextValue as Date));
  };

  // 来庁場所変更時の処理
  const handleLocationChange = (event: SelectChangeEvent) => {
    setLocation(event.target.value as string);
  };

  // 予約可能で一番近い日
  const [activeStartDate, setActiveStartDate] = useState<Date>(new Date());

  // 予約空枠の取得
  const readEmptySlots = useCallback(() => {
    const closingDays = settingsCounterUniqueClosingDaysUsecase.closingDays;
    const skipDays = reservationSlotUsecase.extractionUnreservableDays(
      settingsRereservationableTimeUsecase.startDate,
      location
    );

    // 空き枠を取得
    return slots
      .filter((emptySlot) => emptySlot.reservationNumber == null)
      .filter((emptySlot) => {
        const isTarget = emptySlot.visitDateTime.getTime() >= new Date().getTime();
        const targetDate = emptySlot.createDateText();
        return isTarget && !skipDays.includes(targetDate) && !closingDays.includes(targetDate);
      })
      .filter((emptySlot) => !emptySlot.isStaffOnly);
  }, [
    slots,
    location,
    settingsCounterUniqueClosingDaysUsecase,
    settingsRereservationableTimeUsecase,
    reservationSlotUsecase,
  ]);

  // 指定日の時間一覧を作成
  const createTimes = useCallback(
    (date: Date) => {
      // 空き枠を取得
      const emptySlots = readEmptySlots();
      // 対象日の受付時間一覧を作成
      return emptySlots
        .filter((emptySlot) => emptySlot.createDateText() === TimeControl.createDateText(date))
        .map((emptySlot) => emptySlot.createTimeText());
    },
    [readEmptySlots]
  );

  // 予約可能な残り枠数を表示するための判定処理
  const isDateMarked = useCallback(
    (date: Date) => {
      // 空き枠を取得
      const emptySlots = readEmptySlots().map((emptySlot) =>
        new Date(emptySlot.visitDateTime).setHours(0, 0, 0, 0)
      );

      // 指定日の枠数を取得
      const emptySlotByDate = emptySlots.filter((emptySlot) => emptySlot === date.getTime());
      return emptySlotByDate.length;
    },
    [readEmptySlots]
  );

  // 初期値取得
  useEffect(() => {
    setIsWaiting(true);
    const run = async () => {
      // 来庁場所一覧取得
      await settingsLocationUsecase.fetch(publicEntityCode).catch(() => {});
      const readlocations = settingsLocationUsecase.read();
      setLocations(readlocations);
      if (readlocations.length > 0) {
        setLocation(readlocations[0]);
      }
      // 予約開始可能期間の開始営業日数を取得
      await settingsRereservationableTimeUsecase.fetch(publicEntityCode).catch(() => {});
      setStartDate(settingsRereservationableTimeUsecase.startDate);
    };
    run().finally(() => setIsWaiting(false));
  }, [
    setIsWaiting,
    setLocations,
    setLocation,
    settingsLocationUsecase,
    settingsRereservationableTimeUsecase,
    publicEntityCode,
  ]);

  // 予約枠の取得
  useEffect(() => {
    if (location === '' || startDate < 0) {
      return;
    }
    setIsWaiting(true);
    const run = async () => {
      await settingsCounterUniqueClosingDaysUsecase
        .fetch(publicEntityCode, location)
        .catch(() => {});

      await reservationSlotUsecase.fetchSlots(publicEntityCode, location).catch(() => {});
      const slots = reservationSlotUsecase.readSlots();
      setSlots(slots);
    };
    run().finally(() => {
      setIsWaiting(false);
      setSelectedDay(null);
      setTimes([]);
    });
  }, [
    reservationSlotUsecase,
    settingsCounterUniqueClosingDaysUsecase,
    setSlots,
    setIsWaiting,
    publicEntityCode,
    location,
    startDate,
  ]);

  // 最小大表示日の更新
  useEffect(() => {
    if (slots.length <= 0) {
      return;
    }
    setActiveStartDate(reservationSlotUsecase.minReserveDay());
  }, [slots, setActiveStartDate, reservationSlotUsecase]);

  return (
    <>
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={4}>
        <div>予約可能な日程は下記の通りです。</div>
        <div className="mt-4">
          ※お亡くなりになられてから、7日後以降のご予約をおすすめいたします。
        </div>
        <Stack className="w-3/5 max-w-md" spacing={1}>
          <InputLabel>来庁場所</InputLabel>
          <Select value={location} onChange={handleLocationChange}>
            {locations.map((location, index) => (
              <MenuItem key={index} value={location}>
                {location}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        <div>
          <Calendar
            locale="jp"
            calendarType="gregory"
            onChange={onChange}
            onClickDay={() => setIsDataListDialogOpen(true)}
            prev2Label={null}
            next2Label={null}
            minDetail="month"
            defaultActiveStartDate={activeStartDate}
            minDate={activeStartDate}
            value={selectedDay}
            formatDay={(locale, date) => date.getDate().toString()}
            tileClassName={style.tile}
            tileContent={({ date, view }) =>
              view === 'month' && isDateMarked(date) ? (
                <p className={style.rest}>
                  残<span className={style.number}>{isDateMarked(date)}</span>件
                </p>
              ) : (
                <p>×</p>
              )
            }
            tileDisabled={({ date, view }) => view === 'month' && !isDateMarked(date)}
          />
        </div>
        <ExecuteButton
          text="閉じる"
          onClick={() => {
            ToParent.requestClosing();
            if (window.history.length > 1) {
              setIsCloseWarningOpen(true);
            } else {
              // 閉じる
              window.close();
            }
          }}
          isPrimary={true}
          type="button"
          width={buttonWidth}
        />
      </Stack>
      <CommonDialog
        open={isDataListDialogOpen}
        onClose={() => {
          setIsDataListDialogOpen(false);
        }}
        content={
          <Stack className="w-full h-full" justifyContent="center" alignItems="center" spacing={4}>
            <Stack className="w-full" justifyContent="center" alignItems="center" spacing={2}>
              {selectedDay && (
                <Stack className="w-full max-w-md" spacing={1}>
                  <InputLabel>来庁日</InputLabel>
                  <TextField
                    className="bg-blue-100"
                    fullWidth
                    value={TimeControl.createDateTextJp(selectedDay as Date)}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Stack>
              )}

              <Stack className="w-full max-w-md" spacing={1}>
                <InputLabel>受付可能時間</InputLabel>
                <Stack className="w-full max-w-md border border-b-0">
                  {times.map((time, index) => (
                    <div key={index} className={`border-b px-4 py-2`}>
                      {time}
                    </div>
                  ))}
                </Stack>
              </Stack>
            </Stack>
            <Stack
              className="w-full flex-wrap"
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={6}
            >
              <ExecuteButton
                className="mx-2 my-1"
                text="閉じる"
                onClick={() => setIsDataListDialogOpen(false)}
                isPrimary={true}
                width={buttonWidth}
              />
            </Stack>
          </Stack>
        }
      />

      <ClosePageDaialog isOpen={isCloseWarningOpen} setIsOpen={setIsCloseWarningOpen} />
    </>
  );
};

export default ViewReservationStatusPage;
