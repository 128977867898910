import { injectable } from 'inversify';
import { HttpRequest } from '../../utils/http-request';
import SettingsCounterUniqueClosingDaysUsecase from '../../usecases/interfaces/settings-counter-unique-closing-days.usecase.interface';
import CounterUniqueClosingDay from '../../domains/entitis/counter-closing-unique-day';
import { ErrorHandler } from '../../domains/services/error-handler';

interface ResponseType {
  date: string;
  isCounterClose: boolean;
  isBusinessDay: boolean;
}
@injectable()
export default class SettingsCounterUniqueClosingDaysRepository
  implements SettingsCounterUniqueClosingDaysUsecase
{
  private readonly _endpoint = 'counter-closed-day';

  private _data: CounterUniqueClosingDay[] = [];

  async fetch(entityCode: string, location: string): Promise<void> {
    const requestUrl = HttpRequest.generateUrl(this._endpoint);
    HttpRequest.addUrlParamater(requestUrl, 'public-entity-code', entityCode);
    HttpRequest.addUrlParamater(requestUrl, 'location', location);
    const response = await HttpRequest.requestGet<ResponseType[]>(requestUrl);
    this._data = [];
    if (
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code) ||
      response.data?.data == null
    ) {
      return;
    }
    const items = response.data.data.map((item) => {
      return CounterUniqueClosingDay.create({
        date: new Date(item.date),
        isBusinessDay: item.isBusinessDay,
      });
    });
    this._data = [...items];
    return;
  }
  read(): CounterUniqueClosingDay[] {
    return this._data.map((item) => CounterUniqueClosingDay.copy(item));
  }

  get closingDays(): string[] {
    return this._data.map((day) => day.createDateText());
  }

  async update(
    entityCode: string,
    location: string,
    days: CounterUniqueClosingDay[]
  ): Promise<boolean> {
    const closedDays = days.map((item) => {
      return {
        date: item.createDateText(),
        isBusinessDay: item.isBusinessDay,
      };
    });
    const requestUrl = HttpRequest.generateUrl(this._endpoint);
    const response = await HttpRequest.requestPut<null>(requestUrl, {
      publicEntityCode: entityCode,
      location,
      closedDays,
    });
    return !(
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    );
  }
}
