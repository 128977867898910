import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useContainer } from 'inversify-react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Alert, AlertColor, Snackbar, SnackbarOrigin, Stack, TextField } from '@mui/material';

import { TYPES } from '../../../../types';
import { Validation } from '../../../../libs/domains/services/validation';
import ExecuteButton from '../../molecule/execute-button/ExecuteButton';
import AuthenticationUsecase from '../../../../libs/usecases/interfaces/authentication.usecase.interface';
import { PageUrl } from '../../../../libs/domains/services/page-url';
import { useIsWaitingContext } from '../../../contexts/is-waiting-context';
import { ErrorHandler } from '../../../../libs/domains/services/error-handler';
import { buttonWidth } from '../../../styles/size';
import { Session } from '../../../../libs/domains/entitis/session';
import MailCaution from '../../organism/mail-caution/MailCaution';
import { ToParent } from '../../../../libs/domains/services/to-parent';
const { QUERY_KEYS, ENDPOINT } = PageUrl;

interface State extends SnackbarOrigin {
  open: boolean;
  severity: AlertColor;
  alartMessage: string;
}

const AuthenticationPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [session, setSession] = useState<string | null>(null);

  const container = useContainer();
  const authenticationUsecase = useMemo(
    () => container.get<AuthenticationUsecase>(TYPES.Authentication),
    [container]
  );

  const sessionManager = useMemo(() => Session.create(), []);

  const { control, handleSubmit } = useForm<Validation.CodeInputs>(Validation.DEFAULTVALUES.code);

  const [snakState, setSnakState] = React.useState<State>({
    open: false,
    alartMessage: '',
    severity: 'info',
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, alartMessage, horizontal, open, severity } = snakState;

  // 自治体コードを取得
  const publicEntityCode = searchParams.get(QUERY_KEYS.PUBLIC_ENTITY_CODE) ?? '';
  // ユーザーIDを取得
  const userId = searchParams.get(QUERY_KEYS.USER_ID) ?? '';
  // メールアドレスを取得
  const mailAddress = searchParams.get(QUERY_KEYS.EMAIL) ?? '';
  // 再予約かどうか
  const isChangeReservation = searchParams.get(QUERY_KEYS.IS_CHANGE_RESERVATION) === 'true';

  // 不正なリクエストパラメータ
  if (userId === '' || publicEntityCode === '' || mailAddress === '') {
    navigate(ENDPOINT.NOT_FOUND, { replace: true });
  }

  // 読み込み中
  const { setIsWaiting } = useIsWaitingContext();

  // 遷移先URLを作成
  const generateURL = useCallback(
    (path: PageUrl.Endpoint) => {
      // リクエストパラメータを作成
      const publicEntutyCodeQuery = { key: QUERY_KEYS.PUBLIC_ENTITY_CODE, value: publicEntityCode };
      return PageUrl.generate(path, publicEntutyCodeQuery);
    },
    [publicEntityCode]
  );

  const popAlert = useCallback(
    (open: boolean, severity: AlertColor, alartMessage: string) => {
      setSnakState((state) => {
        return {
          ...state,
          open,
          severity,
          alartMessage,
        };
      });
    },
    [setSnakState]
  );

  const onSubmit: SubmitHandler<Validation.CodeInputs> = (data: Validation.CodeInputs) => {
    const alertMessage = ErrorHandler.getErrorMessage(ErrorHandler.CODE.FAILED_AUTHENTICATION);
    const severity = 'warning';
    const open = true;

    // セッションが確立していない
    if (session == null) {
      popAlert(open, severity, alertMessage);
      return;
    }

    // 認証処理
    setIsWaiting(true);
    authenticationUsecase
      .verify(userId, data.code, session)
      .then((result) => {
        if (!result) {
          // setSession(null);
          popAlert(open, severity, alertMessage);
          // reSent(true);
          return;
        }

        // セッション情報をトークンとして保存する
        sessionManager.updateToken(userId, session);

        // 親ページに認証情報を渡す
        ToParent.sendUserAuthenticated(session);
        // 親ページにユーザーIDを渡す
        ToParent.sendUserId(userId);
      })
      .catch(() => {})
      .finally(() => setIsWaiting(false));
  };

  // 認証メールの再送
  const reSent = (iaAuto: boolean = false) => {
    // 認証コードをメールで送信
    setIsWaiting(true);
    authenticationUsecase
      .send(userId)
      .then((result) => {
        setSession(result);
        if (result == null) {
          popAlert(
            true,
            'warning',
            ErrorHandler.getErrorMessage(ErrorHandler.CODE.FAILED_RESEND_CODE)
          );
        } else {
          if (iaAuto) {
            return;
          }
          popAlert(true, 'success', '認証コードを再送しました。');
        }
      })
      .catch(() => {})
      .finally(() => setIsWaiting(false));
  };

  const onCancel = () => {
    navigate(generateURL(ENDPOINT.INPUT_EMAIL), { replace: true });
  };

  const handleSnackClose = () => {
    setSnakState({ ...snakState, open: false });
  };

  useEffect(() => {
    // メールアドレスが貰えてなかったら入力画面へ戻す
    if (mailAddress === '') {
      navigate(generateURL(ENDPOINT.INPUT_EMAIL), { replace: true });
    }

    // 認証コードをメールで送信
    authenticationUsecase
      .send(userId)
      .then((result) => {
        setSession(result);
        if (result == null) {
          popAlert(true, 'error', ErrorHandler.getErrorMessage(ErrorHandler.CODE.FAILED_SEND_CODE));
        }
      })
      .catch(() => {});
  }, [navigate, mailAddress, authenticationUsecase, generateURL, userId, popAlert]);

  return (
    <>
      <Stack
        className="h-screen w-screen"
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack justifyContent="flex-start" alignItems="flex-start">
          <div>{mailAddress}宛に認証コードを送付しました。</div>
          <div>メールに記載されている認証コードを入力してください。</div>
        </Stack>

        <Stack justifyContent="flex-start" alignItems="flex-start">
          <div
            className="cursor-pointer text-blue-600 hover:text-blue-400"
            onClick={() => reSent()}
          >
            コードを再送する
          </div>
        </Stack>
        <Stack className="w-3/5 max-w-md" spacing={1}>
          <Controller
            name={Validation.NAMES.code}
            control={control}
            rules={Validation.RULES.code}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                type="number"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                fullWidth
                label="認証コード"
                focused
              />
            )}
          />
        </Stack>

        <Stack
          className="w-full flex-wrap pt-2"
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {!isChangeReservation && (
            <ExecuteButton
              className="mx-2 my-1"
              text="メールアドレスを設定しなおす"
              onClick={onCancel}
              isPrimary={false}
              type="button"
              width={buttonWidth}
            />
          )}
          <ExecuteButton
            className="mx-2 my-1"
            text={session ? '認証する' : '認証コード送信中'}
            isPrimary={true}
            width={buttonWidth}
            isDisabled={session == null}
          />
        </Stack>
        <Stack direction="column" justifyContent="flex-start" alignItems="flex-start">
          <MailCaution publicEntityCode={publicEntityCode} />
          <div className={`mx-4`}>
            ・表示されているメールアドレスに誤りがないかご確認ください。誤りがある場合には、正しいメールアドレスを再度入力してください。
          </div>
        </Stack>
      </Stack>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={6000}
        open={open}
        onClose={handleSnackClose}
        key={vertical + horizontal}
      >
        <Alert severity={severity}>{alartMessage}</Alert>
      </Snackbar>
    </>
  );
};

export default AuthenticationPage;
