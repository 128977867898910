import { createContext, ReactNode, useContext, useState, Dispatch, SetStateAction } from 'react';

interface IsPreparedReservationContextType {
  isPreparedReservation: boolean;
  setIsPreparedReservation: Dispatch<SetStateAction<boolean>>;
}

/**
 * 作成したコンテキストは一意の名称とする
 */
const isPreparedReservationContext = createContext<IsPreparedReservationContextType>(
  {} as IsPreparedReservationContextType
);

/**
 * 外部から設定値を取り出す用
 */
export const useIsPreparedReservationContext = () => useContext(isPreparedReservationContext);

/**
 * コンテキストを提供する
 */
export const IsPreparedReservationProvider = ({ children }: { children: ReactNode }) => {
  const [isPreparedReservation, setIsPreparedReservation] = useState<boolean>(false);
  const { Provider } = isPreparedReservationContext;

  const value = {
    isPreparedReservation,
    setIsPreparedReservation,
  };

  return <Provider value={value}>{children}</Provider>;
};
