import React from 'react';
import { Stack } from '@mui/material';
import ExecuteButton from '../../molecule/execute-button';

interface PropsType {
  onNoCancel: () => void; // 取り消した場合の動作
  onCancel: () => void; // 取り消さない場合の動作
  visitDateTime?: string; // 予約時間
}

const ReserveCancel = ({ onNoCancel, onCancel, visitDateTime }: PropsType) => {
  const buttonWidth = 220;

  return (
    <Stack className="w-full h-full" justifyContent="space-between" alignItems="center">
      {visitDateTime && (
        <Stack className="w-full h-36" justifyContent="center" alignItems="center">
          <div>{`${visitDateTime}～の予約を取り消します。`}</div>
          <div>予約の取り消しを行うと、手続き情報も削除されます。</div>
          <div>本当によろしいですか？</div>
        </Stack>
      )}

      <Stack
        className="w-full flex-wrap"
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <ExecuteButton
          className="mx-2 my-1"
          text="予約を取り消さない"
          onClick={onNoCancel}
          isPrimary={false}
          width={buttonWidth}
        />
        <ExecuteButton
          className="mx-2 my-1"
          text="予約を取り消す"
          onClick={onCancel}
          isPrimary={true}
          width={buttonWidth}
        />
      </Stack>
    </Stack>
  );
};

export default ReserveCancel;
