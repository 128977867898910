/**
 * 比較・差分抽出の汎用処理
 */
export namespace DifferentialUtil {
  /**
   * null許容の対象を比較する
   * @param original 比較元
   * @param other 比較対象
   * @returns 一致:true、不一致:false
   */
  export const exactOptional = <T>(original: T | null, other: T | null): boolean => {
    return original === null && other === null
      ? true
      : original !== null && other !== null
      ? original === other
      : false;
  };

  /**
   * undefined許容の対象を比較する
   * @param original
   * @param other
   * @returns 有無が一致: false
   */
  export const exactExistence = <T>(original?: T, other?: T): boolean => {
    return original == null && other == null ? true : original != null && other != null;
  };
}
