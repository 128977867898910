import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useContainer } from 'inversify-react';
import MailSettingUsecase from '../../../../libs/usecases/interfaces/mail-setting.usecase.interface';
import { TYPES } from '../../../../types';
import { ErrorHandler } from '../../../../libs/domains/services/error-handler';

interface PropsType {
  publicEntityCode: string;
}

const MailCaution = ({ publicEntityCode }: PropsType) => {
  const container = useContainer();
  const meilSettingUsecase = container.get<MailSettingUsecase>(TYPES.MailSetting);

  const [domainName, setDomainName] = useState<string | null>('読み込み中');

  useEffect(() => {
    const run = async () => {
      // メール設定取得
      await meilSettingUsecase.fetch(publicEntityCode);
      const readMeilSetting = meilSettingUsecase.read();
      if (readMeilSetting == null) {
        return;
      }

      const domainName = readMeilSetting.domainName;
      setDomainName(domainName);
    };
    run().catch(() => {
      alert(ErrorHandler.getErrorMessage(ErrorHandler.CODE.FAILED_SEND_CODE));
    });
  }, [setDomainName, publicEntityCode, meilSettingUsecase]);

  return (
    <Stack direction="column" justifyContent="flex-start" alignItems="flex-start">
      <div className={`mx-4 mb-3`}>
        入力いただいたメールアドレスにメールが届かない場合は、以下の点にご注意ください。
      </div>
      <div className={`mx-4`}>
        ・ご利用されているメールサービスの設定をご確認いただき、ドメイン「{domainName}
        」を受信許可してください。
      </div>
      <div className={`mx-4`}>
        ・お送りしたメールが「低優先メール」「迷惑メール」フォルダーに入っている可能性もありますので、ご確認ください。
      </div>
    </Stack>
  );
};

export default MailCaution;
