/**
 * 文字列操作系の便利機能
 */
export namespace StringUtil {
  /**
   * 指定した桁数分、文字列を0で埋める
   * @param num 0埋めしたい数値
   * @param len 0埋めの桁数
   * @returns 0埋めされた文字列
   */
  export const zeroPadding = (num: number, len: number) => {
    return (Array(len).join('0') + num).slice(-1 * len);
  };

  /**
   * {数値}で指定した箇所を、指定した引数で置き換える
   * @param str フォーマット文字列
   * @param args 置き換える引数
   * @returns 置き換えた文字列
   */
  export const format = (str: string, ...args: unknown[]): string => {
    for (const [i, arg] of args.entries()) {
      const regExp = new RegExp(`\\{${i}\\}`, 'g');
      str = str.replace(regExp, arg as string);
    }
    return str;
  };
}
