import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useSearchParams } from 'react-router-dom';
import { Fab, InputLabel, Stack, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { QRCodeSVG } from 'qrcode.react';
import { useContainer } from 'inversify-react';
import ReservationUsecase from '../../../../libs/usecases/interfaces/reservation.usecase.interface';
import { TYPES } from '../../../../types';
import { useIsWaitingContext } from '../../../contexts/is-waiting-context';
import ExecuteButton from '../../molecule/execute-button';
import { useSelectedManagementPageViewContext } from '../../../contexts/selected-managemaent-page-view-context';
import { useAgentIdContext } from '../../../contexts/agent-id-context';
import { buttonWidth } from '../../../styles/size';
import { useLoginStaffContext } from '../../../contexts/login-staff-context';
import RemarkField from '../../molecule/remark-field';
import RemarkUsecase from '../../../../libs/usecases/interfaces/remark-usecase.interface';
import { PageUrl } from '../../../../libs/domains/services/page-url';
const { QUERY_KEYS } = PageUrl;
interface ReservationDetailViewModel {
  name: string;
  phoneNumber: string;
  reservationNumber: string;
  visitDateTime: string;
  belongings: string;
  remark: string;
}
interface PropsType {
  reservationNumber: string;
  onReturn: () => void;
  onEdit?: () => void;
}
const ReservationDetailPage = ({ reservationNumber, onReturn, onEdit }: PropsType) => {
  const container = useContainer();
  const resevationTextField = 'bg-blue-200';
  const [searchParams] = useSearchParams();

  // 自治体コードとユーザーIDを取得
  const publicEntityCode = searchParams.get(QUERY_KEYS.PUBLIC_ENTITY_CODE) ?? '';

  // ログインユーザー
  const { loginStaff } = useLoginStaffContext();

  // 印刷処理
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: '@page { size: A4; margin: 10mm 0; }',
  });

  // 読み込み中
  const { setIsWaiting } = useIsWaitingContext();

  // 管理画面の表示内容
  const { setSelectedView } = useSelectedManagementPageViewContext();

  // 画面表示用の情報
  const [viewReservationDetail, setViewReservationDetail] =
    useState<ReservationDetailViewModel | null>(null);
  const reservationUsecase = useMemo(
    () => container.get<ReservationUsecase>(TYPES.Reservation),
    [container]
  );

  // 予約備考
  const remarkUsecase = useMemo(
    () => container.get<RemarkUsecase>(TYPES.ReservationRemark),
    [container]
  );

  // 代理予約者
  const { setAgentId } = useAgentIdContext();

  // 代理予約かどうか
  const [isAgent, setIsAgent] = useState(false);

  // QRコードの元データ
  const [detail, setDetail] = useState('');

  const viewQR = () => {
    return (
      <>
        {/* QRコード */}
        {detail !== '' && <QRCodeSVG value={detail} size={200} />}

        {/* 注意 */}
        {isAgent && (
          <Stack justifyContent="center" alignItems="center">
            <div>この予約は代理でされています。</div>
            <div>変更・キャンセルした場合、予約された方へお伝えください。</div>
          </Stack>
        )}
      </>
    );
  };

  // 備考欄更新
  const onRemarkChange = (value: string) => {
    setViewReservationDetail((prev) => {
      if (prev == null) {
        return prev;
      }
      return { ...prev, remark: value };
    });
  };

  // 備考を保存
  const onSaveRemark = async () => {
    try {
      setIsWaiting(true);
      if (
        !(await remarkUsecase.update(
          publicEntityCode,
          reservationNumber,
          viewReservationDetail?.remark ?? ''
        ))
      ) {
        alert('備考の更新に失敗しました。');
        return;
      }
    } finally {
      setIsWaiting(false);
    }
  };

  // 予約情報取得
  useEffect(() => {
    const run = async () => {
      setIsWaiting(true);
      try {
        const current = await reservationUsecase.fetch(undefined, reservationNumber);
        // QRコード
        setDetail(current?.preApplication?.preApplicationDetail ?? '');
        // 表示用
        setViewReservationDetail({
          name: current?.preApplication?.userName ?? '',
          phoneNumber: current?.preApplication?.phoneNumber ?? '',
          reservationNumber: current?.reservationNumber ?? '',
          visitDateTime: current?.createViewDateTimeText() ?? '',
          belongings: current?.preApplication?.belongings ?? '',
          remark: current?.remark ?? '',
        });
        // ユーザーIDを確保
        setAgentId(current?.user?.userId ?? '');
      } finally {
        setIsWaiting(false);
      }
    };
    run();
  }, [reservationUsecase, setIsWaiting, reservationNumber, setAgentId]);

  // 代理予約かどうかの監視
  useEffect(() => {
    setIsAgent(viewReservationDetail != null && detail === '');
  }, [viewReservationDetail, detail, setIsAgent]);

  return (
    <>
      <Stack justifyContent="center" alignItems="center" spacing={6} component="form" noValidate>
        <Stack
          ref={componentRef}
          className="mt-8"
          width="100%"
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={4}
          component="form"
          noValidate
        >
          {/* 印刷範囲はここから */}
          <>
            {viewReservationDetail != null && (
              <>
                <div>以下の内容で、来庁予約されております。</div>

                {viewQR()}
                <Stack className="w-4/5 max-w-md" spacing={1}>
                  <InputLabel>来庁者氏名</InputLabel>
                  <TextField
                    className={resevationTextField}
                    fullWidth
                    value={viewReservationDetail.name}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <InputLabel>電話番号</InputLabel>
                  <TextField
                    className={resevationTextField}
                    fullWidth
                    value={viewReservationDetail.phoneNumber}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <InputLabel>予約番号</InputLabel>
                  <TextField
                    className={resevationTextField}
                    fullWidth
                    value={viewReservationDetail.reservationNumber}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <InputLabel>来庁日時</InputLabel>
                  <TextField
                    className={resevationTextField}
                    fullWidth
                    value={viewReservationDetail.visitDateTime}
                    InputProps={{
                      readOnly: true,
                    }}
                  />

                  <>
                    <InputLabel>持ち物</InputLabel>
                    <Stack
                      className="border p-1 w-full max-w-md flex-wrap"
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      {viewReservationDetail.belongings.split(',').map((item, index) => {
                        return (
                          <div key={index} className="bg-slate-50 p-2 m-1">
                            {item}
                          </div>
                        );
                      })}
                    </Stack>
                  </>
                  {/* 備考 */}
                  <RemarkField
                    text={viewReservationDetail.remark}
                    setText={onRemarkChange}
                    doSave={onSaveRemark}
                    canSave={loginStaff?.permission === 'administrator'}
                    isReadOnly={loginStaff?.permission !== 'administrator'}
                  />
                </Stack>
              </>
            )}
          </>

          {/* 印刷範囲はここまで */}
        </Stack>
        {viewReservationDetail != null && (
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <Stack
              className="w-full"
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={6}
            >
              <ExecuteButton
                text="戻る"
                onClick={onReturn}
                isPrimary={false}
                type="button"
                width={buttonWidth}
              />
              {!isAgent && (
                <ExecuteButton
                  text="印刷"
                  onClick={handlePrint}
                  type="button"
                  isPrimary={true}
                  width={buttonWidth}
                />
              )}
            </Stack>

            {/* 編集ボタン */}
            {loginStaff?.permission === 'administrator' && (
              <Fab
                color="primary"
                aria-label="edit"
                variant="extended"
                onClick={() => {
                  if (onEdit != null) {
                    onEdit();
                  }
                  setSelectedView('change-reservation-view');
                }}
              >
                <EditIcon className="mr-2" />
                編集
              </Fab>
            )}
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default ReservationDetailPage;
