/**
 * ユーザーのプロパティ
 */
export interface UserProperties {
  userId: string;
  mailadress: string;
}

/**
 * ユーザー情報
 */
export default class User {
  private constructor(
    private readonly _userId: string, // ユーザーID
    private readonly _mailadress: string // メールアドレス
  ) {}

  /**
   * 同一オブジェクトとの比較
   * @param other 比較対象
   * @returns 一致:true、不一致:false
   */
  equals(other?: User | null): boolean {
    if (other == null) {
      return false;
    }
    return this._userId === other.userId && this._mailadress === other.mailadress;
  }

  /**
   * ユーザーID
   */
  get userId(): string {
    return this._userId;
  }
  /**
   * メールアドレス
   */
  get mailadress(): string {
    return this._mailadress;
  }

  /**
   * 代理予約用のIDを作成
   */
  static generateAgentId(): string {
    return `agent-${new Date().getTime()}`;
  }

  /**
   * 新規作成
   */
  static create(properties: UserProperties) {
    return new User(properties.userId, properties.mailadress);
  }

  /**
   * コピーを作成
   */
  static copy(previos: User) {
    return new User(previos.userId, previos.mailadress);
  }
}
