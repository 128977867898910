const TYPES = {
  PreApplication: Symbol.for('PreApplication'),
  Authentication: Symbol.for('Authentication'),
  ReservationSlot: Symbol.for('ReservationSlot'),
  ReservationUser: Symbol.for('ReservationUser'),
  Reservation: Symbol.for('Reservation'),
  ReservationRemark: Symbol.for('ReservationRemark'),
  Login: Symbol.for('Login'),
  Notice: Symbol.for('Notice'),
  StaffManagement: Symbol.for('StaffManagement'),
  SettingsLocation: Symbol.for('SettingsLocation'),
  SettingsReservationableTime: Symbol.for('SettingsReservationableTime'),
  SettingsAcceptableCount: Symbol.for('SettingsAcceptableCount'),
  SettingsReceptionTime: Symbol.for('SettingsReceptionTime'),
  SettingsCounterClosingDays: Symbol.for('SettingsCounterClosingDays'),
  SettingsCounterUniqueClosingDays: Symbol.for('SettingsCounterUniqueClosingDays'),
  MailSetting: Symbol.for('MailSetting'),
  ReservationOutput: Symbol.for('ReservationOutput'),
};

export { TYPES };
