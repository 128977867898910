import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useContainer } from 'inversify-react';

import { TYPES } from '../../../../types';
import { useReservationContext } from '../../../contexts/revervation-context';
import { PageUrl } from '../../../../libs/domains/services/page-url';
import { useIsWaitingContext } from '../../../contexts/is-waiting-context';
import { useIsChangeReservationContext } from '../../../contexts/is-change-reservation-context';
import ReservationUsecase from '../../../../libs/usecases/interfaces/reservation.usecase.interface';
import ReservationUserUsecase from '../../../../libs/usecases/interfaces/reservation-user.usecase.interface';
import { usePreReservationContext } from '../../../contexts/pre-revervation-context';
import Reservation from '../../../../libs/domains/entitis/reservation';
import { Stack } from '@mui/material';
import { useSelectedManagementPageViewContext } from '../../../contexts/selected-managemaent-page-view-context';
import { useAgentIdContext } from '../../../contexts/agent-id-context';
import User from '../../../../libs/domains/value-objects/user';
import NowLoading from '../../molecule/now-loading';
import { useIsPreparedReservationContext } from '../../../contexts/is-prepared-reservation-context';
import { Session } from '../../../../libs/domains/entitis/session';
import { useUserAuthenticatedContext } from '../../../contexts/user-authenticated-context';
import CommonDialog from '../../molecule/common-dialog';
const { QUERY_KEYS, ENDPOINT } = PageUrl;

const ReservationPage = () => {
  const navigate = useNavigate();
  const container = useContainer();
  const [searchParams] = useSearchParams();

  // 代理予約者
  const { agentId } = useAgentIdContext();

  // 必須情報の取得
  const userId = searchParams.get(QUERY_KEYS.USER_ID) ?? agentId ?? '';
  const publicEntityCode = searchParams.get(QUERY_KEYS.PUBLIC_ENTITY_CODE) ?? '';
  if (userId === '' || publicEntityCode === '') {
    navigate(ENDPOINT.NOT_FOUND, { replace: true });
  }

  // セッション管理
  const sessionManager = useMemo(() => Session.create(false), []);

  // 再予約かどうか
  const { isChangeReservation, setIsChangeReservation } = useIsChangeReservationContext();

  // 予約前準備完了しているかどうか
  const { setIsPreparedReservation } = useIsPreparedReservationContext();

  // 管理画面の表示内容
  const { setSelectedView } = useSelectedManagementPageViewContext();

  // ユーザー
  const reservationUserUsecase = useMemo(
    () => container.get<ReservationUserUsecase>(TYPES.ReservationUser),
    [container]
  );

  // 予約
  const reservationUsecase = useMemo(
    () => container.get<ReservationUsecase>(TYPES.Reservation),
    [container]
  );
  // 予約情報
  const { reservation, setReservation } = useReservationContext();
  const { setPreReservation } = usePreReservationContext();

  // 読み込み中
  const { isWaiting, setIsWaiting } = useIsWaitingContext();

  // 認証済みユーザーかどうか
  const { userAuthenticated, setUserAuthenticated } = useUserAuthenticatedContext();

  // 認証ダイアログ表示
  const [isAuthDialogOpen, setIsAuthDialogOpen] = useState(false);
  const closeAuthDialog = (event: React.MouseEvent<HTMLInputElement>, reason: 'backdropClick') => {
    if (reason === 'backdropClick') {
      // バックドロップのクリックを無効化
      return;
    }
    setIsAuthDialogOpen(false);
  };

  const [sendmailAddress, setSendmailAddress] = useState('');

  // 予約の準備をスキップするかどうか
  const [isInitializeStarted, setIsInitializeStarted] = useState(false);

  const needChecking = useCallback(
    () => isChangeReservation && reservation != null,
    [isChangeReservation, reservation]
  );

  // セッション開始
  const startSession = useCallback(() => {
    const token = sessionManager.readToken(userId);
    if (token == null) {
      // 認証されていないため終了
      return false;
    }
    setUserAuthenticated(token);
    if (sessionManager.readValidityTime(userId) != null) {
      // すでにセッションの監視が開始されている
      return true;
    }
    // セッションの開始
    sessionManager.updateValidityTime(userId, new Date());
    return true;
  }, [sessionManager, userId, setUserAuthenticated]);

  // 次のページへ遷移
  const gotoReservationEditPage = useCallback(() => {
    setIsPreparedReservation(true);
    if (agentId != null) {
      // 代理予約へ遷移
      setSelectedView('reservation-edit-view');
      return;
    }
    // 通常の予約画面へ遷移
    const queryPublicEntityCode = {
      key: QUERY_KEYS.PUBLIC_ENTITY_CODE,
      value: publicEntityCode,
    };
    const queryUserId = {
      key: QUERY_KEYS.USER_ID,
      value: userId,
    };
    navigate(PageUrl.generate(ENDPOINT.RESERVATION_EDIT, queryPublicEntityCode, queryUserId), {
      replace: true,
    });
  }, [navigate, publicEntityCode, userId, agentId, setSelectedView, setIsPreparedReservation]);

  // 遷移先URLの作成
  const generateNextURL = (endpoint: PageUrl.Endpoint, email: string) => {
    return PageUrl.generate(
      endpoint,
      {
        key: QUERY_KEYS.PUBLIC_ENTITY_CODE,
        value: publicEntityCode,
      },
      {
        key: QUERY_KEYS.USER_ID,
        value: userId,
      },
      {
        key: QUERY_KEYS.EMAIL,
        value: email,
      },
      {
        key: QUERY_KEYS.IS_CHANGE_RESERVATION,
        value: `${isChangeReservation}`,
      }
    );
  };

  // 予約済みかどうか
  const existsReservation = useCallback(async () => {
    setIsWaiting(true);

    try {
      // ユーザー情報取得
      const user = await reservationUserUsecase.fetch(userId).catch(() => null);
      if (user == null) {
        if (agentId == null) {
          setReservation(null);
          setPreReservation(null);
          setIsChangeReservation(false);
          setIsWaiting(false);
          return;
        }
      }

      const target: User = user ?? User.create({ userId: agentId ?? '', mailadress: '' });
      setSendmailAddress(target.mailadress);

      const current = await reservationUsecase.fetch(target).catch(() => null);
      const existReservation = current != null;
      if (!existReservation) {
        setPreReservation(null);
        setIsChangeReservation(existReservation);
        return;
      }

      // すでに予約が存在しているなら予約変更の扱いとする
      setReservation(Reservation.copy(current));
      setPreReservation(Reservation.copy(current));
      setIsChangeReservation(existReservation);
    } finally {
      setIsWaiting(false);
    }
  }, [
    setIsWaiting,
    reservationUsecase,
    reservationUserUsecase,
    setReservation,
    setPreReservation,
    setIsChangeReservation,
    userId,
    agentId,
  ]);

  // 初期化
  useEffect(() => {
    if (isInitializeStarted) {
      // 初期化開始している場合は初期化しない
      return;
    } else {
      if (needChecking()) {
        // 次の画面に送る
        gotoReservationEditPage();
        return;
      }
      setIsInitializeStarted(true);
    }

    // TODO: 呼び出し元URLはクロスドメインだと、URLパラメータ（クエリ）が取れないことある
    // document.referrer.includes(`public-entity-code=${publicEntityCode}`);
    // if (document.referrer.includes(`public-entity-code=${publicEntityCode}`)) {
    // TODO: 暫定対処で有無で判定。事前申請からiFrameで呼び出してもらえないか検討する
    // if (document.referrer !== '') {
    //   sessionManager.updateToken(userId, userId);
    // }

    // すでに予約があるかどうか調べる
    existsReservation().then(() => {
      const isStarted = agentId == null ? startSession() : true;
      if (isStarted) {
        gotoReservationEditPage();
      } else {
        // 再コード認証
        setIsAuthDialogOpen(true);
      }
    });
  }, [
    needChecking,
    existsReservation,
    gotoReservationEditPage,
    userAuthenticated,
    startSession,
    agentId,
    sessionManager,
    userId,
    isInitializeStarted,
    publicEntityCode,
  ]);

  // iFrameから認証情報を受け取る
  const handler = useCallback(
    (event: MessageEvent<any>) => {
      console.log(event);
      if (event.data?.type !== 'userAuthenticated') {
        return;
      }
      if (event.data?.data == null) {
        return;
      }
      setIsAuthDialogOpen(false);
      if (agentId != null) {
        return;
      }
      if (!startSession()) {
        navigate(ENDPOINT.RESERVATION_SESSION_TIMEOUT, { replace: true });
        return;
      }
      console.log('goto reservation page 3.');
      gotoReservationEditPage();
    },
    [agentId, startSession, setIsAuthDialogOpen, gotoReservationEditPage, navigate]
  );
  useEffect(() => {
    window.addEventListener('message', handler);
    return () => {
      window.removeEventListener('message', handler);
    };
  }, [handler]);

  return (
    <>
      <Stack justifyContent="center" alignItems="center">
        <NowLoading isLoading={isWaiting} />
      </Stack>

      {/* 認証ダイアログ */}
      <CommonDialog
        open={isAuthDialogOpen}
        onClose={closeAuthDialog}
        content={
          <Stack width="100%" height="680px" justifyContent="center" alignItems="center">
            <iframe
              title="reservation"
              width="100%"
              height="100%"
              src={generateNextURL(ENDPOINT.AUTHETICATION, sendmailAddress)}
            ></iframe>
          </Stack>
        }
      />
    </>
  );
};

export default ReservationPage;
