import _ from 'lodash';
/**
 * 受付時間のプロパティ
 */
export interface ReceptionTimeProperties {
  dow: string;
  times: string[];
}

/**
 * 受付時間のオブジェクト
 */
export interface ReceptionTimeDto {
  dow: string;
  times: string[];
}

/**
 *  受付時間
 */
export default class ReceptionTime {
  private constructor(
    private _dow: string, // 受付曜日
    private _times: string[] // 受付時間
  ) {}

  /**
   * 受付曜日
   */
  get dow(): string {
    return this._dow;
  }

  /**
   * 受付曜日
   */
  set dow(dow: string) {
    this._dow = dow;
  }

  /**
   * 受付時間
   */
  get times(): string[] {
    return [...this._times];
  }

  /**
   * 受付時間
   */
  set times(times: string[]) {
    this._times = [...times];
  }

  /**
   * 同一オブジェクトとの比較
   * @param other 比較対象
   * @returns 一致:true、不一致:false
   */
  equals(other?: ReceptionTime | null): boolean {
    if (other == null) {
      return false;
    }
    return this._dow === other.dow && _.isEqual(this._times, other.times);
  }

  /**
   * JSONにする
   * @returns
   */
  toDto(): ReceptionTimeDto {
    return {
      dow: this._dow,
      times: [...this._times],
    };
  }

  /**
   * 新規作成
   * @param properties 受付時間
   * @returns 受付時間
   */
  static create(properties: ReceptionTimeProperties) {
    return new ReceptionTime(properties.dow, _.cloneDeep(properties.times));
  }

  /**
   * コピーを作成
   * @param previos コピーもとの受付時間
   * @returns コピーした受付時間
   */
  static copy(previos: ReceptionTimeProperties) {
    return new ReceptionTime(previos.dow, _.cloneDeep(previos.times));
  }
}
