import { Container } from 'inversify';
import { TYPES } from './types';
import 'reflect-metadata';

// interfaces
import PreApplicationUsecase from './libs/usecases/interfaces/pre-application.usecase.interface';
import AuthenticationUsecase from './libs/usecases/interfaces/authentication.usecase.interface';
import ReservationUserUsecase from './libs/usecases/interfaces/reservation-user.usecase.interface';
import ReservationSlotUsecase from './libs/usecases/interfaces/reservation-slot.usecase.interface';
import ReservationUsecase from './libs/usecases/interfaces/reservation.usecase.interface';
import RemarkUsecase from './libs/usecases/interfaces/remark-usecase.interface';
import ReservationOutputUsecase from './libs/usecases/interfaces/reservation-output.usecase.interface';
import LoginUsecase from './libs/usecases/interfaces/login.usecase.interface';
import NoticeServiceUsecase from './libs/usecases/interfaces/notice-service.usecase.interface';
import SettingsLocationUsecase from './libs/usecases/interfaces/settings-location.usecase.interface';
import SettingsReservationableTimeUsecase from './libs/usecases/interfaces/settings-reservationable-time.usecase.interface';
import SettingsAcceptableCountUsecase from './libs/usecases/interfaces/settings-acceptable-count.usecase.interface';
import StaffManagementUsecase from './libs/usecases/interfaces/staff-management.usecase.interface';
import SettingsReceptionTimeUsecase from './libs/usecases/interfaces/settings-reception-time.usecase.interface';
import SettingsCounterClosingDaysUsecase from './libs/usecases/interfaces/settings-counter-closing-days.usecase.interface';
import SettingsCounterUniqueClosingDaysUsecase from './libs/usecases/interfaces/settings-counter-unique-closing-days.usecase.interface';
import MailSettingUsecase from './libs/usecases/interfaces/mail-setting.usecase.interface';

// 実体
import PreApplicationController from './libs/gateways/controllers/pre-application.controller';
import AuthenticationController from './libs/gateways/controllers/authentication.controller';
import ReservationUserController from './libs/gateways/controllers/reservation-user.controller';
import ReservationSlotRepository from './libs/gateways/repositories/reservation-slot.repository';
import ReservationController from './libs/gateways/controllers/reservation.controller';
import RemarkController from './libs/gateways/controllers/remark.controller';
import ReservationOutputRepository from './libs/gateways/repositories/reservation-output.repository';
import LoginController from './libs/gateways/controllers/login.controller';
import NoticeServiceRepository from './libs/gateways/repositories/notice-service.repository';
import StaffManagementRepository from './libs/gateways/repositories/staff-management.repository';
import SettingsLocationRepository from './libs/gateways/repositories/settings-location.repository';
import SettingsRereservationableTimeRepository from './libs/gateways/repositories/settings-rereservationable-time';
import SettingsReceptionTimeRepository from './libs/gateways/repositories/settings-reception-time.repository';
import SettingsAcceptableCountRepository from './libs/gateways/repositories/settings-acceptable-count.repository';
import SettingsCounterClosingDaysRepository from './libs/gateways/repositories/settings-counter-closing-days.repository';
import SettingsCounterUniqueClosingDaysRepository from './libs/gateways/repositories/settings-counter-unique-closing-days.repository';
import MailSettingRepository from './libs/gateways/repositories/mail-setting.repository';

// スタブ
// import PreApplicationControllerStub from './libs/gateways/controllers/pre-application.controller.stub';
// import AuthenticationControllerStub from './libs/gateways/controllers/authentication.controller.stub';
// import ReservationUserControllerStub from './libs/gateways/controllers/reservation-user.controller.stub';
// import ReservationSlotRepositoryStub from './libs/gateways/repositories/reservation-slot.repository.stub';
// import ReservationControllerStub from './libs/gateways/controllers/reservation.controller.stub';
// import ReservationOutputRepositoryStub from './libs/gateways/repositories/reservation-output.repository.stub';
// import LoginControllerStub from './libs/gateways/controllers/login.controller.stub';
// import NoticeServiceRepositoryStub from './libs/gateways/repositories/notice-service.repository.stub';
// import SettingsLocationRepositoryStub from './libs/gateways/repositories/settings-location.repository.stub';
// import StaffManagementRepositoryStub from './libs/gateways/repositories/staff-management.repository.stub';
// import SettingsRereservationableTimeRepositoryStub from './libs/gateways/repositories/settings-rereservationable-time.stub';
// import SettingsAcceptableCountRepositoryStub from './libs/gateways/repositories/settings-acceptable-count.repository.stub';
// import SettingsReceptionTimeRepositoryStub from './libs/gateways/repositories/settings-reception-time.repository.stub';
// import SettingsCounterClosingDaysRepositoryStub from './libs/gateways/repositories/settings-counter-closing-days.repository.stub';
// import SettingsCounterUniqueClosingDaysRepositoryStub from './libs/gateways/repositories/settings-counter-unique-closing-days.repository.stub';
// import MailSettingRepositoryStub from './libs/gateways/repositories/mail-setting.repository.stub';

// 紐づけ
const rootContainer = new Container();

// 紐づけ - 実体
rootContainer.bind<PreApplicationUsecase>(TYPES.PreApplication).to(PreApplicationController);
rootContainer.bind<AuthenticationUsecase>(TYPES.Authentication).to(AuthenticationController);
rootContainer.bind<ReservationUserUsecase>(TYPES.ReservationUser).to(ReservationUserController);
rootContainer
  .bind<ReservationSlotUsecase>(TYPES.ReservationSlot)
  .to(ReservationSlotRepository)
  .inSingletonScope();
rootContainer
  .bind<ReservationUsecase>(TYPES.Reservation)
  .to(ReservationController)
  .inSingletonScope();
rootContainer.bind<RemarkUsecase>(TYPES.ReservationRemark).to(RemarkController);
rootContainer
  .bind<ReservationOutputUsecase>(TYPES.ReservationOutput)
  .to(ReservationOutputRepository)
  .inSingletonScope();
rootContainer.bind<LoginUsecase>(TYPES.Login).to(LoginController).inSingletonScope();
rootContainer
  .bind<NoticeServiceUsecase>(TYPES.Notice)
  .to(NoticeServiceRepository)
  .inSingletonScope();
rootContainer
  .bind<StaffManagementUsecase>(TYPES.StaffManagement)
  .to(StaffManagementRepository)
  .inSingletonScope();
rootContainer
  .bind<SettingsLocationUsecase>(TYPES.SettingsLocation)
  .to(SettingsLocationRepository)
  .inSingletonScope();
rootContainer
  .bind<SettingsReservationableTimeUsecase>(TYPES.SettingsReservationableTime)
  .to(SettingsRereservationableTimeRepository)
  .inSingletonScope();
rootContainer
  .bind<SettingsAcceptableCountUsecase>(TYPES.SettingsAcceptableCount)
  .to(SettingsAcceptableCountRepository)
  .inSingletonScope();
rootContainer
  .bind<SettingsReceptionTimeUsecase>(TYPES.SettingsReceptionTime)
  .to(SettingsReceptionTimeRepository)
  .inSingletonScope();
rootContainer
  .bind<SettingsCounterClosingDaysUsecase>(TYPES.SettingsCounterClosingDays)
  .to(SettingsCounterClosingDaysRepository)
  .inSingletonScope();
rootContainer
  .bind<SettingsCounterUniqueClosingDaysUsecase>(TYPES.SettingsCounterUniqueClosingDays)
  .to(SettingsCounterUniqueClosingDaysRepository)
  .inSingletonScope();
rootContainer
  .bind<MailSettingUsecase>(TYPES.MailSetting)
  .to(MailSettingRepository)
  .inSingletonScope();

// 紐づけ - スタブ
// rootContainer.bind<PreApplicationUsecase>(TYPES.PreApplication).to(PreApplicationControllerStub);
// rootContainer.bind<AuthenticationUsecase>(TYPES.Authentication).to(AuthenticationControllerStub);
// rootContainer.bind<ReservationUserUsecase>(TYPES.ReservationUser).to(ReservationUserControllerStub);
// rootContainer
//   .bind<ReservationSlotUsecase>(TYPES.ReservationSlot)
//   .to(ReservationSlotRepositoryStub)
//   .inSingletonScope();
// rootContainer
//   .bind<ReservationUsecase>(TYPES.Reservation)
//   .to(ReservationControllerStub)
//   .inSingletonScope();
// rootContainer
//   .bind<ReservationOutputUsecase>(TYPES.ReservationOutput)
//   .to(ReservationOutputRepositoryStub)
//   .inSingletonScope();
// rootContainer
//   .bind<SettingsLocationUsecase>(TYPES.SettingsLocation)
//   .to(SettingsLocationRepositoryStub)
//   .inSingletonScope();
// rootContainer.bind<LoginUsecase>(TYPES.Login).to(LoginControllerStub).inSingletonScope();
// rootContainer
//   .bind<NoticeServiceUsecase>(TYPES.Notice)
//   .to(NoticeServiceRepositoryStub)
//   .inSingletonScope();
// rootContainer
//   .bind<SettingsReservationableTimeUsecase>(TYPES.SettingsReservationableTime)
//   .to(SettingsRereservationableTimeRepositoryStub)
//   .inSingletonScope();
// rootContainer
//   .bind<StaffManagementUsecase>(TYPES.StaffManagement)
//   .to(StaffManagementRepositoryStub)
//   .inSingletonScope();
// rootContainer
//   .bind<SettingsAcceptableCountUsecase>(TYPES.SettingsAcceptableCount)
//   .to(SettingsAcceptableCountRepositoryStub)
//   .inSingletonScope();
// rootContainer
//   .bind<SettingsReceptionTimeUsecase>(TYPES.SettingsReceptionTime)
//   .to(SettingsReceptionTimeRepositoryStub)
//   .inSingletonScope();
// rootContainer
//   .bind<SettingsCounterClosingDaysUsecase>(TYPES.SettingsCounterClosingDays)
//   .to(SettingsCounterClosingDaysRepositoryStub)
//   .inSingletonScope();
// rootContainer
//   .bind<SettingsCounterUniqueClosingDaysUsecase>(TYPES.SettingsCounterUniqueClosingDays)
//   .to(SettingsCounterUniqueClosingDaysRepositoryStub)
//   .inSingletonScope();
// rootContainer
//   .bind<MailSettingUsecase>(TYPES.MailSetting)
//   .to(MailSettingRepositoryStub)
//   .inSingletonScope();

export { rootContainer };
