import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import { useContainer } from 'inversify-react';
import { TYPES } from '../../../../types';
import { PageUrl } from '../../../../libs/domains/services/page-url';
import { useReservationContext } from '../../../contexts/revervation-context';
import ReservationUsecase from '../../../../libs/usecases/interfaces/reservation.usecase.interface';
import { useIsWaitingContext } from '../../../contexts/is-waiting-context';
import { useAgentIdContext } from '../../../contexts/agent-id-context';
import ExecuteButton from '../../molecule/execute-button';
import { useSelectedManagementPageViewContext } from '../../../contexts/selected-managemaent-page-view-context';
import Notice, { NoticeType } from '../../../../libs/domains/entitis/notice';
import NoticeServiceUsecase from '../../../../libs/usecases/interfaces/notice-service.usecase.interface';
import { buttonWidth } from '../../../styles/size';
import SessionControl from '../../organism/session-control';
import { ToParent } from '../../../../libs/domains/services/to-parent';
import ClosePageDaialog from '../../organism/close-page-daialog';

const { QUERY_KEYS, ENDPOINT } = PageUrl;

const ReservationCancelConfirmPage = () => {
  const container = useContainer();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [cautionList, setCautionList] = useState<string[]>([]);

  // 読み込み中
  const { setIsWaiting } = useIsWaitingContext();

  // 代理予約者
  const { agentId } = useAgentIdContext();

  // 管理画面の表示内容
  const { setSelectedView } = useSelectedManagementPageViewContext();

  // 自治体コードとユーザーIDを取得
  const publicEntityCode = searchParams.get(QUERY_KEYS.PUBLIC_ENTITY_CODE) ?? '';
  const userId = searchParams.get(QUERY_KEYS.USER_ID) ?? agentId ?? '';
  // 不正なリクエストパラメータ
  if (userId === '' || publicEntityCode === '') {
    navigate(ENDPOINT.NOT_FOUND, { replace: true });
  }

  // 予約情報
  const { reservation, setReservation } = useReservationContext();
  const reservationUsecase = useMemo(
    () => container.get<ReservationUsecase>(TYPES.Reservation),
    [container]
  );

  const noticeServiceUsecase = useMemo(
    () => container.get<NoticeServiceUsecase>(TYPES.Notice),
    [container]
  );

  const noticeAdd = useCallback(async () => {
    if (reservation == null) {
      return;
    }
    const noticeType: NoticeType = '削除';
    const reservationNumber: string = '';
    await noticeServiceUsecase.add(
      publicEntityCode,
      noticeType,
      Notice.createContents(
        reservation?.preApplication?.userName ?? '',
        new Date(),
        reservation.location,
        reservation?.visitDateTime ?? new Date(),
        noticeType
      ),
      Notice.createDeleteDate(reservation?.visitDateTime ?? new Date()),
      reservationNumber
    );
  }, [noticeServiceUsecase, publicEntityCode, reservation]);

  // 「閉じる」警告を開くかどうか
  const [isCloseWarningOpen, setIsCloseWarningOpen] = useState<boolean>(false);

  // 次のページへ移動する
  const moveNextPage = () => {
    if (agentId == null) {
      ToParent.requestClosing();
      if (window.history.length > 1) {
        setIsCloseWarningOpen(true);
      } else {
        // 閉じる
        window.close();
      }
    } else {
      // 管理画面の予約検索へ遷移
      setSelectedView('reservation-serch-view');
    }
  };

  // 注意喚起文の作成し、キャンセル完了メールを送信
  useEffect(() => {
    if (reservation == null) {
      return;
    }

    const mailadress = reservation.user?.mailadress ?? '';
    if (mailadress === '') {
      // 送信先が無い
      setCautionList([
        'キャンセルのメールは送信されていません。',
        '予約された方へキャンセルされた旨をお伝えください。',
      ]);
      // 通知を追加して予約情報を削除
      noticeAdd();
      setReservation(null);
      return;
    }

    setIsWaiting(true);
    reservationUsecase
      .sendCanceledMail(publicEntityCode, reservation)
      .then(async () => {
        // 注意喚起文作成
        const textList = reservationUsecase.readCacnelAttention(
          reservation.preApplication?.procedureType ?? ''
        );
        setCautionList(textList);
        if (agentId != null) {
          textList.unshift('以下、注意喚起です。');
        }

        // 通知を追加して予約情報を削除
        noticeAdd();
        setReservation(null);
      })
      .finally(() => setIsWaiting(false));
  }, [
    publicEntityCode,
    reservation,
    setReservation,
    setCautionList,
    setIsWaiting,
    reservationUsecase,
    noticeAdd,
    agentId,
  ]);

  return (
    <>
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={4}>
        <div> 予約を取り消しました。</div>
        <Stack direction="column" justifyContent="center" alignItems="center">
          {cautionList.map((text, index) => {
            return <div key={index}>{text}</div>;
          })}
        </Stack>
        <ExecuteButton
          text={agentId == null ? '閉じる' : '予約検索'}
          onClick={() => moveNextPage()}
          isPrimary={true}
          type="button"
          width={buttonWidth}
        />
      </Stack>
      {/* セッション管理 */}
      {agentId == null && <SessionControl />}

      <ClosePageDaialog isOpen={isCloseWarningOpen} setIsOpen={setIsCloseWarningOpen} />
    </>
  );
};

export default ReservationCancelConfirmPage;
