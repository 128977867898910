interface SessionPossess {
  userId: string;
  token?: string;
  validityTime?: number;
  [key: string]: string | number | undefined;
}

/**
 * セッション
 */
export class Session {
  /**
   * セッションの有効期限（10分）
   */
  private readonly _expirationTime = 600000;

  /**
   * セッションの有効期限直前（9分）
   */
  private readonly _preExpirationTime = 540000;

  /**
   * 保存キー
   */
  private readonly _key = 'sessions';

  /**
   * コンストラクタ
   */
  private constructor(private _isCleanUp: boolean) {
    if (this._isCleanUp) {
      // なるべくゴミ掃除することを推奨する
      this.removeDisableUser();
    }
  }

  /**
   * ユーザー毎のトークンを取得
   */
  readToken(userId: string): string | null {
    const setttion: SessionPossess = this.readUser(userId);
    return setttion?.token ?? null;
  }

  /**
   * ユーザー毎のトークンを保存
   */
  updateToken(userId: string, token?: string): void {
    const session: SessionPossess = this.readUser(userId);
    if (token == null) {
      delete session.token;
    } else {
      session.token = token;
    }
    this.updateUser(session);
  }

  /**
   * ユーザー毎のセッション有効時間の取得
   */
  readValidityTime(userId: string): Date | null {
    const setttion: SessionPossess = this.readUser(userId);
    const validityTime = setttion?.validityTime;
    return validityTime == null ? null : new Date(validityTime);
  }
  /**
   * ユーザー毎のセッション有効時間を保存
   */
  updateValidityTime(userId: string, datetime?: Date): void {
    const session: SessionPossess = this.readUser(userId);
    if (datetime == null) {
      delete session.validityTime;
    } else {
      session.validityTime = datetime.getTime();
    }
    this.updateUser(session);
  }

  /**
   * セッションが無効なユーザーを削除
   */
  removeDisableUser() {
    try {
      const items = this.read();
      const enableUsers = items.filter((items) => {
        const validityTime = items.validityTime ?? 0;
        const nowTime = new Date().getTime();
        return nowTime - validityTime < this._expirationTime;
      });
      localStorage.setItem(this._key, JSON.stringify(enableUsers));
    } catch {
      return;
    }
  }

  /**
   * セッションがタイムアウト直前かどうか
   */
  isPreTimeout(userId: string): boolean {
    const session: SessionPossess = this.readUser(userId);
    if (session.validityTime == null) {
      return true;
    }
    const nowTime = new Date().getTime();
    const elapsedtime = nowTime - session.validityTime;
    return elapsedtime >= this._preExpirationTime;
  }

  /**
   * セッションがタイムアウトかどうかを管理する
   */
  isTimeout(userId: string): boolean {
    const session: SessionPossess = this.readUser(userId);
    if (session.validityTime == null) {
      return true;
    }
    const nowTime = new Date().getTime();
    const elapsedtime = nowTime - session.validityTime;
    const isTimeout = elapsedtime >= this._expirationTime;
    if (isTimeout) {
      this.removeDisableUser();
    }
    return isTimeout;
  }

  /**
   * ストレージから全データを読み込み
   */
  private read(): SessionPossess[] {
    try {
      return JSON.parse(localStorage.getItem(this._key) ?? '[]') as SessionPossess[];
    } catch {
      return [];
    }
  }

  /**
   * ストレージから対象ユーザーを読み込み
   */
  private readUser(userId: string): SessionPossess {
    const settion: SessionPossess = { userId };
    try {
      const items = this.read();
      const item = items.find((item) => item.userId === userId);
      if (item == null) {
        return settion;
      }

      for (let key in item) {
        const value = item[key];
        settion[key] = value;
      }
      return settion;
    } catch {
      return settion;
    }
  }

  /**
   * 対象ユーザのセッションを更新
   */
  private updateUser(session: SessionPossess) {
    try {
      const items = this.read();
      const updateList = items.filter((item) => item.userId !== session.userId);
      updateList.push(session);
      localStorage.setItem(this._key, JSON.stringify(updateList));
    } catch {
      return;
    }
  }

  /**
   * 新規作成
   * @param isCleanUp // ゴミを掃除するかどうか
   * @returns セッション管理
   */
  static create(isCleanUp: boolean = true) {
    return new Session(isCleanUp);
  }
}
