import React from 'react';
import { Link, Stack } from '@mui/material';

interface PropsType {
  itemName: string;
  onClick: () => void;
  isSelected?: boolean;
}

const MenuBotton = ({ itemName, onClick, isSelected = false }: PropsType) => {
  const selectedMenuDecoration = () => {
    return isSelected ? ' bg-gray-600' : '';
  };

  return (
    <Stack
      className={'hover:bg-gray-600 hover:cursor-pointer p-2 rounded-xl' + selectedMenuDecoration()}
      onClick={onClick}
    >
      <Link
        className="no-underline"
        component="button"
        variant="body2"
        color="inherit"
        style={{ textDecoration: 'none' }}
      >
        {itemName}
      </Link>
    </Stack>
  );
};

export default MenuBotton;
