import { Stack } from '@mui/material';
import React from 'react';
import WaitAMoment from '../../molecule/wait-a-moment';

const LoadingPage = () => {
  return (
    <Stack spacing={4}>
      <WaitAMoment isLoading={true} />
    </Stack>
  );
};

export default LoadingPage;
