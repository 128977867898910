import { StringUtil } from '../../utils/string-util';

export namespace SendMail {
  /**
   * 送信メール種別の定義
   */
  export const SEND_MAIL_TYPE = {
    CANCEL: 'cancel',
    CHANGE: 'change',
    COMPLETE: 'complete',
  } as const;

  /**
   * 送信メール種別
   */
  export type SendMailType = (typeof SEND_MAIL_TYPE)[keyof typeof SEND_MAIL_TYPE];

  /**
   * メール内容
   */
  export interface Content {
    type: SendMailType; // 送信メール種別
    mailFrom: string; // 送信元メールアドレス
    mailTo: string; // 送信先メールアドレス
    subject: string; // 件名
    message: string; // 本文
  }

  /**
   * 予約完了・変更メール本文作成
   * @param date  予約日時
   * @param number 予約番号
   * @param location  来庁場所
   * @param belongings  持ち物一覧
   * @param changeUrl 遷移先URL
   * @param template メール本文ひな形
   * @returns メール内容
   */
  export const generateReservedMessage = (
    date: string,
    number: string,
    location: string,
    belongings: string,
    changeUrl: string,
    template: string
  ): string => {
    return StringUtil.format(
      template,
      `・${date}`,
      `・${number}`,
      `・${location}`,
      belongings
        .split(',')
        .map((belonging) => `・${belonging}`)
        .join('\n'),
      `${window.location.origin}${changeUrl}`
    );
  };

  /**
   * 送信メール作成
   * @param type  送信メール種別
   * @param from  送信元メールアドレス
   * @param to  送信先メールアドレス
   * @param subject メール件名
   * @param message メール本文
   * @param contact 問合せ先
   * @returns メール内容
   */
  export const generateContent = (
    type: SendMailType,
    from: string,
    to: string,
    subject: string,
    message: string,
    contact: string
  ): Content => {
    return {
      type,
      mailFrom: from,
      mailTo: to,
      subject,
      message: message + contact,
    };
  };
}
