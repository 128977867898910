/**
 * 受付可能組数のプロパティ
 */
export interface AcceptableCountProperties {
  dow: string;
  acceptableCount: number;
}

/**
 * 受付可能組数情報
 */
export default class AcceptableCount {
  private constructor(
    private readonly _dow: string, // 曜日
    private _acceptableCount: number // 受付可能組数
  ) {}

  /**
   * 曜日
   */
  get dow(): string {
    return this._dow;
  }
  /**
   * 受付可能組数
   */
  get acceptableCount(): number {
    return this._acceptableCount;
  }
  set acceptableCount(count: number) {
    this._acceptableCount = count;
  }

  /**
   * コピーの作成
   */
  static copy(previos: AcceptableCount) {
    return this.create({ dow: previos.dow, acceptableCount: previos.acceptableCount });
  }

  /**
   * 新規作成
   */
  static create(properties: AcceptableCountProperties) {
    return new AcceptableCount(properties.dow, properties.acceptableCount);
  }
}
