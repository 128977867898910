import React, { useEffect, useMemo, useState } from 'react';
import { Stack, Typography, IconButton, Badge } from '@mui/material';
import { NotificationsNoneOutlined } from '@mui/icons-material';
import { Subscription } from 'rxjs';
import NoticeServiceUsecase from '../../../../libs/usecases/interfaces/notice-service.usecase.interface';
import { TYPES } from '../../../../types';
import { useContainer } from 'inversify-react';
import Notice from '../../../../libs/domains/entitis/notice';
import CommonDialog from '../../molecule/common-dialog';
import NoticesView from '../../organism/notices-view/NoticesView';
import { useLoginStaffContext } from '../../../contexts/login-staff-context';

const ManagementHedder = () => {
  const container = useContainer();

  // ダイアログ操作
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // 通知
  const noticeServiceUsecase = useMemo(
    () => container.get<NoticeServiceUsecase>(TYPES.Notice),
    [container]
  );

  // 通知数
  const [unreadCount, setUnreadCount] = useState(0);

  // 通知一覧
  const [notices, setNotices] = useState<Notice[]>([]);

  const { loginStaff } = useLoginStaffContext();

  useEffect(() => {
    const subscription = new Subscription();
    const run = async () => {
      // 最新ログ取得
      subscription.add(
        noticeServiceUsecase.notice$?.subscribe((payload: Notice[]) => {
          if (payload == null) {
            return;
          }
          // 未読数を更新
          setUnreadCount(noticeServiceUsecase.readUnreadCount());
          // 通知一覧を更新
          setNotices(payload);
        })
      );
    };
    run();

    return () => {
      // 切り替え時の処理
      subscription.unsubscribe();
    };
  }, [container, noticeServiceUsecase]);

  return (
    <>
      <Stack className="pr-4" direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" color="initial">
          窓口予約
        </Typography>

        <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
          <div>{loginStaff?.loginId}</div>
          <Badge badgeContent={unreadCount} color="error">
            <IconButton onClick={handleClickOpen}>
              <NotificationsNoneOutlined />
            </IconButton>
          </Badge>
        </Stack>
      </Stack>

      {/* 通知一覧表示 */}
      <CommonDialog
        open={open}
        isSlideIn={true}
        onClose={handleClose}
        content={<NoticesView onClose={handleClose} notices={notices} />}
      />
    </>
  );
};

export default ManagementHedder;
