import React from 'react';
import { Stack, Typography } from '@mui/material';

const ReservationHeader = () => {
  return (
    <Stack
      className="relative p-4 w-screen bg-white"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography variant="h6" color="initial">
        窓口予約
      </Typography>
    </Stack>
  );
};

export default ReservationHeader;
