/**
 * 入力値の検証ルールの管理
 */
export namespace Validation {
  /**
   * 検証ルール
   */
  export const RULES = {
    email: {
      required: 'メールアドレスを入力してください。',
      minLength: { value: 6, message: '6文字以上で入力してください。' },
      maxLength: { value: 254, message: '254文字以下で入力してください。' },
      pattern: {
        value: /^[A-Za-z0-9]+[A-Za-z0-9_.-]*@[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/,
        message: 'メールアドレスを入力してください。',
      },
    },
    code: {
      required: '認証コードを入力してください',
      minLength: { value: 6, message: '正しい認証コードを入力してください。' },
      maxLength: { value: 6, message: '正しい認証コードを入力してください。' },
      pattern: {
        value: /[0-9]/,
        message: '数字のみ入力できます。',
      },
    },
    loginId: {
      required: 'ログインIDを入力してください。',
      pattern: {
        value: /^(?!.*\|).*$/,
        message: '禁止文字が入力されています。',
      },
    },
    password: {
      required: 'パスワードを入力してください。',
    },
  } as const;

  /**
   * 検証対象入力欄の名前一覧
   */
  export const NAMES = {
    email: 'email',
    code: 'code',
    loginId: 'loginId',
    password: 'password',
  } as const;

  /**
   * 検証対象入力欄のデフォルト値一覧
   */
  export const DEFAULTVALUES = {
    email: {
      defaultValues: { email: '' },
    },
    code: {
      defaultValues: { code: '' },
    },
    login: {
      defaultValues: { loginId: '', password: '' },
    },
  } as const;

  /**
   * メール入力画面のチェック対象
   */
  export type EmailInputs = { email: string };
  /**
   * メール入力画面のチェック対象
   */
  export type CodeInputs = { code: string };
  /**
   * ログイン画面のチェック対象
   */
  export type LoginInputs = { loginId: string; password: string };
}
