import { createContext, ReactNode, useContext, useState, Dispatch, SetStateAction } from 'react';

interface AgentIdContextType {
  agentId: string | null;
  setAgentId: Dispatch<SetStateAction<string | null>>;
}

/**
 * 作成したコンテキストは一意の名称とする
 */
const agentIdContext = createContext<AgentIdContextType>({} as AgentIdContextType);

/**
 * 外部から設定値を取り出す用
 */
export const useAgentIdContext = () => useContext(agentIdContext);

/**
 * コンテキストを提供する
 */
export const AgentIdProvider = ({ children }: { children: ReactNode }) => {
  const [agentId, setAgentId] = useState<string | null>(null);
  const { Provider } = agentIdContext;

  const value = {
    agentId,
    setAgentId,
  };

  return <Provider value={value}>{children}</Provider>;
};
