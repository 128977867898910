import { injectable } from 'inversify';
import SettingsAcceptableCountUsecase from '../../usecases/interfaces/settings-acceptable-count.usecase.interface';
import AcceptableCount from '../../domains/entitis/acceptable-count';
import { HttpRequest } from '../../utils/http-request';
import { ErrorHandler } from '../../domains/services/error-handler';

interface ResponseType {
  dow: string;
  acceptableCount: number;
}
@injectable()
export default class SettingsAcceptableCountRepository implements SettingsAcceptableCountUsecase {
  private readonly _endpoint = 'acceptable-count';
  private readonly _defaultAcceptableCounts: AcceptableCount[] = [
    AcceptableCount.create({ dow: '日', acceptableCount: 0 }),
    AcceptableCount.create({ dow: '月', acceptableCount: 0 }),
    AcceptableCount.create({ dow: '火', acceptableCount: 0 }),
    AcceptableCount.create({ dow: '水', acceptableCount: 0 }),
    AcceptableCount.create({ dow: '木', acceptableCount: 0 }),
    AcceptableCount.create({ dow: '金', acceptableCount: 0 }),
    AcceptableCount.create({ dow: '土', acceptableCount: 0 }),
    AcceptableCount.create({ dow: '祝', acceptableCount: 0 }),
  ];
  private _acceptableCounts: AcceptableCount[] = [];

  async fetch(entityCode: string, location: string): Promise<void> {
    const requestUrl = HttpRequest.generateUrl(this._endpoint);
    HttpRequest.addUrlParamater(requestUrl, 'public-entity-code', entityCode);
    HttpRequest.addUrlParamater(requestUrl, 'location', location);
    const response = await HttpRequest.requestGet<ResponseType[]>(requestUrl);

    // 一旦初期化
    this._acceptableCounts = this._defaultAcceptableCounts.map((counts) => {
      return AcceptableCount.copy(counts);
    });

    if (
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    ) {
      return;
    }

    // データ保持
    const acceptableCounts =
      response.data?.data == null
        ? this._acceptableCounts.map((item) =>
            AcceptableCount.create({
              dow: item.dow,
              acceptableCount: 0,
            })
          )
        : response.data.data.map((item) => {
            return AcceptableCount.create({
              dow: item.dow,
              acceptableCount: item.acceptableCount,
            });
          });
    for (let acceptableCount of acceptableCounts) {
      const index = this._acceptableCounts.findIndex((row) => row.dow === acceptableCount.dow);
      if (index < 0) {
        continue;
      }
      this._acceptableCounts[index].acceptableCount = acceptableCount.acceptableCount;
    }

    return;
  }
  read(): AcceptableCount[] {
    return this._acceptableCounts.map((acceptableCount) => AcceptableCount.copy(acceptableCount));
  }

  async update(
    entityCode: string,
    location: string,
    weekDays: AcceptableCount[]
  ): Promise<boolean> {
    const dows = weekDays.map((receptionTime) => {
      return {
        dow: receptionTime.dow,
        acceptableCount: receptionTime.acceptableCount,
      };
    });
    const requestUrl = HttpRequest.generateUrl(this._endpoint);
    const response = await HttpRequest.requestPut<null>(requestUrl, {
      publicEntityCode: entityCode,
      location,
      dows,
    });
    return !(
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    );
  }
}
