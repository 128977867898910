import React, { useEffect, useMemo } from 'react';
import {
  Alert,
  AlertColor,
  Snackbar,
  SnackbarOrigin,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Validation } from '../../../../libs/domains/services/validation';
import ExecuteButton from '../../molecule/execute-button';
import { PageUrl } from '../../../../libs/domains/services/page-url';
import { useContainer } from 'inversify-react';
import LoginUsecase from '../../../../libs/usecases/interfaces/login.usecase.interface';
import { TYPES } from '../../../../types';
import { useLoginStaffContext } from '../../../contexts/login-staff-context';
import { useIsWaitingContext } from '../../../contexts/is-waiting-context';
import { useUserAuthenticatedContext } from '../../../contexts/user-authenticated-context';
import { ErrorHandler } from '../../../../libs/domains/services/error-handler';
import { buttonWidth } from '../../../styles/size';
import { Staff } from '../../../../libs/domains/entitis/staff';

const { QUERY_KEYS, ENDPOINT } = PageUrl;

interface State extends SnackbarOrigin {
  open: boolean;
  severity: AlertColor;
  alartMessage: string;
}

const LoginPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const container = useContainer();
  const loginUsecase = useMemo(() => container.get<LoginUsecase>(TYPES.Login), [container]);

  // リクエストパラメータから自治体コードを取得
  const publicEntityCode = searchParams.get(QUERY_KEYS.PUBLIC_ENTITY_CODE) ?? '';

  // ログイン中スタッフ
  const { setLoginStaff } = useLoginStaffContext();

  // 読み込み中
  const { setIsWaiting } = useIsWaitingContext();

  // 認証済みユーザーかどうか
  const { setUserAuthenticated } = useUserAuthenticatedContext();

  // 入力内容のチェック
  const {
    control: controlLoginId,
    control: controlPassword,
    handleSubmit,
  } = useForm<Validation.LoginInputs>(Validation.DEFAULTVALUES.login);

  // ログイン失敗時メッセージ表示
  const [snakState, setSnakState] = React.useState<State>({
    open: false,
    alartMessage: '',
    severity: 'info',
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, alartMessage, horizontal, open, severity } = snakState;

  const handleSnackClose = () => {
    setSnakState({ ...snakState, open: false });
  };

  // 次のページへ移動する
  const moveNextPage = () => {
    const queryPublicEntityCode = {
      key: QUERY_KEYS.PUBLIC_ENTITY_CODE,
      value: publicEntityCode,
    };

    // 管理画面へ遷移
    navigate(PageUrl.generate(ENDPOINT.MANAGEMENT, queryPublicEntityCode), { replace: true });
  };

  useEffect(() => {
    // 不正なリクエストパラメータ
    if (publicEntityCode === '') {
      navigate(ENDPOINT.NOT_FOUND, { replace: true });
    }
  }, [navigate, publicEntityCode]);

  // ログインボタンを押したときの処理
  const login: SubmitHandler<Validation.LoginInputs> = async (data) => {
    setIsWaiting(true);
    loginUsecase
      .verify(Staff.generateUserName(publicEntityCode, data.loginId), data.password)
      .then((loginStaff) => {
        if (loginStaff == null) {
          setSnakState({
            ...snakState,
            open: true,
            severity: 'warning',
            alartMessage: ErrorHandler.getErrorMessage(ErrorHandler.CODE.FAILED_STAFF_LOGIN),
          });
          return;
        }
        setLoginStaff(loginStaff);
        setUserAuthenticated(loginStaff.accessToken);
        moveNextPage();
      })
      .catch(() => {})
      .finally(() => {
        setIsWaiting(false);
      });
  };
  return (
    <>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={4}
        component="form"
        noValidate
      >
        <Stack className="w-5/6 max-w-md">
          <Stack className="relative w-full h-full" spacing={5}>
            <Stack
              className="w-full text-xs"
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h6" className="text-gray-500">
                管理画面
              </Typography>
            </Stack>

            <Controller
              name={Validation.NAMES.loginId}
              control={controlLoginId}
              rules={Validation.RULES.loginId}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  fullWidth
                  label="ログインID"
                  focused
                />
              )}
            />
            <Controller
              name={Validation.NAMES.password}
              control={controlPassword}
              rules={Validation.RULES.password}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  fullWidth
                  label="パスワード"
                  focused
                  type={'password'}
                />
              )}
            />

            <Stack
              className="w-full "
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={12}
            >
              <ExecuteButton
                text="ログイン"
                onClick={handleSubmit(login)}
                isPrimary={true}
                type="submit"
                width={buttonWidth}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={6000}
        open={open}
        onClose={handleSnackClose}
        key={vertical + horizontal}
      >
        <Alert severity={severity}>{alartMessage}</Alert>
      </Snackbar>
    </>
  );
};

export default LoginPage;
