import { Box, Fab, Stack } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ExecuteButton from '../../molecule/execute-button';
import CommonDialog from '../../molecule/common-dialog';
import { useContainer } from 'inversify-react';
import { useSearchParams } from 'react-router-dom';
import { PageUrl } from '../../../../libs/domains/services/page-url';
import { useIsWaitingContext } from '../../../contexts/is-waiting-context';
import StaffManagementUsecase from '../../../../libs/usecases/interfaces/staff-management.usecase.interface';
import { TYPES } from '../../../../types';
import StaffCreate from '../../organism/staff-create';
import { usePreStaffInformationContext } from '../../../contexts/pre-staff-information-context';
import StaffDelete from '../../organism/staff-delete';
import { useNewStaffInformationContext } from '../../../contexts/new-staff-information-context';
import { Role, Staff } from '../../../../libs/domains/entitis/staff';
import StaffEntryConfirmation from '../../organism/staff-entry-confirmation';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ErrorHandler } from '../../../../libs/domains/services/error-handler';
import { useLoginStaffContext } from '../../../contexts/login-staff-context';

const { QUERY_KEYS } = PageUrl;

/**
 * 表の行
 */
interface RowType {
  id: string;
  permission: Role;
  expirationDateStartTime: string;
  expirationDateEndTime: string;
}

const StaffManagementPage = () => {
  const container = useContainer();
  const [searchParams] = useSearchParams();

  const { loginStaff } = useLoginStaffContext();

  // 読み込み中
  const { setIsWaiting } = useIsWaitingContext();

  // 自治体コードを取得
  const publicEntityCode = searchParams.get(QUERY_KEYS.PUBLIC_ENTITY_CODE) ?? '';

  // 職員情報
  const staffManagementUsecase = useMemo(
    () => container.get<StaffManagementUsecase>(TYPES.StaffManagement),
    [container]
  );

  // 登録済みの職員情報
  const { setPreStaff } = usePreStaffInformationContext();
  // 新規入力した職員情報
  const { newStaff } = useNewStaffInformationContext();

  // 職員情報表示データ
  const [viewRows, setViewRows] = useState<RowType[]>([]);

  // 列ヘッダーの情報
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 150 },
    {
      field: 'permission',
      headerName: '権限',
      width: 150,
      editable: true,
    },
    {
      field: 'expirationDateStartTime',
      headerName: '開始日',
      width: 150,
      editable: true,
    },
    {
      field: 'expirationDateEndTime',
      headerName: '終了日',
      width: 150,
      editable: true,
    },
    {
      field: 'edit',
      headerName: '',
      sortable: false,
      width: 100,
      filterable: false,
      renderCell: (params) => (
        <ExecuteButton text="編集" onClick={() => openEditUserDialog(params.row)} />
      ),
    },
    {
      field: 'cancel',
      headerName: '',
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <ExecuteButton
          text="削除"
          isPrimary={false}
          onClick={() => openDeleteUserDialog(params.row.id)}
        />
      ),
    },
  ];

  // 再読み込み
  const relaod = useCallback(() => {
    const readStaffs = staffManagementUsecase.read();
    const views: RowType[] = readStaffs.map((readStaff) => {
      return {
        id: readStaff.loginId,
        permission: readStaff.permission,
        expirationDateStartTime: readStaff.createExpirationFromDateViewText(),
        expirationDateEndTime: readStaff.createExpirationToDateViewText(),
      };
    });
    setViewRows(views);
  }, [setViewRows, staffManagementUsecase]);

  // ユーザ追加ダイアログ表示
  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);
  const openAddUserDialog = () => {
    setPreStaff(null);
    setIsAddUserDialogOpen(true);
  };
  const closeAddUserDialog = () => {
    setIsAddUserDialogOpen(false);
  };

  // 新規追加実行処理
  const onSaveNewUser = async () => {
    setIsWaiting(true);
    try {
      if (newStaff) {
        if (!(await staffManagementUsecase.add(newStaff, loginStaff?.accessToken ?? ''))) {
          alert(ErrorHandler.getErrorMessage(ErrorHandler.CODE.FAILED_STAFF_ADD));
          return;
        }
        await staffManagementUsecase.fetch(publicEntityCode);
        relaod();
      }
    } finally {
      closeAddUserDialog();
      setIsWaiting(false);
    }
  };

  // ユーザ編集ダイアログ表示
  const [isEditUserDialogOpen, setIsEditUserDialogOpen] = useState(false);
  const openEditUserDialog = (preStaffOne: RowType) => {
    setPreStaff(
      Staff.create({
        userName: Staff.generateUserName(publicEntityCode, preStaffOne.id),
        password: '',
        publicEntityCode: publicEntityCode,
        permission: preStaffOne.permission,
        expirationFrom: new Date(preStaffOne.expirationDateStartTime.replace(/-/g, '/')),
        expirationTo: new Date(preStaffOne.expirationDateEndTime.replace(/-/g, '/')),
      })
    );
    setIsEditUserDialogOpen(true);
  };
  const closeEditUserDialog = () => {
    setIsEditUserDialogOpen(false);
  };

  // ユーザ編集内容保存確認ダイアログ表示
  const [isEntryConfirmationOpen, setIsEntryConfirmationOpen] = useState(false);
  const openEntryConfirmationDialog = () => {
    setIsEntryConfirmationOpen(true);
  };
  const closeEntryConfirmationDialog = () => {
    setIsEntryConfirmationOpen(false);
  };

  // 編集実行処理
  const onSaveEditUser = async () => {
    setIsWaiting(true);
    try {
      if (newStaff) {
        if (!(await staffManagementUsecase.update(newStaff, loginStaff?.accessToken ?? ''))) {
          alert(ErrorHandler.getErrorMessage(ErrorHandler.CODE.FAILED_STAFF_SAVE));
          return;
        }
        await staffManagementUsecase.fetch(publicEntityCode);
        relaod();
      }
    } finally {
      closeEntryConfirmationDialog();
      closeEditUserDialog();
      setIsWaiting(false);
    }
  };

  // ユーザ削除ダイアログ表示
  const [isDeleteUserDialogOpen, setIsDeleteUserDialogOpen] = useState(false);
  const [deleteUserName, setDeleteUserName] = useState('');
  const openDeleteUserDialog = (id: string) => {
    setDeleteUserName(Staff.generateUserName(publicEntityCode, id));
    setIsDeleteUserDialogOpen(true);
  };
  const closeDeleteUserDialog = () => {
    setIsDeleteUserDialogOpen(false);
  };

  // 削除実行処理
  const onDeleteOk = async () => {
    setIsWaiting(true);
    try {
      if (!(await staffManagementUsecase.remove(deleteUserName))) {
        alert(ErrorHandler.getErrorMessage(ErrorHandler.CODE.FAILED_STAFF_REMOVE));
        return;
      }
      await staffManagementUsecase.fetch(publicEntityCode);
      relaod();
    } finally {
      closeDeleteUserDialog();
      setIsWaiting(false);
    }
  };

  // 職員一覧取得
  useEffect(() => {
    setIsWaiting(true);
    staffManagementUsecase
      .fetch(publicEntityCode)
      .then(() => relaod())
      .catch(() => [])
      .finally(() => setIsWaiting(false));
  }, [setIsWaiting, staffManagementUsecase, publicEntityCode, relaod]);

  return (
    <>
      {/* ユーザ追加用ダイアログ */}
      <CommonDialog
        open={isAddUserDialogOpen}
        onClose={closeAddUserDialog}
        content={
          <StaffCreate onCancel={closeAddUserDialog} onSave={onSaveNewUser} isEdit={false} />
        }
      />
      {/* ユーザ編集用ダイアログ */}
      <CommonDialog
        open={isEditUserDialogOpen}
        onClose={closeEditUserDialog}
        content={
          <StaffCreate
            onCancel={closeEditUserDialog}
            onSave={openEntryConfirmationDialog}
            isEdit={true}
          />
        }
      />
      {/* ユーザ編集内容保存確認ダイアログ */}
      <CommonDialog
        open={isEntryConfirmationOpen}
        onClose={closeEntryConfirmationDialog}
        content={
          <StaffEntryConfirmation onCancel={closeEntryConfirmationDialog} onOk={onSaveEditUser} />
        }
      />

      {/* ユーザ削除用ダイアログ */}
      <CommonDialog
        open={isDeleteUserDialogOpen}
        onClose={closeDeleteUserDialog}
        content={<StaffDelete onOk={onDeleteOk} onCancel={closeDeleteUserDialog} />}
      />
      {/* メイン画面 */}
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={4}>
        <Stack width="100%" direction="row-reverse" className="p-4">
          <Fab color="primary" aria-label="edit" variant="extended" onClick={openAddUserDialog}>
            <AddCircleIcon className="mr-2" />
            追加
          </Fab>
        </Stack>

        <Stack>
          <Box sx={{ height: 500, width: '100%' }}>
            <DataGrid
              rows={viewRows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
            />
          </Box>
        </Stack>
      </Stack>
    </>
  );
};

export default StaffManagementPage;
