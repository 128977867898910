import { injectable } from 'inversify';
import ReservationOutputUsecase from '../../usecases/interfaces/reservation-output.usecase.interface';
import ReservationOutput from '../../domains/value-objects/reservation-output';
import { HttpRequest } from '../../utils/http-request';
import { TimeControl } from '../../utils/time-control';
import { ErrorHandler } from '../../domains/services/error-handler';

interface FetchResponseType {
  number: string; // 予約番号
  location: string; // 来庁場所
  datetime: string; // 予約日時
  procedureType: string; // 申請種別
}

@injectable()
export default class ReservationOutputRepository implements ReservationOutputUsecase {
  async add(
    publicEntityCode: string,
    procedureType: string,
    dateTime: string,
    location: string,
    reservationNumber: string
  ): Promise<boolean> {
    return true;
  }

  async fetchList(
    publicEntityCode: string,
    location: string,
    dateRange: {
      dateFrom: Date;
      dateTo: Date;
    }
  ): Promise<ReservationOutput[]> {
    const requestUrl = HttpRequest.generateUrl('reservation-outputs');
    HttpRequest.addUrlParamater(requestUrl, 'public-entity-code', publicEntityCode);
    HttpRequest.addUrlParamater(requestUrl, 'location', location);
    const dateFrom = TimeControl.createDateTimeTimeZoneText(dateRange.dateFrom);
    HttpRequest.addUrlParamater(requestUrl, 'date-from', dateFrom);
    const dateTo = TimeControl.createDateTimeTimeZoneText(dateRange.dateTo);
    HttpRequest.addUrlParamater(requestUrl, 'date-to', dateTo);
    const response = await HttpRequest.requestGet<FetchResponseType[]>(requestUrl);
    if (
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    ) {
      return [];
    }
    const reservationOutputs = response.data?.data ?? null;
    if (reservationOutputs == null || reservationOutputs.length <= 0) {
      return [];
    }
    return reservationOutputs
      .map((reservationOutput) => {
        return ReservationOutput.create({
          procedureType: reservationOutput.procedureType,
          visitDateTime: new Date(reservationOutput.datetime),
          location: reservationOutput.location,
          reservationNumber: reservationOutput.number,
        });
      })
      .sort((a, b) => a.visitDateTime.getTime() - b.visitDateTime.getTime());
  }

  async delete(reserveNumber: string): Promise<boolean> {
    return true;
  }
}
