import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IconButton, List, ListItem, ListItemText, Stack, Checkbox } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Notice from '../../../../libs/domains/entitis/notice';
import { useContainer } from 'inversify-react';
import { TYPES } from '../../../../types';
import NoticeServiceUsecase from '../../../../libs/usecases/interfaces/notice-service.usecase.interface';
import { useIsWaitingContext } from '../../../contexts/is-waiting-context';
import ReservationDetailPage from '../../pages/reservation-detail-page';
import ReservationUsecase from '../../../../libs/usecases/interfaces/reservation.usecase.interface';
import ExecuteButton from '../../molecule/execute-button';
import { buttonWidth } from '../../../styles/size';
import CommonDialog from '../../molecule/common-dialog';

interface PropsType {
  onClose: () => void; // ダイアログを閉じるときの処理
  notices: Notice[]; // 通知一覧
}

const style = {
  width: '80%',
  minWidth: 360,
  fontSize: '0.8rem',
  border: 1,
  borderColor: 'lightgray',
  bgcolor: 'background.paper',
};

const NoticesView = ({ onClose, notices }: PropsType) => {
  const container = useContainer();

  // 通知
  const noticeServiceUsecase = useMemo(
    () => container.get<NoticeServiceUsecase>(TYPES.Notice),
    [container]
  );

  // 予約
  const reservationUsecase = useMemo(
    () => container.get<ReservationUsecase>(TYPES.Reservation),
    [container]
  );

  // 読み込み中
  const { setIsWaiting } = useIsWaitingContext();

  // 詳細画面表示
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // 「警告」ダイアログを開くかどうか
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState<boolean>(false);

  // 選択された予約の番号
  const [selectedReservationNumber, setSelectedReservationNumber] = useState('');

  // 既読
  const doMarkAllAsRead = useCallback(async () => {
    setIsWaiting(true);
    await noticeServiceUsecase.doMarkAllAsRead();
    setIsWaiting(false);
  }, [noticeServiceUsecase, setIsWaiting]);

  // ページ遷移可能かどうかの判定
  const possibleTransition = (targetNotice: Notice) => {
    // 削除されている場合は遷移不可
    if (targetNotice.type === '削除') {
      return false;
    }

    // 同じ予約番号の場合は最新以外は遷移不可
    const noticeList = notices.filter(
      (notice) =>
        notice.reservationNumber === targetNotice.reservationNumber && notice.type !== '削除'
    );
    if (
      noticeList.length > 1 &&
      noticeList.findIndex((notice) => notice.contents === targetNotice.contents) !== 0
    ) {
      return false;
    }

    // 遷移可能
    return true;
  };

  // 通知をクリック時の処理
  const handleRowClick = async (reservationNumber: string, isWork: boolean) => {
    if (!isWork) {
      return;
    }
    try {
      setIsWaiting(true);
      const current = await reservationUsecase.fetch(undefined, reservationNumber);

      if (current == null) {
        setIsWarningDialogOpen(true);
        return;
      }

      // 詳細画面を表示
      setSelectedReservationNumber(reservationNumber);
      setIsOpen(true);
    } finally {
      setIsWaiting(false);
    }
  };

  useEffect(() => {
    doMarkAllAsRead();
  }, [doMarkAllAsRead]);

  return isOpen ? (
    <>
      {/* 予約内容詳細画面 */}
      <ReservationDetailPage
        reservationNumber={selectedReservationNumber}
        onReturn={() => setIsOpen(false)}
        onEdit={async () => {
          await doMarkAllAsRead();
          onClose();
        }}
      />
    </>
  ) : (
    <>
      <Stack className="relative h-full w-full p-4">
        <Stack justifyContent="center" alignItems="center">
          <div className="fixed top-4 right-8">
            <IconButton
              edge="start"
              color="inherit"
              onClick={async () => {
                await doMarkAllAsRead();
                onClose();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </Stack>

        <Stack justifyContent="center" alignItems="center" spacing={4}>
          <div>最新の通知を表示しています。</div>
          <List
            className=""
            sx={style}
            component="nav"
            aria-label="mailbox folders"
            style={{ padding: 0 }}
          >
            {notices.map((notice, index) => (
              <ListItem key={index} divider className="w-full" style={{ padding: 0 }}>
                <Stack
                  className="grow w-full h-full pl-1"
                  direction="row"
                  justifyContent="stretch"
                  alignItems="center"
                >
                  <Checkbox
                    checked={notice.isDone}
                    onChange={async (event) => {
                      const isDone = event.target.checked;
                      setIsWaiting(true);
                      await noticeServiceUsecase.doneToggle(isDone, notice.id);
                      setIsWaiting(false);
                    }}
                  />
                  <ListItemText
                    className={`grow h-full px-1 py-2 ${
                      possibleTransition(notice)
                        ? 'cursor-pointer hover:bg-cyan-100'
                        : 'text-gray-500'
                    }`}
                    onClick={() =>
                      handleRowClick(notice.reservationNumber, possibleTransition(notice))
                    }
                    primary={notice.contents}
                  />
                </Stack>
              </ListItem>
            ))}
          </List>
        </Stack>
      </Stack>

      <CommonDialog
        open={isWarningDialogOpen}
        onClose={() => {
          setIsWarningDialogOpen(false);
        }}
        content={
          <Stack className="w-full h-full" justifyContent="center" alignItems="center" spacing={6}>
            <Stack className="w-full pt-10 pb-6" justifyContent="center" alignItems="center">
              <div>{'この予約はキャンセルされている可能性があるため、'}</div>
              <div>{'開くことができません。'}</div>
            </Stack>
            <Stack
              className="w-full flex-wrap"
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={6}
            >
              <ExecuteButton
                className="mx-2 my-1"
                text="閉じる"
                onClick={() => setIsWarningDialogOpen(false)}
                isPrimary={true}
                width={buttonWidth}
              />
            </Stack>
          </Stack>
        }
      />
    </>
  );
};

export default NoticesView;
