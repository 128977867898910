import { useContainer } from 'inversify-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PageUrl } from '../../../../libs/domains/services/page-url';
import ReservationSlotUsecase from '../../../../libs/usecases/interfaces/reservation-slot.usecase.interface';
import { TYPES } from '../../../../types';
import moment from 'moment';
import WaitAMoment from '../../molecule/wait-a-moment';
import { Stack } from '@mui/material';
const { QUERY_KEYS } = PageUrl;

interface PropsType {
  location?: string;
}

const GuideSettingsReflection = ({ location }: PropsType) => {
  const container = useContainer();
  const [searchParams] = useSearchParams();
  const publicEntityCode = searchParams.get(QUERY_KEYS.PUBLIC_ENTITY_CODE) ?? '';

  const [maxday, setMaxday] = useState<string | null>(null);

  // 予約枠操作
  const reservationSlotUsecase = useMemo(
    () => container.get<ReservationSlotUsecase>(TYPES.ReservationSlot),
    [container]
  );

  // 予約枠の取得
  useEffect(() => {
    const run = async () => {
      await reservationSlotUsecase.fetchSlots(publicEntityCode, location).catch(() => {});
      const slots = reservationSlotUsecase.readSlots();
      if (slots.length <= 0) {
        return;
      }
      const day = moment(
        slots.map((slot) => slot.visitDateTime.getTime()).reduce((a, b) => Math.max(a, b))
      );
      setMaxday(day.add(1, 'day').format('YYYY年MM月DD日'));
    };
    run();
  }, [publicEntityCode, location, reservationSlotUsecase]);

  return (
    <Stack className="h-16" direction="row" alignItems="center" justifyContent="center" spacing={1}>
      <span>変更された設定情報は</span>
      <WaitAMoment isLoading={maxday == null}>
        <span>{maxday}</span>
      </WaitAMoment>
      <span>以降の予約設定からの反映となります。</span>
    </Stack>
  );
};

export default GuideSettingsReflection;
