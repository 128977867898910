import { injectable } from 'inversify';
import { HttpRequest } from '../../utils/http-request';
import SettingsCounterClosingDaysUsecase from '../../usecases/interfaces/settings-counter-closing-days.usecase.interface';
import CounterClosingDay from '../../domains/entitis/counter-closing-day';
import { ErrorHandler } from '../../domains/services/error-handler';

interface ResponseType {
  dow: string;
  isCounterClose: boolean;
  isBusinessDay: boolean;
}
@injectable()
export default class SettingsCounterClosingDaysRepository
  implements SettingsCounterClosingDaysUsecase
{
  private readonly _endpoint = 'counter-closed-week-day';

  private readonly _defaultData: CounterClosingDay[] = [
    CounterClosingDay.create({ dow: '日', isCounterClose: true, isBusinessDay: false }),
    CounterClosingDay.create({ dow: '月', isCounterClose: false, isBusinessDay: true }),
    CounterClosingDay.create({ dow: '火', isCounterClose: false, isBusinessDay: true }),
    CounterClosingDay.create({ dow: '水', isCounterClose: false, isBusinessDay: true }),
    CounterClosingDay.create({ dow: '木', isCounterClose: false, isBusinessDay: true }),
    CounterClosingDay.create({ dow: '金', isCounterClose: false, isBusinessDay: true }),
    CounterClosingDay.create({ dow: '土', isCounterClose: true, isBusinessDay: false }),
    CounterClosingDay.create({ dow: '祝', isCounterClose: true, isBusinessDay: false }),
  ];

  private _data: CounterClosingDay[] = [];

  async fetch(entityCode: string, location: string): Promise<void> {
    const requestUrl = HttpRequest.generateUrl(this._endpoint);
    HttpRequest.addUrlParamater(requestUrl, 'public-entity-code', entityCode);
    HttpRequest.addUrlParamater(requestUrl, 'location', location);
    const response = await HttpRequest.requestGet<ResponseType[]>(requestUrl);

    // 一旦初期化
    this._data = this._defaultData.map((data) => {
      return CounterClosingDay.copy(data);
    });

    if (
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    ) {
      return;
    }

    const items =
      response.data?.data == null
        ? this._data.map((item) =>
            CounterClosingDay.create({
              dow: item.dow,
              isCounterClose: item.isCounterClose,
              isBusinessDay: item.isBusinessDay,
            })
          )
        : response.data.data.map((item) => {
            return CounterClosingDay.create({
              dow: item.dow,
              isCounterClose: item.isCounterClose,
              isBusinessDay: item.isBusinessDay,
            });
          });
    for (let acceptableCount of items) {
      const index = this._data.findIndex((row) => row.dow === acceptableCount.dow);
      if (index < 0) {
        continue;
      }
      this._data[index].isCounterClose = acceptableCount.isCounterClose;
      this._data[index].isBusinessDay = acceptableCount.isBusinessDay;
    }

    return;
  }
  read(): CounterClosingDay[] {
    return this._data.map((item) => CounterClosingDay.copy(item));
  }

  async update(
    entityCode: string,
    location: string,
    weekDays: CounterClosingDay[]
  ): Promise<boolean> {
    const dows = weekDays.map((item) => {
      return {
        dow: item.dow,
        isCounterClose: item.isCounterClose,
        isBusinessDay: item.isBusinessDay,
      };
    });
    const requestUrl = HttpRequest.generateUrl(this._endpoint);
    const response = await HttpRequest.requestPut<null>(requestUrl, {
      publicEntityCode: entityCode,
      location,
      dows,
    });
    return response.status === 200 && response.data.code === 0;
  }
}
