/**
 * 窓口閉鎖曜日設定のプロパティ
 */
export interface CounterClosingDayProperties {
  dow: string;
  isCounterClose: boolean;
  isBusinessDay: boolean;
}

/**
 * 窓口閉鎖曜日設定情報
 */
export default class CounterClosingDay {
  private constructor(
    private readonly _dow: string, // 曜日
    private _isCounterClose: boolean, // 窓口閉鎖日
    private _isBusinessDay: boolean // 営業日
  ) {}

  /**
   * 曜日
   */
  get dow(): string {
    return this._dow;
  }
  /**
   * 窓口閉鎖日かどうか
   */
  get isCounterClose(): boolean {
    return this._isCounterClose;
  }
  set isCounterClose(isCounterClose: boolean) {
    this._isCounterClose = isCounterClose;
  }

  /**
   * 営業日かどうか
   */
  get isBusinessDay(): boolean {
    return this._isBusinessDay;
  }
  set isBusinessDay(isBusinessDay: boolean) {
    this._isBusinessDay = isBusinessDay;
  }

  /**
   * コピーの作成
   */
  static copy(previos: CounterClosingDay) {
    return this.create({
      dow: previos.dow,
      isCounterClose: previos.isCounterClose,
      isBusinessDay: previos.isBusinessDay,
    });
  }

  /**
   * 新規作成
   */
  static create(properties: CounterClosingDayProperties) {
    return new CounterClosingDay(
      properties.dow,
      properties.isCounterClose,
      properties.isBusinessDay
    );
  }
}
