import { Stack, Typography } from '@mui/material';
import React from 'react';

const NotFound404Page = () => {
  return (
    <Stack spacing={4}>
      <Typography variant="h1">404</Typography>
      <Stack>
        <div>Not Found.</div>
        <div>お探しのページは存在しないか見つかりません。</div>
      </Stack>
    </Stack>
  );
};

export default NotFound404Page;
