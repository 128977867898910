import { mkConfig, generateCsv, download } from 'export-to-csv';
import { InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField } from '@mui/material';
import { useContainer } from 'inversify-react';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import SettingsLocationUsecase from '../../../../libs/usecases/interfaces/settings-location.usecase.interface';
import { TYPES } from '../../../../types';
import { useSearchParams } from 'react-router-dom';
import { PageUrl } from '../../../../libs/domains/services/page-url';
import { useIsWaitingContext } from '../../../contexts/is-waiting-context';
import ExecuteButton from '../../molecule/execute-button';
import { buttonWidth, dateTimePickerWidth } from '../../../styles/size';
import { TimeControl } from '../../../../libs/utils/time-control';
import ReservationOutputUsecase from '../../../../libs/usecases/interfaces/reservation-output.usecase.interface';
import ReservationOutput from '../../../../libs/domains/value-objects/reservation-output';

const { QUERY_KEYS } = PageUrl;

const ReservationOutputPage = () => {
  const container = useContainer();
  const [searchParams] = useSearchParams();

  // 自治体コードを取得
  const publicEntityCode = searchParams.get(QUERY_KEYS.PUBLIC_ENTITY_CODE) ?? '';

  // 読み込み中
  const { setIsWaiting } = useIsWaitingContext();

  // 予約出力情報
  const reservationOutputUsecase = useMemo(
    () => container.get<ReservationOutputUsecase>(TYPES.ReservationOutput),
    [container]
  );

  // 来庁場所
  const settingsLocationUsecase = useMemo(
    () => container.get<SettingsLocationUsecase>(TYPES.SettingsLocation),
    [container]
  );
  const [locations, setLocations] = useState<string[]>([]);
  const [location, setLocation] = useState<string>('');

  // 対象年月
  const [yearMonth, setYearMonth] = useState<string>('');
  // 出力範囲（検索条件）
  const [dateFrom, setDateFrom] = useState<Date>(new Date());
  const [dateTo, setDateTo] = useState<Date>(new Date());

  // 来庁場所変更時の処理
  const handleLocationChange = (event: SelectChangeEvent) => {
    setLocation(event.target.value as string);
  };

  // 選択された年月が未来かどうか
  const isFutureMonth = (selectedMonth: string): boolean => {
    const today = new Date();
    const selectedDate = new Date(selectedMonth);

    return selectedDate > today;
  };

  // 年月選択時の処理
  const handleMonthChange = (event: ChangeEvent<HTMLInputElement>) => {
    setYearMonth(event.target.value);
    const selectedYearMonth = new Date(event.target.value.replace(/-/g, '/'));
    const today = new Date();

    // 開始日時と終了日時を設定
    const dateFrom = TimeControl.createDateTimeTimeZoneStartDate(
      new Date(selectedYearMonth.getFullYear(), selectedYearMonth.getMonth(), 1)
    );
    setDateFrom(dateFrom);

    if (
      today.getFullYear() === selectedYearMonth.getFullYear() &&
      today.getMonth() === selectedYearMonth.getMonth()
    ) {
      setDateTo(today);
    } else {
      const dateTo = TimeControl.createDateTimeTimeZoneEndDate(
        new Date(selectedYearMonth.getFullYear(), selectedYearMonth.getMonth() + 1, 0)
      );
      setDateTo(dateTo);
    }
  };

  // 出力処理
  const onOutput = () => {
    if (yearMonth === '') {
      alert('日付を選択してください。');
      return;
    }
    const dateRange = {
      dateFrom: dateFrom,
      dateTo: dateTo,
    };
    console.log(dateRange);
    setIsWaiting(true);
    reservationOutputUsecase
      .fetchList(publicEntityCode, location, dateRange)
      .then((reservationOutputList) => {
        if (reservationOutputList.length <= 0) {
          alert('指定の条件には予約がありません。');
          return;
        }
        const reservationCsvLists =
          ReservationOutput.createReservationOutputList(reservationOutputList);
        // csv出力
        const today = TimeControl.createDateText(new Date());
        const filename = `${publicEntityCode}_予約情報一覧(${yearMonth}_${location})_${today}`;
        const csvConfig = mkConfig({ useKeysAsHeaders: true, filename: filename });
        const csv = generateCsv(csvConfig)(reservationCsvLists);
        download(csvConfig)(csv);
      })
      .finally(() => {
        setIsWaiting(false);
      });
  };

  // 来庁場所一覧取得
  useEffect(() => {
    setIsWaiting(true);
    settingsLocationUsecase
      .fetch(publicEntityCode)
      .then(() => {
        const readlocations = settingsLocationUsecase.read();
        setLocations(readlocations);
        const defaultLocation = readlocations.length > 0 ? readlocations[0] : undefined;
        setLocation(defaultLocation ?? '');
      })
      .catch(() => [])
      .finally(() => setIsWaiting(false));
  }, [setIsWaiting, setLocations, setLocation, settingsLocationUsecase, publicEntityCode]);

  return (
    <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
      <Stack className="w-3/5 max-w-md">
        <InputLabel>来庁場所</InputLabel>
        <Select value={location ?? ''} onChange={handleLocationChange}>
          {locations.map((location, index) => (
            <MenuItem key={index} value={location}>
              {location}
            </MenuItem>
          ))}
        </Select>
      </Stack>
      <Stack className="w-3/5 max-w-md">
        <InputLabel>日付</InputLabel>
        <TextField
          id="date-from"
          type="month"
          onChange={handleMonthChange}
          defaultValue={yearMonth}
          sx={{ width: dateTimePickerWidth }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Stack>

      <ExecuteButton
        text="一覧を出力する"
        onClick={onOutput}
        isDisabled={isFutureMonth(yearMonth)}
        isPrimary={true}
        type="submit"
        width={buttonWidth}
      />
    </Stack>
  );
};

export default ReservationOutputPage;
