import React, { useEffect, useMemo, useState } from 'react';
import { InputLabel, Stack, TextField } from '@mui/material';
import ExecuteButton from '../../molecule/execute-button/ExecuteButton';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PageUrl } from '../../../../libs/domains/services/page-url';
import { useContainer } from 'inversify-react';
import PreApplicationUsecase from '../../../../libs/usecases/interfaces/pre-application.usecase.interface';
import { TYPES } from '../../../../types';
import { useReservationContext } from '../../../contexts/revervation-context';
import ReservationUsecase from '../../../../libs/usecases/interfaces/reservation.usecase.interface';
import { useIsWaitingContext } from '../../../contexts/is-waiting-context';
import Reservation from '../../../../libs/domains/entitis/reservation';
import ReservationUserUsecase from '../../../../libs/usecases/interfaces/reservation-user.usecase.interface';
import { usePreReservationContext } from '../../../contexts/pre-revervation-context';
import { useAgentIdContext } from '../../../contexts/agent-id-context';
import { useSelectedManagementPageViewContext } from '../../../contexts/selected-managemaent-page-view-context';
import User from '../../../../libs/domains/value-objects/user';
import NoticeServiceUsecase from '../../../../libs/usecases/interfaces/notice-service.usecase.interface';
import Notice, { NoticeType } from '../../../../libs/domains/entitis/notice';
import { buttonWidth } from '../../../styles/size';
import SessionControl from '../../organism/session-control';
import MailCaution from '../../organism/mail-caution/MailCaution';
import style from './ReservationCompletionPage.module.scss';
import { ToParent } from '../../../../libs/domains/services/to-parent';
import ClosePageDaialog from '../../organism/close-page-daialog';
import RemarkField from '../../molecule/remark-field';
import RemarkUsecase from '../../../../libs/usecases/interfaces/remark-usecase.interface';
const { QUERY_KEYS, ENDPOINT } = PageUrl;

type Status = 'ready' | 'adding' | 'success' | 'fail';

interface ReservationViewModel {
  reservationNumber: string;
  location: string;
  visitDateTime: string;
  procedureType: string;
  belongings: string;
}

const ReservationCompletionPage = () => {
  const container = useContainer();
  const [searchParams] = useSearchParams();
  const resevationTextField = 'bg-blue-200';
  const navigate = useNavigate();

  // 代理予約者
  const { agentId } = useAgentIdContext();

  // 自治体コードとユーザーIDを取得
  const publicEntityCode = searchParams.get(QUERY_KEYS.PUBLIC_ENTITY_CODE) ?? '';
  const userId = searchParams.get(QUERY_KEYS.USER_ID) ?? agentId ?? '';
  // 不正なリクエストパラメータ
  if (userId === '' || publicEntityCode === '') {
    navigate(ENDPOINT.NOT_FOUND, { replace: true });
  }

  // 読み込み中
  const { setIsWaiting } = useIsWaitingContext();

  // 管理画面の表示内容
  const { setSelectedView } = useSelectedManagementPageViewContext();

  // 予約登録状況
  const [status, setStatus] = useState<Status>('ready');

  // 予約登録状況
  const [isSendMail, setIsSendMail] = useState<boolean>(false);

  // 各ユースケースの取得
  const preApplicationUsecase = useMemo(
    () => container.get<PreApplicationUsecase>(TYPES.PreApplication),
    [container]
  );
  const reservationUsecase = useMemo(
    () => container.get<ReservationUsecase>(TYPES.Reservation),
    [container]
  );
  const reservationUserUsecase = useMemo(
    () => container.get<ReservationUserUsecase>(TYPES.ReservationUser),
    [container]
  );
  const noticeServiceUsecase = useMemo(
    () => container.get<NoticeServiceUsecase>(TYPES.Notice),
    [container]
  );
  const remarkUsecase = useMemo(
    () => container.get<RemarkUsecase>(TYPES.ReservationRemark),
    [container]
  );

  // 予約情報
  const { reservation } = useReservationContext();
  const { preReservation } = usePreReservationContext();

  // 画面表示用の情報
  const [viewReservation, setViewReservation] = useState<ReservationViewModel>({
    reservationNumber: '-',
    location: '-',
    visitDateTime: '-',
    procedureType: '-',
    belongings: '-',
  });

  // 「閉じる」警告を開くかどうか
  const [isCloseWarningOpen, setIsCloseWarningOpen] = useState<boolean>(false);

  // 次のページへ移動する
  const moveNextPage = () => {
    if (agentId == null) {
      ToParent.requestClosing();
      if (window.history.length > 1) {
        setIsCloseWarningOpen(true);
      } else {
        // 閉じる
        window.close();
      }
    } else {
      // 管理画面の予約検索へ遷移
      setSelectedView('reservation-serch-view');
    }
  };

  useEffect(() => {
    // 予約情報がないときは不正とみなす
    if (reservation == null) {
      navigate(ENDPOINT.NOT_FOUND, { replace: true });
    }

    setIsWaiting(true);
    setStatus('adding');
    const run = async () => {
      // 予約情報受け取り
      const location = reservation ? reservation.location : '-';
      const remark = reservation ? reservation.remark : '';
      const inputDateTime = reservation ? reservation.createAddDateTimeText() : '-';
      const visitDateTime = reservation ? reservation.createViewDateTimeText() : '-';

      try {
        // 事前情報取得
        const preApplication = await preApplicationUsecase.fetch(userId);
        if (preApplication == null) {
          setStatus('fail');
          return;
        }

        // ユーザー情報取得
        let user = await reservationUserUsecase.fetch(userId);
        if (user == null) {
          if (agentId == null) {
            setStatus('fail');
            return;
          }
          user = User.create({ userId: agentId, mailadress: '' });
        }

        // 予約を新規登録
        let reservationNumber: string | null = '';
        if (preReservation == null) {
          // 新規登録
          reservationNumber = await reservationUsecase.add(
            publicEntityCode,
            userId,
            location,
            remark,
            inputDateTime
          );
        } else {
          // 予約変更
          if (preReservation.createAddDateTimeText() === inputDateTime) {
            // 同じ時間なのでそのままにする
            reservationNumber = preReservation.reservationNumber;

            if (preReservation.remark !== remark) {
              // 備考が異なりため更新する
              if (
                !(await remarkUsecase.update(
                  publicEntityCode,
                  preReservation.reservationNumber ?? '',
                  remark
                ))
              ) {
                setStatus('fail');
                return;
              }
            }
          } else {
            // 追加する
            reservationNumber = await reservationUsecase.add(
              publicEntityCode,
              userId,
              location,
              remark,
              inputDateTime
            );
          }
        }
        if (reservationNumber == null || reservationNumber === '') {
          setStatus('fail');
          return;
        }

        // 予約済みとは予約された場合は、元々の予約をキャンセルする
        if (
          preReservation?.reservationNumber != null &&
          preReservation.reservationNumber !== reservationNumber
        ) {
          const result = await reservationUsecase.cancel(preReservation.reservationNumber);
          if (!result) {
            setStatus('fail');
            return;
          }
        }

        // 画面を更新
        const { procedureType, belongings } = preApplication ?? {
          procedureType: '',
          belongings: '',
        };
        setViewReservation({
          reservationNumber,
          location,
          visitDateTime,
          procedureType,
          belongings,
        });

        // 通知
        const noticeType: NoticeType = preReservation == null ? '登録' : '変更';
        await noticeServiceUsecase.add(
          publicEntityCode,
          noticeType,
          Notice.createContents(
            preApplication.userName,
            new Date(),
            location,
            reservation?.visitDateTime ?? new Date(),
            noticeType
          ),
          Notice.createDeleteDate(reservation?.visitDateTime ?? new Date()),
          reservationNumber
        );

        // 完了メール送信
        const comReservation = Reservation.create({
          location,
          visitDateTime: new Date(inputDateTime.replace(/-/g, '/')),
          reservationNumber,
          preApplication,
          user,
        });
        if (user.mailadress !== '') {
          const sendResult =
            preReservation == null
              ? await reservationUsecase.sendCompletedMail(publicEntityCode, comReservation)
              : await reservationUsecase.sendChangedMail(publicEntityCode, comReservation);
          if (sendResult) {
            setIsSendMail(true);
          }
        }

        // 予約登録成功
        setStatus('success');
      } finally {
        setIsWaiting(false);
      }
    };
    // 予約登録処理を実行
    run();
  }, [
    userId,
    reservation,
    reservationUsecase,
    preApplicationUsecase,
    publicEntityCode,
    reservationUserUsecase,
    noticeServiceUsecase,
    remarkUsecase,
    preReservation,
    setIsWaiting,
    navigate,
    agentId,
  ]);

  const creatreInformation = () => {
    switch (status) {
      case 'adding':
        return <div>予約確定中です。しばらくお待ちください。</div>;
      case 'success':
        return (
          <>
            <div>
              以下の通り、{agentId != null && '代理'}予約{preReservation != null && 'の変更'}
              を受け付けました。
            </div>
            {isSendMail ? (
              <>
                <div>認証されたメールアドレスにも送付されておりますので、ご確認ください。</div>
                <div>
                  予約日時の変更キャンセルは、送付メールのリンク先より行う必要がございますので
                  <br className="hidden sm:block" />
                  メールのほうは大事に保管下さるようお願いいたします。
                </div>
                <div className="mt-5">
                  なお、来庁の際には、番号発券機の番号を取らずに直接、市民課職員にお声かけください。
                </div>
              </>
            ) : (
              <>
                {/* 代理予約時の文言 */}
                {agentId != null && (
                  <div>来庁時に必要な持ち物は、別途予約された方へお伝えください。</div>
                )}

                {/* 何等かの要因でメール送信失敗 */}
                {agentId == null && (
                  <>
                    <div>メール送信に失敗しました。</div>
                    <div>お手数ですが、ご予約変更の差異は別途ご連絡お願いします。</div>
                  </>
                )}
              </>
            )}
          </>
        );
      case 'fail':
        return (
          <>
            <div>予約に失敗しました。</div>
            <div>お手数ですが、はじめから予約しなおしてください。</div>
          </>
        );
      default:
        return (
          <>
            <div>待機中です。</div>
            <div>しばらく待って切り替わらない場合は、予約しなおしてください。</div>
          </>
        );
    }
  };

  return (
    <>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={4}
        component="form"
        noValidate
      >
        <Stack justifyContent="center" alignItems="center">
          {creatreInformation()}
        </Stack>

        {status === 'success' && (
          <>
            <Stack className="w-4/5 max-w-md" spacing={1}>
              <InputLabel>予約番号</InputLabel>
              <TextField
                className={resevationTextField}
                fullWidth
                value={viewReservation.reservationNumber}
                InputProps={{
                  readOnly: true,
                }}
              />
              <InputLabel>来庁場所</InputLabel>
              <TextField
                className={resevationTextField}
                fullWidth
                value={viewReservation.location}
                InputProps={{
                  readOnly: true,
                }}
              />

              <InputLabel>予約日時</InputLabel>
              <TextField
                className={resevationTextField}
                fullWidth
                value={viewReservation.visitDateTime}
                InputProps={{
                  readOnly: true,
                }}
              />
              {agentId == null && (
                <>
                  <InputLabel>持ち物</InputLabel>
                  <Stack
                    className="border p-1 w-full max-w-md flex-wrap"
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    {viewReservation.belongings.split(',').map((item, index) => (
                      <div key={index} className="bg-slate-50 p-2 m-1">
                        {item}
                      </div>
                    ))}
                  </Stack>
                </>
              )}

              {/* 備考 */}
              {agentId != null && <RemarkField text={reservation?.remark ?? ''} />}
            </Stack>
            {isSendMail && (
              <div className={style[`mail-caution`]}>
                <MailCaution publicEntityCode={publicEntityCode} />
              </div>
            )}
          </>
        )}
        {(status === 'success' || status === 'fail') && (
          <ExecuteButton
            text={agentId == null ? '閉じる' : '予約検索'}
            onClick={() => moveNextPage()}
            isPrimary={true}
            type="button"
            width={buttonWidth}
          />
        )}
      </Stack>
      {/* セッション管理 */}
      {agentId == null && <SessionControl />}
      <ClosePageDaialog isOpen={isCloseWarningOpen} setIsOpen={setIsCloseWarningOpen} />
    </>
  );
};

export default ReservationCompletionPage;
