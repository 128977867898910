import { injectable } from 'inversify';
import AuthenticationUsecase from '../../usecases/interfaces/authentication.usecase.interface';
import { HttpRequest } from '../../utils/http-request';
import { ErrorHandler } from '../../domains/services/error-handler';

@injectable()
export default class AuthenticationController implements AuthenticationUsecase {
  async send(userId: string): Promise<string | null> {
    const response = await HttpRequest.requestPost<string>(
      HttpRequest.generateUrl('authentication'),
      {
        userId,
      }
    );

    if (
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    ) {
      return null;
    }
    return response.data.data ?? null;
  }
  async verify(userId: string, code: string, session: string): Promise<boolean> {
    const response = await HttpRequest.requestPost<ResponseType>(
      HttpRequest.generateUrl('verification'),
      {
        userId,
        code,
        session,
      }
    );
    return !(
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    );
  }
}
