import { injectable } from 'inversify';
import SettingsReservationableTimeUsecase from '../../usecases/interfaces/settings-reservationable-time.usecase.interface';
import { ReservationableTime } from '../../domains/entitis/rereservationable-time';
import { HttpRequest } from '../../utils/http-request';
import { ErrorHandler } from '../../domains/services/error-handler';

interface ResponseType {
  start_date: number;
  end_date: number;
}

@injectable()
export default class SettingsRereservationableTimeRepository
  implements SettingsReservationableTimeUsecase
{
  private readonly _reservationableTime: ReservationableTime = { startDate: 0, endDate: 0 };

  private readonly _endpoint = 'reservable-time';

  async fetch(entityCode: string): Promise<void> {
    const requestUrl = HttpRequest.generateUrl(this._endpoint);
    HttpRequest.addUrlParamater(requestUrl, 'public-entity-code', entityCode);
    const response = await HttpRequest.requestGet<ResponseType>(requestUrl);
    if (
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    ) {
      return;
    }
    this._reservationableTime.startDate = response.data.data?.start_date ?? 0;
    this._reservationableTime.endDate = response.data.data?.end_date ?? 0;
  }

  read(): ReservationableTime {
    return { ...this._reservationableTime };
  }

  get startDate(): number {
    return this._reservationableTime?.startDate ?? 0;
  }

  async update(entityCode: string, startDate: number, endDate: number): Promise<boolean> {
    const requestUrl = HttpRequest.generateUrl(this._endpoint);
    const response = await HttpRequest.requestPut<null>(requestUrl, {
      publicEntityCode: entityCode,
      startDate,
      endDate,
    });
    return !(
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    );
  }
}
