import moment from 'moment';
moment.locale('ja', {
  weekdays: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
  weekdaysShort: ['日', '月', '火', '水', '木', '金', '土'],
});

export type NoticeType = '登録' | '削除' | '変更';

/**
 * 通知のプロパティ
 */
export interface NoticeProperties {
  id: number; // 通知ID
  type: NoticeType; // 通知種類
  contents: string; // 通知内容
  deleteDate: Date; // 削除日時
  isRead: boolean; // 既読
  reservationNumber: string; // 予約番号
  isDone: boolean; // 完了しているかどうか
}

/**
 * 通知情報
 */
export default class Notice {
  private constructor(
    private readonly _id: number, // 通知ID
    private readonly _type: NoticeType, // 通知種類
    private readonly _contents: string, // 通知内容
    private readonly _deleteDate: Date, // 削除予定日時
    private _isRead: boolean, // 既読
    private readonly _reservationNumber: string, // 予約番号
    private _isDone: boolean // 完了しているかどうか
  ) {}

  /**
   * 同一オブジェクトとの比較
   * @param other 比較対象
   * @returns 一致:true、不一致:false
   */
  equals(other?: Notice | null): boolean {
    if (other == null) {
      return false;
    }
    return (
      this._contents === other.contents &&
      this._type === other.type &&
      this._deleteDate.getTime() === other.deleteDate.getDate() &&
      this._isRead === other.isRead &&
      this._isDone === other.isDone
    );
  }

  /**
   * 通知ID
   */
  get id(): number {
    return this._id;
  }

  /**
   * 通知種類
   */
  get type(): NoticeType {
    return this._type;
  }

  /**
   * 通知種類
   */
  get deleteDate(): Date {
    return this._deleteDate;
  }

  /**
   * 既読
   */
  get isRead(): boolean {
    return this._isRead;
  }
  set isRead(isRead: boolean) {
    this._isRead = isRead;
  }

  /**
   * 通知内容
   */
  get contents(): string {
    return this._contents;
  }

  /**
   * 予約番号
   */
  get reservationNumber(): string {
    return this._reservationNumber;
  }

  /**
   * 完了しているかどうか
   */
  get isDone(): boolean {
    return this._isDone;
  }
  set isDone(isDone: boolean) {
    this._isDone = isDone;
  }

  /**
   * 削除予定日を設定する
   * @param date 予約日
   * @returns 削除予定日
   */
  static createDeleteDate(date: Date): string {
    return `${moment(date.setDate(date.getDate() + 7)).format('yyyy-MM-DD')}`;
  }

  /**
   * 通知本文を作成する
   * @param name 利用者名
   * @param createDateTime 作成した日時
   * @param location 来庁場所
   * @param targetDate 予約対象日
   * @param type 通知種別
   * @returns
   */
  static createContents(
    name: string,
    createDateTime: Date,
    location: string,
    date: Date,
    type: NoticeType
  ): string {
    const dateTimeText = moment(createDateTime.getTime()).format(
      'YYYY年MM月DD日(ddd) HH時mm分ss秒'
    );
    const targetdateText = moment(date.getTime()).format('YYYY年MM月DD日(ddd) HH時mm分');
    return `[${dateTimeText}] - ${name}さんが${location}の ${targetdateText} ${
      type === '変更' ? 'に' : 'の'
    }予約を${type}しました。`;
  }

  /**
   * 新規作成
   */
  static create(properties: NoticeProperties) {
    return new Notice(
      properties.id,
      properties.type,
      properties.contents,
      new Date(properties.deleteDate),
      properties.isRead,
      properties.reservationNumber,
      properties.isDone
    );
  }

  /**
   * コピーを作成
   */
  static copy(previos: Notice) {
    return new Notice(
      previos.id,
      previos.type,
      previos.contents,
      new Date(previos.deleteDate),
      previos.isRead,
      previos.reservationNumber,
      previos.isDone
    );
  }
}
