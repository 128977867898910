import moment from 'moment';

/**
 * 窓口閉鎖日設定のプロパティ
 */
export interface CounterClosingUniqueDayProperties {
  date: Date;
  isBusinessDay: boolean;
}

/**
 * 窓口閉鎖曜日設定情報
 */
export default class CounterUniqueClosingDay {
  private constructor(
    private readonly _date: Date, // 窓口閉鎖日
    private _isBusinessDay: boolean // 営業日
  ) {}

  /**
   * 窓口閉鎖日
   */
  get date(): Date {
    return this._date;
  }
  /**
   * 窓口閉鎖日を文字列で取得する
   */
  createDateText(): string {
    return `${moment(this._date.getTime()).format('yyyy-MM-DD')}`;
  }
  /**
   * 営業日かどうか
   */
  get isBusinessDay(): boolean {
    return this._isBusinessDay;
  }
  set isBusinessDay(isBusinessDay: boolean) {
    this._isBusinessDay = isBusinessDay;
  }

  /**
   * コピーの作成
   */
  static copy(previos: CounterUniqueClosingDay) {
    return this.create({
      date: previos.date,
      isBusinessDay: previos.isBusinessDay,
    });
  }

  /**
   * 新規作成
   */
  static create(properties: CounterClosingUniqueDayProperties) {
    return new CounterUniqueClosingDay(properties.date, properties.isBusinessDay);
  }
}
