import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import ManagementHedder from '../../templates/management-hedder';
import { useLoginStaffContext } from '../../../contexts/login-staff-context';
import { PageUrl } from '../../../../libs/domains/services/page-url';
import NoticeServiceUsecase from '../../../../libs/usecases/interfaces/notice-service.usecase.interface';
import { TYPES } from '../../../../types';
import { useContainer } from 'inversify-react';
import { ViewName, switchingPage } from '../../../utils/switching-managemant-page-view';
import MenuBotton from '../../molecule/menu-botton';
import { useSelectedManagementPageViewContext } from '../../../contexts/selected-managemaent-page-view-context';
import { usePreReservationContext } from '../../../contexts/pre-revervation-context';
import { useReservationContext } from '../../../contexts/revervation-context';
import { useIsChangeReservationContext } from '../../../contexts/is-change-reservation-context';
import { useIsUpdateLocationSettingsContext } from '../../../contexts/is-update-location-settings-context';
import style from './ManagementPage.module.scss';
import { useReservationSearchConditionContext } from '../../../contexts/revervation-search-condition-context';
import LoginUsecase from '../../../../libs/usecases/interfaces/login.usecase.interface';
import { useIsWaitingContext } from '../../../contexts/is-waiting-context';
import { needStaffTokenUpdate } from '../../../../libs/domains/services/login-staff';

const { ENDPOINT, QUERY_KEYS } = PageUrl;

const ManagementPage = () => {
  const container = useContainer();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // ログイン中ユーザー
  const { loginStaff, setLoginStaff } = useLoginStaffContext();

  // 表示中の画面
  const { selectedView, setSelectedView } = useSelectedManagementPageViewContext();
  const isSelectedMenu = (...selected: ViewName[]) => {
    if (selectedView == null) {
      return false;
    }
    return selected.includes(selectedView);
  };

  // 予約情報
  const { setReservation } = useReservationContext();
  const { setPreReservation } = usePreReservationContext();

  // 再予約かどうか
  const { setIsChangeReservation } = useIsChangeReservationContext();

  // 場所別設定の変更の有無
  const { setIsUpdateLocationSettings, needDiscardLocationSettins } =
    useIsUpdateLocationSettingsContext();

  // 検索条件の画面間連携
  const { setReservationSearchCondition } = useReservationSearchConditionContext();

  // 読み込み中
  const { setIsWaiting } = useIsWaitingContext();

  // リクエストパラメータから自治体コードを取得
  const publicEntityCode = searchParams.get(QUERY_KEYS.PUBLIC_ENTITY_CODE) ?? '';

  // 通知
  const noticeServiceUsecase = useMemo(
    () => container.get<NoticeServiceUsecase>(TYPES.Notice),
    [container]
  );

  // ログイン
  const loginUsecase = useMemo(() => container.get<LoginUsecase>(TYPES.Login), [container]);

  // ページ読み込み
  const loadPage = (before: ViewName, after: ViewName): ViewName => {
    if (before !== after) {
      return after;
    }
    setTimeout(() => setSelectedView(after), 10);
    return 'loading-view';
  };

  const logout = async () => {
    await systemlogout();
    viewlogout();
  };

  const systemlogout = async () => {
    // システムからのログアウト
    setIsWaiting(true);
    if (!(await loginUsecase.revoke(loginStaff?.refreshToken ?? ''))) {
      alert('ログアウト処理に失敗しました。ログイン画面に戻ります。');
    }
    setIsWaiting(false);
  };

  const viewlogout = useCallback(() => {
    // 画面上のログアウト
    setLoginStaff(null);
    navigate(
      PageUrl.generate(ENDPOINT.LOGIN, {
        key: 'public-entity-code',
        value: publicEntityCode,
      }),
      { replace: true }
    );
  }, [navigate, publicEntityCode, setLoginStaff]);

  // 代理予約のページ郡
  const agentPages: ViewName[] = [
    'create-reservation-agent-view',
    'reservation-view',
    'reservation-edit-view',
    'reservation-entry-confirmation-view',
    'reservation-completion-view',
    'change-reservation-view',
    'reservation-cancel-confirm-view',
  ];

  // 管理画面の初期化処理
  useEffect(() => {
    // 不正な画面遷時はページを離脱する
    if (loginStaff == null || loginStaff?.loginId == null) {
      navigate(
        PageUrl.generate(ENDPOINT.LOGIN, { key: 'public-entity-code', value: publicEntityCode }),
        { replace: true }
      );
      return;
    }

    // 初期表示画面を設定
    setSelectedView(null);

    // 通知配信の開始
    noticeServiceUsecase.start(loginStaff.userName, publicEntityCode);

    // 場所別設定を未更新とする
    setIsUpdateLocationSettings(false);

    return () => {
      // 通知配信の終了
      noticeServiceUsecase.stop();
    };
    // TODO: 1回だけ実行したいので、依存性リストの警告は無視するようにした
  }, []); // eslint-disable-line

  // ログイン中職員の定期監視
  useEffect(() => {
    // 周期開始
    const cycleid = window.setInterval(async () => {
      if (!needStaffTokenUpdate(loginStaff?.updatetime ?? new Date(0))) {
        // 更新不要
        return;
      }
      const refreshStaff = await loginUsecase.refresh(loginStaff);
      if (refreshStaff == null) {
        // 更新失敗
        window.clearInterval(cycleid);
        alert('再ログインしてください。');
        viewlogout();
        return;
      }

      // 差し替え
      setLoginStaff(refreshStaff);
    }, 1800000);

    return () => {
      window.clearInterval(cycleid);
    };
    // TODO: 1回だけ実行したいので、依存性リストの警告は無視するようにした
  }, []); // eslint-disable-line

  return (
    <Stack className="h-screen w-screen max-h-screen">
      <Stack
        className="p-4 grow-0 border-b-solid border-b-gray-700 border-b sticky top-0 h-20 bg-white"
        alignItems="stretch"
        justifyContent="center"
      >
        <ManagementHedder />
      </Stack>
      <Stack className={`${style.base} grow`} direction="row">
        <Stack
          alignItems="flex-start"
          justifyContent="space-between"
          className="bg-gray-700 text-gray-50 px-4 py-8 grow-0 min-w-fit min-h-fit overflow-y-auto"
          spacing={2}
        >
          <Stack alignItems="flex-start" justifyContent="flex-start" spacing={2}>
            <MenuBotton
              itemName="ダッシュボード"
              onClick={() => {
                if (!needDiscardLocationSettins()) {
                  return;
                }
                setSelectedView((prev) => loadPage(prev, 'dashboard-view'));
              }}
              isSelected={isSelectedMenu('dashboard-view')}
            />
            <MenuBotton
              itemName="予約検索"
              onClick={() => {
                if (!needDiscardLocationSettins()) {
                  return;
                }
                setReservation(null);
                setPreReservation(null);
                setIsChangeReservation(false);
                setReservationSearchCondition(null);
                setSelectedView((prev) => loadPage(prev, 'reservation-serch-view'));
              }}
              isSelected={isSelectedMenu('reservation-serch-view')}
            />

            {loginStaff?.permission === 'administrator' && (
              <>
                <MenuBotton
                  itemName="代理予約"
                  onClick={() => {
                    if (!needDiscardLocationSettins()) {
                      return;
                    }
                    setReservation(null);
                    setPreReservation(null);
                    setIsChangeReservation(false);
                    setReservationSearchCondition(null);
                    setSelectedView((prev) => loadPage(prev, 'create-reservation-agent-view'));
                  }}
                  isSelected={isSelectedMenu(...agentPages)}
                />
                <MenuBotton
                  itemName="出力"
                  onClick={() => {
                    if (!needDiscardLocationSettins()) {
                      return;
                    }
                    setSelectedView((prev) => loadPage(prev, 'reservation-output-view'));
                  }}
                  isSelected={isSelectedMenu('reservation-output-view')}
                />
                <MenuBotton
                  itemName="基本設定"
                  onClick={() => {
                    if (!needDiscardLocationSettins()) {
                      return;
                    }
                    setSelectedView((prev) => loadPage(prev, 'settings-view'));
                  }}
                  isSelected={isSelectedMenu('settings-view')}
                />
                <MenuBotton
                  itemName="場所別設定"
                  onClick={() => {
                    if (!needDiscardLocationSettins()) {
                      return;
                    }
                    setSelectedView((prev) => loadPage(prev, 'location-settings-view'));
                  }}
                  isSelected={isSelectedMenu('location-settings-view')}
                />
                <MenuBotton
                  itemName="職員管理"
                  onClick={() => {
                    if (!needDiscardLocationSettins()) {
                      return;
                    }
                    setSelectedView((prev) => loadPage(prev, 'staff-management-view'));
                  }}
                  isSelected={isSelectedMenu('staff-management-view')}
                />
              </>
            )}
          </Stack>
          <MenuBotton itemName="ログアウト" onClick={() => logout()} />
        </Stack>
        <Stack className="p-2 grow overflow-y-auto">{switchingPage(selectedView)}</Stack>
      </Stack>
    </Stack>
  );
};

export default ManagementPage;
