import { createContext, ReactNode, useContext, useState, Dispatch, SetStateAction } from 'react';

interface IsUpdateLocationSettingsContextType {
  isUpdateLocationSettings: boolean;
  setIsUpdateLocationSettings: Dispatch<SetStateAction<boolean>>;
  needDiscardLocationSettins: () => boolean;
}

/**
 * 作成したコンテキストは一意の名称とする
 */
const IsUpdateLocationSettingsContext = createContext<IsUpdateLocationSettingsContextType>(
  {} as IsUpdateLocationSettingsContextType
);

/**
 * 外部から設定値を取り出す用
 */
export const useIsUpdateLocationSettingsContext = () => useContext(IsUpdateLocationSettingsContext);

/**
 * コンテキストを提供する
 */
export const IsUpdateLocationSettingsContextProvider = ({ children }: { children: ReactNode }) => {
  const [isUpdateLocationSettings, setIsUpdateLocationSettings] = useState<boolean>(false);
  const { Provider } = IsUpdateLocationSettingsContext;

  const needDiscardLocationSettins = () => {
    if (!isUpdateLocationSettings) {
      return true;
    }
    const isDiscard = window.confirm('変更があります。破棄しますか？');
    if (isDiscard) {
      setIsUpdateLocationSettings(false);
    }
    return isDiscard;
  };

  const value = {
    isUpdateLocationSettings,
    setIsUpdateLocationSettings,
    needDiscardLocationSettins,
  };

  return <Provider value={value}>{children}</Provider>;
};
