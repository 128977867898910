import { createContext, ReactNode, useContext, useState, Dispatch, SetStateAction } from 'react';
import { Staff } from '../../libs/domains/entitis/staff';

// nullはログインしていない状態を表す
type PreStaffInformationContextType = {
  preStaff: Staff | null;
  setPreStaff: Dispatch<SetStateAction<Staff | null>>;
};

/**
 * 作成したコンテキストは一意の名称とする
 */
const preStaffInformationContext = createContext<PreStaffInformationContextType>(
  {} as PreStaffInformationContextType
);

/**
 * 外部から設定値を取り出す用
 */
export const usePreStaffInformationContext = () => useContext(preStaffInformationContext);

/**
 * コンテキストを提供する
 */
export const PreStaffInformationProvider = ({ children }: { children: ReactNode }) => {
  const [preStaff, setPreStaff] = useState<Staff | null>(null);
  const { Provider } = preStaffInformationContext;

  const value = {
    preStaff,
    setPreStaff,
  };
  return <Provider value={value}>{children}</Provider>;
};
