import axios, { AxiosResponse } from 'axios';
import { HttpResponse } from './models/response-data';

/**
 * HTTPリクエスト処理
 * ※Axiosに依存
 */
export namespace HttpRequest {
  const TIMEOUT = 60000;

  // リクエスト先URLを環境変数から取得
  const BASE_URL = `${process.env.REACT_APP_API_URL ?? window.location.origin}`;
  const STAGE = `${process.env.REACT_APP_STAGE}`;

  /**
   * GETリクエストを行い、サーバからのレスポンスを返す
   * @param url リクエストURL
   * @returns サーバからのレスポンス
   */
  export const requestGet = async <T>(url: URL): Promise<HttpResponse<T>> => {
    const requestUrl = url.href;
    return await axios
      .get<T>(requestUrl, { timeout: TIMEOUT })
      .then((response) => {
        return successResponse(response);
      })
      .catch((e) => {
        return errorResponse<T>(e);
      });
  };

  /**
   * POSTリクエストを行い、サーバからのレスポンスを返す
   * @param url リクエストURL
   * @param contents リクエスト内容
   * @returns サーバからのレスポンス
   */
  export const requestPost = async <T>(url: URL, contents: any): Promise<HttpResponse<T>> => {
    const requestUrl = url.href;
    return await axios
      .post<T>(requestUrl, contents, { timeout: TIMEOUT })
      .then((response) => {
        return successResponse(response);
      })
      .catch((e) => {
        return errorResponse<T>(e);
      });
  };

  /**
   * DELETEリクエストを行い、サーバからのレスポンスを返す
   * @param url リクエストURL
   * @returns サーバからのレスポンス
   */
  export const requestDelete = async <T>(url: URL): Promise<HttpResponse<any>> => {
    const requestUrl = url.href;
    return await axios
      .delete<T>(requestUrl, { timeout: TIMEOUT })
      .then((response) => {
        return successResponse(response);
      })
      .catch((e) => {
        return errorResponse<T>(e);
      });
  };

  /**
   * PUTリクエストを行い、サーバからのレスポンスを返す
   * @param url リクエストURL
   * @param contents リクエスト内容
   * @returns サーバからのレスポンス
   */
  export const requestPut = async <T>(url: URL, contents: any): Promise<HttpResponse<any>> => {
    const requestUrl = url.href;
    return await axios
      .put<T>(requestUrl, contents, { timeout: TIMEOUT })
      .then((response) => {
        return successResponse(response);
      })
      .catch((e) => {
        return errorResponse<T>(e);
      });
  };

  /**
   * 成功時のレスポンスをAxiosに依存しない型に変換
   * @param response 成功時のレスポンス
   * @returns 変換後のレスポンス
   */
  const successResponse = <T>(response: AxiosResponse<T>): HttpResponse<T> => {
    const { data, status } = response;
    return { data, status } as HttpResponse<T>;
  };

  /**
   * エラー時のレスポンスを返す
   * @param e エラー
   * @returns エラー時のレスポンス
   */
  const errorResponse = <T>(e: any): HttpResponse<T> => {
    const errorResponse = {
      status: 500,
      data: { code: -1 },
    };
    if (e.code == null) {
      return errorResponse;
    }
    if (e.code === 'ECONNABORTED') {
      return errorResponse;
    }
    if (e.response == null) {
      return errorResponse;
    }
    const { data, status } = e.response;
    return { data, status };
  };

  /**
   * 固有のエンドポイントに応じてURLクラスのインスタンスを作成する
   * @param endpointUrl 固有のエンドポイント
   * @returns URLクラスのインスタンス
   */
  export const generateUrl = (endpointUrl: string): URL => {
    return new URL(`${STAGE}${endpointUrl}`, `${BASE_URL}`);
  };

  /**
   * 指定したリクエストパラメータをURLへ設定する
   * @param endpointUrl コール先のURL
   * @param name パラメータ名
   * @param target
   * @returns
   */
  export const addUrlParamater = <T>(endpointUrl: URL, name: string, target?: T) => {
    if (target == null) {
      return;
    }
    endpointUrl.searchParams.set(name, `${target}`);
  };
}
