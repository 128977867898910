import { injectable } from 'inversify';
import MailSettingUsecase from '../../usecases/interfaces/mail-setting.usecase.interface';
import MailSetting from '../../domains/entitis/mail-setting';
import { HttpRequest } from '../../utils/http-request';
import { ErrorHandler } from '../../domains/services/error-handler';
import { SendMail } from '../../domains/services/send-mail';

@injectable()
export default class MailSettingRepository implements MailSettingUsecase {
  private _mailSetting: MailSetting | null = null;
  private readonly _endpoint = 'mail-setting';

  async fetch(publicEntityCode: string): Promise<void> {
    const requestUrl = HttpRequest.generateUrl(this._endpoint);
    HttpRequest.addUrlParamater(requestUrl, 'public-entity-code', publicEntityCode);
    const response = await HttpRequest.requestGet<MailSetting>(requestUrl);

    if (
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    ) {
      return;
    }
    this._mailSetting = response.data.data ?? this._mailSetting;
    return;
  }
  read(): MailSetting | null {
    if (this._mailSetting == null) {
      return null;
    }
    return MailSetting.copy(this._mailSetting);
  }

  async generateCompletedMail(
    publicEntityCode: string,
    to: string,
    date: string,
    number: string,
    location: string,
    belongings: string,
    changeUrl: string
  ): Promise<SendMail.Content> {
    if (this._mailSetting == null) {
      await this.fetch(publicEntityCode);
    }
    const message = SendMail.generateReservedMessage(
      date,
      number,
      location,
      belongings,
      changeUrl,
      this._mailSetting?.reservationCompletionMessage ?? ''
    );
    return SendMail.generateContent(
      SendMail.SEND_MAIL_TYPE.COMPLETE,
      this._mailSetting?.emailSender ?? '',
      to,
      this._mailSetting?.reservationCompletionSubject ?? '',
      message,
      this._mailSetting?.contactInformation ?? ''
    );
  }
  async generateChangedMail(
    publicEntityCode: string,
    to: string,
    date: string,
    number: string,
    location: string,
    belongings: string,
    changeUrl: string
  ): Promise<SendMail.Content> {
    if (this._mailSetting == null) {
      await this.fetch(publicEntityCode);
    }
    const message = SendMail.generateReservedMessage(
      date,
      number,
      location,
      belongings,
      changeUrl,
      this._mailSetting?.reservationChangeMessage ?? ''
    );
    return SendMail.generateContent(
      SendMail.SEND_MAIL_TYPE.CHANGE,
      this._mailSetting?.emailSender ?? '',
      to,
      this._mailSetting?.reservationChangeSubject ?? '',
      message,
      this._mailSetting?.contactInformation ?? ''
    );
  }
  async generateCanceledMail(publicEntityCode: string, to: string): Promise<SendMail.Content> {
    if (this._mailSetting == null) {
      await this.fetch(publicEntityCode);
    }
    const message = this._mailSetting?.reservationCancelMessage ?? '';
    return SendMail.generateContent(
      SendMail.SEND_MAIL_TYPE.CANCEL,
      this._mailSetting?.emailSender ?? '',
      to,
      this._mailSetting?.reservationCancelSubject ?? '',
      message,
      this._mailSetting?.contactInformation ?? ''
    );
  }
}
