import { Stack } from '@mui/material';

const ProcedureCaution = () => {
  return (
    <Stack direction="column" justifyContent="flex-start" alignItems="flex-start">
      <div className={'mx-4'}>
        <div>・一度で手続きが終わらない場合があります。</div>
        <div>・おくやみ窓口では手続きにおひとり様90分以上かかる場合があります。</div>
        <div>・ご予約後、手続き担当課からご連絡をする場合があります。</div>
      </div>
    </Stack>
  );
};

export default ProcedureCaution;
