import ChangeReservationPage from '../components/pages/change-reservation-page';
import CreateReservationAgentPage from '../components/pages/create-reservation-agent-page';
import DashboardPage from '../components/pages/dashboard-page';
import LoadingPage from '../components/pages/loading-page';
import LocationSettingsPage from '../components/pages/location-settings-page';
import ReservationCancelConfirmPage from '../components/pages/reservation-cancel-confirm-page';
import ReservationCompletionPage from '../components/pages/reservation-completion-page/ReservationCompletionPage';
import ReservationEditPage from '../components/pages/reservation-edit-page';
import ReservationEntryConfirmationPage from '../components/pages/reservation-entry-confirmation-page';
import ReservationNotFoundPage from '../components/pages/reservation-not-found-page';
import ReservationOutputPage from '../components/pages/reservation-output-page';
import ReservationPage from '../components/pages/reservation-page';
import ReservationSearchPage from '../components/pages/reservation-search-page';
import SettingsPage from '../components/pages/settings-page';
import StaffManagementPage from '../components/pages/staff-management-page';
import { addOutsideFrame } from './add-frame';

export type ViewName =
  | 'dashboard-view'
  | 'settings-view'
  | 'location-settings-view'
  | 'staff-management-view'
  | 'reservation-serch-view'
  | 'reservation-detail-view'
  | 'create-reservation-agent-view'
  | 'reservation-view'
  | 'reservation-edit-view'
  | 'reservation-entry-confirmation-view'
  | 'reservation-completion-view'
  | 'change-reservation-view'
  | 'reservation-cancel-confirm-view'
  | 'reservation-output-view'
  | 'loading-view'
  | 'reservation-not-found-view'
  | null;

export const switchingPage = (selectedPage: ViewName) => {
  switch (selectedPage) {
    case 'reservation-serch-view':
      return <ReservationSearchPage />;
    case 'location-settings-view':
      return <LocationSettingsPage />;
    case 'staff-management-view':
      return <StaffManagementPage />;
    case 'settings-view':
      return addOutsideFrame(<SettingsPage />);
    case 'create-reservation-agent-view':
      return <CreateReservationAgentPage />;
    case 'reservation-view':
      return <ReservationPage />;
    case 'reservation-edit-view':
      return <ReservationEditPage />;
    case 'reservation-entry-confirmation-view':
      return <ReservationEntryConfirmationPage />;
    case 'reservation-completion-view':
      return <ReservationCompletionPage />;
    case 'change-reservation-view':
      return <ChangeReservationPage />;
    case 'reservation-cancel-confirm-view':
      return <ReservationCancelConfirmPage />;
    case 'reservation-output-view':
      return <ReservationOutputPage />;
    case 'loading-view':
      return <LoadingPage />;
    case 'reservation-not-found-view':
      return <ReservationNotFoundPage />;
    default:
      return <DashboardPage />;
  }
};
