import moment from 'moment';

/**
 * 時間に関する機能
 */
export namespace TimeControl {
  /**
   * 指定した時間、停止する
   * @param milliseconds 待つ時間（ミリ秒）
   */
  export const sleep = (milliseconds: number) =>
    new Promise((resolve) => setTimeout(resolve, milliseconds));

  /**
   * 日を文字列で取得する
   * @param dateTime 変換したい日時
   */
  export const createDateText = (dateTime: Date): string => {
    return `${moment(dateTime.getTime()).format('yyyy-MM-DD')}`;
  };

  /**
   * 年月日を文字列で取得する(日本語)
   * @param dateTime 変換したい日時
   */
  export const createDateTextJp = (dateTime: Date): string => {
    return `${moment(dateTime.getTime()).format('yyyy年MM月DD日')}`;
  };

  /**
   * 日を文字列で取得する(/)
   * @param dateTime 変換したい日時
   */
  export const createDateTextSlash = (dateTime: Date): string => {
    return `${moment(dateTime.getTime()).format('yyyy/MM/DD')}`;
  };

  /**
   * 時間を文字列で取得する
   * @param dateTime 変換したい日時
   */
  export const createTimeText = (dateTime: Date): string => {
    return `${moment(dateTime.getTime()).format('HH:mm')}`;
  };

  /**
   * 日時＋タイムゾーンで文字列で取得する
   * @param dateTime 変換したい日時
   */
  export const createDateTimeTimeZoneText = (dateTime: Date): string => {
    return `${moment(dateTime.getTime()).format('yyyy-MM-DD HH:mm Z')}`;
  };

  /**
   * 日＋0:00タイムゾーンで文字列で取得する
   * @param dateTime 変換したい日時
   */
  export const createDateTimeTimeZoneStartText = (dateTime: Date): string => {
    return `${moment(dateTime.setHours(0, 0, 0)).format('yyyy-MM-DD HH:mm Z')}`;
  };

  /**
   * 日＋0:00のDateを取得する
   * @param dateTime 変換したい日時
   */
  export const createDateTimeTimeZoneStartDate = (dateTime: Date): Date => {
    return new Date(dateTime.setHours(0, 0, 0));
  };

  /**
   * 日＋23:59タイムゾーンで文字列で取得する
   * @param dateTime 変換したい日時
   */
  export const createDateTimeTimeZoneEndText = (dateTime: Date): string => {
    return `${moment(dateTime.setHours(23, 59, 59)).format('yyyy-MM-DD HH:mm Z')}`;
  };

  /**
   * 日＋23:59のDateを取得する
   * @param dateTime 変換したい日時
   */
  export const createDateTimeTimeZoneEndDate = (dateTime: Date): Date => {
    return new Date(dateTime.setHours(23, 59, 59));
  };

  /**
   * 現在から指定した前の日を文字列で取得する
   * @param returnNumber 遡りたい日数（0以上）
   */
  export const createGoBackInDateText = (returnNumber: number): string => {
    const targetReturnNumber = returnNumber > 0 ? returnNumber : 0;
    return `${moment().subtract(targetReturnNumber, 'days').format('yyyy年MM月DD日')}`;
  };
}
