import { injectable } from 'inversify';
import PreApplicationUsecase from '../../usecases/interfaces/pre-application.usecase.interface';
import PreApplication from '../../domains/value-objects/pre-application';
import { HttpRequest } from '../../utils/http-request';
import { ErrorHandler } from '../../domains/services/error-handler';

interface ResponseType {
  name: string;
  phoneNumber?: string;
  procedureType?: string;
  belongings?: string;
  preApplicationDetail?: string;
}

@injectable()
export default class PreApplicationControllerStub implements PreApplicationUsecase {
  async add(userId: string, preApplicationDetail: string): Promise<boolean> {
    const response = await HttpRequest.requestPost<{}>(HttpRequest.generateUrl('pre-application'), {
      userId,
      preApplicationDetail,
    });

    return !(
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    );
  }
  async addAgent(
    entityCode: string,
    userId: string,
    name: string,
    phoneNumber: string
  ): Promise<boolean> {
    const response = await HttpRequest.requestPost<{}>(HttpRequest.generateUrl('pre-application'), {
      publicEntityCode: entityCode,
      userId,
      name,
      phoneNumber,
    });

    return !(
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    );
  }
  async fetch(userId: string): Promise<PreApplication | null> {
    const requestUrl = HttpRequest.generateUrl('pre-application');
    requestUrl.searchParams.set('user-id', userId);
    requestUrl.searchParams.set('can-phone-number', 'true');
    requestUrl.searchParams.set('can-procedure-type', 'true');
    requestUrl.searchParams.set('can-belongings', 'true');
    const response = await HttpRequest.requestGet<ResponseType>(requestUrl);

    if (
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    ) {
      return null;
    }
    const responseData = response.data?.data ?? null;
    if (responseData == null) {
      return null;
    }

    return PreApplication.create({
      userName: responseData.name,
      belongings: responseData.belongings ?? '',
      phoneNumber: responseData.phoneNumber ?? '',
      procedureType: responseData.procedureType ?? '',
      preApplicationDetail: responseData.preApplicationDetail ?? '',
    });
  }
}
