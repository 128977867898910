import { createContext, ReactNode, useContext, useState, Dispatch, SetStateAction } from 'react';
import Reservation from '../../libs/domains/entitis/reservation';

interface ReservationContextType {
  reservation: Reservation | null;
  setReservation: Dispatch<SetStateAction<Reservation | null>>;
}

/**
 * 作成したコンテキストは一意の名称とする
 */
const ReservationContext = createContext<ReservationContextType>({} as ReservationContextType);

/**
 * 外部から設定値を取り出す用
 */
export const useReservationContext = () => useContext(ReservationContext);

/**
 * コンテキストを提供する
 */
export const ReservationProvider = ({ children }: { children: ReactNode }) => {
  const [reservation, setReservation] = useState<Reservation | null>(null);
  const { Provider } = ReservationContext;

  const value = {
    reservation,
    setReservation,
  };

  return <Provider value={value}>{children}</Provider>;
};
