import { Button, Checkbox, Divider, Stack, TextField } from '@mui/material';
import { GridDeleteIcon } from '@mui/x-data-grid';
import moment from 'moment';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import ExecuteButton from '../../molecule/execute-button';
import ReservationSlotUsecase from '../../../../libs/usecases/interfaces/reservation-slot.usecase.interface';
import SettingsCounterUniqueClosingDaysUsecase from '../../../../libs/usecases/interfaces/settings-counter-unique-closing-days.usecase.interface';
import { TYPES } from '../../../../types';
import { useContainer } from 'inversify-react';
import CounterUniqueClosingDay from '../../../../libs/domains/entitis/counter-closing-unique-day';
import { useIsWaitingContext } from '../../../contexts/is-waiting-context';
import NowLoading from '../../molecule/now-loading';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import style from './CounterUniqueClosingDaysSetting.module.scss';

interface PropsType {
  publicEntityCode: string;
  location: string;
  settings: CounterUniqueClosingDay[] | null;
  setSettings: Dispatch<SetStateAction<CounterUniqueClosingDay[] | null>>;
  setIsUpdate: Dispatch<SetStateAction<boolean>>;
}

const CounterUniqueClosingDaysSetting = ({
  publicEntityCode,
  location,
  settings,
  setSettings,
  setIsUpdate,
}: PropsType) => {
  const container = useContainer();

  // 入力された時間
  const [inputDate, setInputDate] = useState('');

  const [targetDay, setTargetDay] = useState<string | null>(null);

  // 読み込み中
  const { setIsWaiting } = useIsWaitingContext();

  // 窓口閉鎖と営業日
  const settingsCounterUniqueClosingDaysUsecase = useMemo(
    () =>
      container.get<SettingsCounterUniqueClosingDaysUsecase>(
        TYPES.SettingsCounterUniqueClosingDays
      ),
    [container]
  );

  // 予約設定反映と窓口閉鎖日の差分チェック
  const isBeforeDay = (date: Date): boolean => {
    if (!targetDay) return false;
    return moment(`${targetDay} 09:00:00`).diff(date) >= 0;
  };

  // 閉鎖日の追加
  const onAdd = async () => {
    setSettings((prev) => {
      if (prev == null) {
        return prev;
      }
      const terget = new Date(inputDate);

      // 同じ日付は追加しない
      if (prev.some((item) => item.date.getTime() === terget.getTime())) {
        return prev;
      }

      // 日付を追加して返却
      prev.push(CounterUniqueClosingDay.create({ date: terget, isBusinessDay: false }));
      setIsUpdate(true);
      return [...prev];
    });
  };

  // 営業日を選んだ時の動作
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    setSettings((prev) => {
      if (prev == null) {
        return prev;
      }
      const day = prev[index];
      if (day == null) {
        return prev;
      }
      prev[index].isBusinessDay = event.target.checked;
      return [...prev];
    });
  };

  // 指定した日時を削除する
  const onTrashClick = (target: Date) => {
    setSettings((prev) => {
      if (prev == null) {
        return prev;
      }
      const items = prev.filter(
        (counterClosingDay) => counterClosingDay.date.getTime() !== target.getTime()
      );
      return [...items];
    });
  };

  // 窓口閉鎖と営業日を取得する
  useEffect(() => {
    if (settings != null) {
      return;
    }
    setIsWaiting(true);
    settingsCounterUniqueClosingDaysUsecase
      .fetch(publicEntityCode, location)
      .then(() => {
        const data = settingsCounterUniqueClosingDaysUsecase.read();
        setSettings(data);
        setIsUpdate(false);
      })
      .catch(() => [])
      .finally(() => setIsWaiting(false));
  }, [
    location,
    publicEntityCode,
    setSettings,
    settingsCounterUniqueClosingDaysUsecase,
    settings,
    setIsWaiting,
    setIsUpdate,
  ]);

  // 予約枠操作
  const reservationSlotUsecase = useMemo(
    () => container.get<ReservationSlotUsecase>(TYPES.ReservationSlot),
    [container]
  );

  // 予約枠の取得
  useEffect(() => {
    const run = async () => {
      await reservationSlotUsecase.fetchSlots(publicEntityCode, location).catch(() => {});
      const slots = reservationSlotUsecase.readSlots();
      if (slots.length <= 0) {
        return;
      }
      const day = moment(
        slots.map((slot) => slot.visitDateTime.getTime()).reduce((a, b) => Math.max(a, b))
      );
      setTargetDay(day.format('YYYY-MM-DD'));
    };
    run();
  }, [publicEntityCode, location, reservationSlotUsecase]);

  return (
    <NowLoading isLoading={settings == null}>
      <Stack spacing={2} alignItems="center" justifyContent="center">
        <Stack className="text-sm">
          <div>土日祝日以外の特定日の窓口閉鎖日および閉庁日について登録いただけます。</div>
          <div>年末年始や法定停電等、カレンダーから取得できない日について設定ください。</div>
          <div>既に公開されている日付を設定した場合は、利用者は即日予約不可となり、</div>
          <div>職員様側での代理予約は可能な状態となります。</div>
          <div>
            {moment(targetDay).format('MM月DD日')}
            以前の日程の登録については、「窓口閉鎖日」のみ反映し、
          </div>
          <div>「営業日」は反映しません。</div>
          <div>
            ※{moment(targetDay).format('MM月DD日')}
            以前の日程を閉庁日とする場合は、窓口閉鎖日の登録後、
          </div>
          <div>全時間帯を代理予約して対応（CSV修正必要）。</div>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={2}>
          <TextField
            type="date"
            onChange={(event: any) => setInputDate(event.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <ExecuteButton
            isDisabled={inputDate === ''}
            isPrimary={false}
            text="追加"
            onClick={onAdd}
          />
        </Stack>
        <Stack alignItems="center" justifyContent="center">
          <ArrowDropDownIcon />
        </Stack>
        <Stack className={style.list} alignItems="center" justifyContent="center">
          <Stack
            className={style.header}
            direction="row"
            alignItems="flex-start"
            justifyContent="flex-start"
            spacing={4}
          >
            <div>窓口閉鎖日</div>
            <div>営業日</div>
          </Stack>
          <Stack className="border h-64 overflow-y-auto min-w-full" spacing={1}>
            {settings?.map((counterClosingDay, index) => (
              <div key={index}>
                <Stack direction="row" alignItems="center" justifyContent="center">
                  <Stack className="p-4 py-0 w-full" alignItems="center" justifyContent="center">
                    {moment(counterClosingDay.date.getTime()).format('YYYY/MM/DD')}
                  </Stack>
                  <div className="p-3 py-0">
                    <Checkbox
                      disabled={isBeforeDay(counterClosingDay.date)}
                      checked={counterClosingDay.isBusinessDay}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleChange(event, index)
                      }
                    />
                  </div>
                  <div className="p-1 py-0">
                    <Button
                      variant="text"
                      color="inherit"
                      onClick={() => onTrashClick(counterClosingDay.date)}
                    >
                      <GridDeleteIcon />
                    </Button>
                  </div>
                </Stack>
                <Divider orientation="vertical" flexItem />
              </div>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </NowLoading>
  );
};

export default CounterUniqueClosingDaysSetting;
