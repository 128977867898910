import moment from 'moment';
import { injectable } from 'inversify';
import { rootContainer } from '../../../inversify.config';
import { TYPES } from '../../../types';
import { HttpRequest } from '../../utils/http-request';
import Reservation from '../../domains/entitis/reservation';
import User from '../../domains/value-objects/user';
import PreApplication from '../../domains/value-objects/pre-application';
import { PageUrl } from '../../domains/services/page-url';
import { ErrorHandler } from '../../domains/services/error-handler';

import ReservationUsecase from '../../usecases/interfaces/reservation.usecase.interface';
import ReservationUserUsecase from '../../usecases/interfaces/reservation-user.usecase.interface';
import MailSettingUsecase from '../../usecases/interfaces/mail-setting.usecase.interface';

const { QUERY_KEYS } = PageUrl;

interface AddReservaitonResponseType {
  reservation_number: string;
}
interface SendMailResponseType {}

interface FetchResponseType {
  number: string; // 予約番号
  userId: string; // ユーザーID
  location: string; // 来庁場所
  remark: string; // 備考
  datetime: string; // 予約日時
  procedureType: string; // 申請種別
  name: string; // 予約者名
  phoneNumber: string; // 電話番号
  belongings: string; //
  preApplicationDetail?: string;
}

@injectable()
export default class ReservationController implements ReservationUsecase {
  // 予約ユーザー操作ユースケース
  private readonly _reservationUserUsecase = rootContainer.get<ReservationUserUsecase>(
    TYPES.ReservationUser
  );

  // メール設定ユースケース
  private readonly _mailSettingUsecase = rootContainer.get<MailSettingUsecase>(TYPES.MailSetting);

  async add(
    publicEntityCode: string,
    userId: string,
    location: string,
    remark: string,
    dateTime: string
  ): Promise<string> {
    const response = await HttpRequest.requestPost<AddReservaitonResponseType>(
      HttpRequest.generateUrl(`reservation`),
      {
        publicEntityCode,
        userId,
        location,
        remark,
        dateTime,
      }
    );
    if (
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    ) {
      return '';
    }
    return response.data?.data?.reservation_number ?? '';
  }

  async sendCompletedMail(publicEntityCode: string, reservation: Reservation): Promise<boolean> {
    // 予約完了メール送信
    const content = await this._mailSettingUsecase.generateCompletedMail(
      publicEntityCode,
      reservation.user?.mailadress ?? '',
      reservation.createViewDateTimeText(),
      reservation.reservationNumber ?? '',
      reservation.location,
      reservation.preApplication?.belongings ?? '',
      this.genereteChangeURL(publicEntityCode, reservation.user?.userId ?? '')
    );
    const response = await HttpRequest.requestPost<SendMailResponseType>(
      HttpRequest.generateUrl(`sendmail`),
      content
    );
    return !(
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    );
  }

  async sendChangedMail(publicEntityCode: string, reservation: Reservation): Promise<boolean> {
    // 予約変更メール送信
    const content = await this._mailSettingUsecase.generateChangedMail(
      publicEntityCode,
      reservation.user?.mailadress ?? '',
      reservation.createViewDateTimeText(),
      reservation.reservationNumber ?? '',
      reservation.location,
      reservation.preApplication?.belongings ?? '',
      this.genereteChangeURL(publicEntityCode, reservation.user?.userId ?? '')
    );
    const response = await HttpRequest.requestPost<SendMailResponseType>(
      HttpRequest.generateUrl(`sendmail`),
      content
    );
    return !(
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    );
  }
  async sendCanceledMail(publicEntityCode: string, reservation: Reservation): Promise<boolean> {
    // 予約キャンセル完了メール送信
    const content = await this._mailSettingUsecase.generateCanceledMail(
      publicEntityCode,
      reservation.user?.mailadress ?? ''
    );
    const response = await HttpRequest.requestPost<SendMailResponseType>(
      HttpRequest.generateUrl(`sendmail`),
      content
    );
    return !(
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    );
  }

  async fetch(
    user: User,
    number: string,
    canProcedureType: boolean = true,
    canName: boolean = true,
    canPhoneNumber: boolean = true,
    canBelongings: boolean = true,
    canPreApplicationDetail: boolean = true
  ): Promise<Reservation | null> {
    const requestUrl = HttpRequest.generateUrl('reservation');
    HttpRequest.addUrlParamater(requestUrl, 'user-id', user ? user.userId : '');
    HttpRequest.addUrlParamater(requestUrl, 'number', number ? number : '');
    HttpRequest.addUrlParamater(requestUrl, 'can-procedure-type', canProcedureType);
    HttpRequest.addUrlParamater(requestUrl, 'can-name', canName);
    HttpRequest.addUrlParamater(requestUrl, 'can-phone-number', canPhoneNumber);
    HttpRequest.addUrlParamater(requestUrl, 'can-belongings', canBelongings);
    HttpRequest.addUrlParamater(requestUrl, 'can-pre-application-detail', canPreApplicationDetail);
    const response = await HttpRequest.requestGet<FetchResponseType>(requestUrl);

    if (
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    ) {
      return null;
    }
    const body = response.data?.data ?? null;
    if (body == null) {
      return null;
    }

    // 事前申請を作成
    const preApplication = PreApplication.create({
      userName: body.name ?? '',
      belongings: body.belongings ?? '',
      phoneNumber: body.phoneNumber ?? '',
      procedureType: body.procedureType ?? '',
      preApplicationDetail: body.preApplicationDetail ?? '',
    });

    const reserveUser = user != null ? User.copy(user) : await this.fetchUser(body.userId);

    return Reservation.create({
      location: body.location,
      remark: body?.remark ?? '',
      visitDateTime: new Date(body.datetime),
      reservationNumber: body.number,
      preApplication,
      user: reserveUser,
    });
  }

  /**
   * 予約ユーザーを取得し、存在していないユーザーならメールアドレスを空で作成する
   * @param userId ユーザーID
   * @returns 予約ユーザー
   */
  async fetchUser(userId: string): Promise<User | undefined> {
    const user = await this._reservationUserUsecase.fetch(userId);
    if (user == null) {
      return User.create({ userId, mailadress: '' });
    }
    return User.copy(user);
  }

  async cancel(reserveNumber: string): Promise<boolean> {
    const requestUrl = HttpRequest.generateUrl('reservation');
    HttpRequest.addUrlParamater(requestUrl, 'number', reserveNumber);
    const response = await HttpRequest.requestDelete(requestUrl);
    return !(
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    );
  }

  readCacnelAttention(procedureType: string): string[] {
    switch (procedureType) {
      default:
        return ['改めて予約を行う場合には、お手数ですが再度手続きの入力からお願いします。'];
    }
  }
  async fetchList(
    publicEntityCode: string,
    filter?:
      | {
          location?: string | undefined;
          dateRange?: { dateFrom?: Date | undefined; dateTo?: Date | undefined } | undefined;
          reservationNumber?: string | undefined;
        }
      | undefined
  ): Promise<Reservation[]> {
    const requestUrl = HttpRequest.generateUrl('reservations');
    HttpRequest.addUrlParamater(requestUrl, 'public-entity-code', publicEntityCode);
    HttpRequest.addUrlParamater(requestUrl, 'location', filter?.location);
    if (filter?.dateRange != null) {
      const fromTime = filter.dateRange.dateFrom?.getTime();
      if (fromTime) {
        const dateFrom = `${moment(fromTime).format('yyyy-MM-DD HH:mm Z')}`;
        HttpRequest.addUrlParamater(requestUrl, 'date-from', dateFrom);
      }
      const toTime = filter.dateRange.dateTo?.getTime();
      if (toTime) {
        const dateTo = `${moment(toTime).format('yyyy-MM-DD HH:mm Z')}`;
        HttpRequest.addUrlParamater(requestUrl, 'date-to', dateTo);
      }
    }
    HttpRequest.addUrlParamater(requestUrl, 'number', filter?.reservationNumber);
    HttpRequest.addUrlParamater(requestUrl, 'can-procedure-type', true);
    HttpRequest.addUrlParamater(requestUrl, 'can-name', true);
    HttpRequest.addUrlParamater(requestUrl, 'can-phone-number', true);
    HttpRequest.addUrlParamater(requestUrl, 'can-belongings', true);
    const response = await HttpRequest.requestGet<FetchResponseType[]>(requestUrl);

    if (
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    ) {
      return [];
    }
    const revervations = response.data?.data ?? null;
    if (revervations == null) {
      return [];
    }

    return revervations
      .map((revervation) => {
        const preApplication = PreApplication.create({
          userName: revervation.name ?? '',
          belongings: revervation.belongings ?? '',
          phoneNumber: revervation.phoneNumber ?? '',
          procedureType: revervation.procedureType ?? '',
          preApplicationDetail: revervation.preApplicationDetail ?? '',
        });

        return Reservation.create({
          location: revervation.location,
          remark: revervation?.remark ?? '',
          visitDateTime: new Date(revervation.datetime),
          reservationNumber: revervation.number,
          preApplication,
        });
      })
      .sort((a, b) => a.visitDateTime.getTime() - b.visitDateTime.getTime());
  }

  /**
   * 変更・キャンセル画面のURLを作成する
   * @param publicEntityCode 自治体コード
   * @param reservation 予約情報
   * @returns
   */
  private genereteChangeURL(publicEntityCode: string, userId: string): string {
    const queryPublicEntityCode = {
      key: QUERY_KEYS.PUBLIC_ENTITY_CODE,
      value: publicEntityCode,
    };
    const queryUserId = {
      key: QUERY_KEYS.USER_ID,
      value: userId,
    };

    return PageUrl.generate(`/change-reservation-page`, queryPublicEntityCode, queryUserId);
  }
}
