import { createContext, ReactNode, useContext, useState, Dispatch, SetStateAction } from 'react';

interface IsChangeReservationContextType {
  isChangeReservation: boolean;
  setIsChangeReservation: Dispatch<SetStateAction<boolean>>;
}

/**
 * 作成したコンテキストは一意の名称とする
 */
const isChangeReservationContext = createContext<IsChangeReservationContextType>(
  {} as IsChangeReservationContextType
);

/**
 * 外部から設定値を取り出す用
 */
export const useIsChangeReservationContext = () => useContext(isChangeReservationContext);

/**
 * コンテキストを提供する
 */
export const IsChangeReservationProvider = ({ children }: { children: ReactNode }) => {
  const [isChangeReservation, setIsChangeReservation] = useState<boolean>(false);
  const { Provider } = isChangeReservationContext;

  const value = {
    isChangeReservation,
    setIsChangeReservation,
  };

  return <Provider value={value}>{children}</Provider>;
};
