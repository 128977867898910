import { Stack } from '@mui/material';
import React, { useState } from 'react';
import ExecuteButton from '../../molecule/execute-button';
import { buttonWidth } from '../../../styles/size';
import { ToParent } from '../../../../libs/domains/services/to-parent';
import ClosePageDaialog from '../../organism/close-page-daialog';

const ReservationSessionTimeoutPage = () => {
  // 「閉じる」警告を開くかどうか
  const [isCloseWarningOpen, setIsCloseWarningOpen] = useState<boolean>(false);

  // 次のページへ移動する
  const moveNextPage = () => {
    ToParent.requestClosing();
    if (window.history.length > 1) {
      setIsCloseWarningOpen(true);
    } else {
      // 閉じる
      window.close();
    }
  };

  return (
    <>
      <Stack justifyContent="center" alignItems="center" spacing={4}>
        <Stack justifyContent="center" alignItems="center">
          <div>セッションタイムアウトしています。</div>
          <div>認証しなおしてください。</div>
        </Stack>
        <ExecuteButton
          text={'閉じる'}
          onClick={() => moveNextPage()}
          isPrimary={true}
          type="button"
          width={buttonWidth}
        />
      </Stack>

      <ClosePageDaialog isOpen={isCloseWarningOpen} setIsOpen={setIsCloseWarningOpen} />
    </>
  );
};

export default ReservationSessionTimeoutPage;
