import React from 'react';
import style from './ExcecuteButton.module.scss';

interface Props {
  text: string;
  onClick?: () => void;
  isPrimary?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  width?: number;
  isDisabled?: boolean;
  className?: string;
  applyResponsiveWidthStyle?: boolean;
}

/**
 * 汎用の実行ボタン
 */
const ExecuteButton = ({
  text,
  onClick = () => {},
  isPrimary = true,
  type = 'submit',
  width,
  isDisabled = false,
  className = '',
  applyResponsiveWidthStyle = false,
}: Props) => {
  const buttonWidth = width ? `${width}px` : width;
  const setStyle = (isPrimary: boolean) => {
    const color = isDisabled
      ? 'bg-gray-500'
      : isPrimary
      ? 'bg-blue-900 hover:bg-blue-700 text-gray-50'
      : 'hover:bg-blue-100 text-blue-900 border-blue-900 border';
    return `${color} rounded-full px-4 py-2 `;
  };
  const widthStyle = applyResponsiveWidthStyle ? `${style['common-buton']}` : '';

  return (
    <>
      <button
        className={`${setStyle(isPrimary)} ${widthStyle} ${className}`}
        type={type}
        onClick={onClick}
        style={{ width: buttonWidth }}
        disabled={isDisabled}
      >
        {text}
      </button>
    </>
  );
};

export default ExecuteButton;
