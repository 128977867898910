import { createContext, ReactNode, useContext, useState, Dispatch, SetStateAction } from 'react';
import { ReservationSearchCondition } from '../../libs/domains/services/reservation-search-condition';

/**
 * コンテキストタイプ
 */
interface ReservationSearchConditionContextType {
  reservationSearchCondition: ReservationSearchCondition | null;
  setReservationSearchCondition: Dispatch<SetStateAction<ReservationSearchCondition | null>>;
}

/**
 * 作成したコンテキストは一意の名称とする
 */
const ReservationSearchConditionContext = createContext<ReservationSearchConditionContextType>(
  {} as ReservationSearchConditionContextType
);

/**
 * 外部から設定値を取り出す用
 */
export const useReservationSearchConditionContext = () =>
  useContext(ReservationSearchConditionContext);

/**
 * コンテキストを提供する
 */
export const ReservationSearchConditionProvider = ({ children }: { children: ReactNode }) => {
  const [reservationSearchCondition, setReservationSearchCondition] =
    useState<ReservationSearchCondition | null>(null);
  const { Provider } = ReservationSearchConditionContext;

  const value = {
    reservationSearchCondition,
    setReservationSearchCondition,
  };

  return <Provider value={value}>{children}</Provider>;
};
