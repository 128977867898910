import moment from 'moment';
import { TimeControl } from '../../utils/time-control';

/**
 * 予約情報のプロパティ
 */
export interface ReservationOutputProperties {
  procedureType: string;
  visitDateTime: Date;
  location: string;
  reservationNumber: string;
}

/**
 * 予約情報
 */
export default class ReservationOutput {
  private constructor(
    private readonly _procedureType: string, // 申請種別
    private readonly _visitDateTime: Date, // 来庁日時
    private readonly _location: string, // 来庁場所
    private readonly _reservationNumber: string // 予約番号
  ) {}

  /**
   * 同一オブジェクトとの比較
   * @param other 比較対象
   * @returns 一致:true、不一致:false
   */
  equals(other?: ReservationOutput | null): boolean {
    if (other == null) {
      return false;
    }

    // 必須プロパティの比較
    const exactRequired =
      this._procedureType === other.procedureType &&
      this._visitDateTime.getTime() === other.visitDateTime.getTime() &&
      this._location === other.location &&
      this._reservationNumber === other.reservationNumber;

    return exactRequired;
  }

  /**
   * 予約番号
   */
  get reservationNumber(): string {
    return this._reservationNumber;
  }

  /**
   * 来庁日時
   */
  get visitDateTime(): Date {
    return new Date(this._visitDateTime);
  }

  /**
   * 来庁日時で文字列を作成する
   */
  createDateTimeText(format: string = 'yyyy-MM-DD HH:mm'): string {
    return moment(this._visitDateTime.getTime()).format(format);
  }

  /**
   * 来庁日だけを文字列で取得する
   */
  createDateText(): string {
    return moment(this._visitDateTime.getTime()).format('yyyy-MM-DD');
  }

  /**
   * 申請種別
   */
  get procedureType(): string {
    return this._procedureType;
  }

  /**
   * 来庁場所
   */
  get location(): string {
    return this._location;
  }

  /**
   * 新規作成
   */
  static create(properties: ReservationOutputProperties) {
    return new ReservationOutput(
      properties.procedureType,
      new Date(properties.visitDateTime),
      properties.location,
      properties.reservationNumber
    );
  }

  /**
   * コピー
   */
  static copy(previos: ReservationOutput) {
    return this.create({
      procedureType: previos.procedureType,
      visitDateTime: previos.visitDateTime,
      location: previos.location,
      reservationNumber: previos.reservationNumber,
    });
  }

  /**
   * 対象の予約一覧をCSV出力用データに変換する
   * @param reservationOutput 出力用予約一覧
   * @returns CSV出力用データ
   */
  static createReservationOutputList(reservationOutput: ReservationOutput[]) {
    // 時間の一覧を取得
    const times: string[] = Array.from(
      new Set(reservationOutput.map((item) => TimeControl.createTimeText(item.visitDateTime)))
    ).sort();

    // CSV出力用に変換する
    const transformedData = reservationOutput.reduce((result: any, item) => {
      //   const date = TimeControl.createDateText(item.visitDateTime);
      const date = TimeControl.createDateTextSlash(item.visitDateTime);
      const time = TimeControl.createTimeText(item.visitDateTime);

      if (!result[date]) {
        result[date] = {};
      }

      if (!result[date][time]) {
        result[date][time] = [];
      }

      // 同じ日時の場合はスペースで結合
      result[date][time].push(`${item.procedureType}:${item.reservationNumber}`);

      return result;
    }, {});

    // CSV出力用のオブジェクト配列に変換する
    const csvData = Object.keys(transformedData).map((date) => {
      const entry: { [key: string]: string } = { '': date };

      times.forEach((time) => {
        entry[time] = (transformedData[date][time] || []).join(' ');
      });

      return entry;
    });

    return csvData;
  }
}
