import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tab,
  Tabs,
} from '@mui/material';
import { TYPES } from '../../../../types';
import SettingsLocationUsecase from '../../../../libs/usecases/interfaces/settings-location.usecase.interface';
import { useContainer } from 'inversify-react';
import { useSearchParams } from 'react-router-dom';
import { PageUrl } from '../../../../libs/domains/services/page-url';
import { useIsWaitingContext } from '../../../contexts/is-waiting-context';
import AcceptableCountSetting from '../../organism/acceptable-count-setting/AcceptableCountSetting';
import ExecuteButton from '../../molecule/execute-button';
import style from './LocationSettingsPage.module.scss';
import ReceptionTimesSetting from '../../organism/reception-times-setting';
import CounterClosingDaysSetting from '../../organism/counter-closing-days-setting';
import CounterUniqueClosingDaysSetting from '../../organism/counter-unique-closing-days-setting';
import CounterUniqueClosingDay from '../../../../libs/domains/entitis/counter-closing-unique-day';
import CounterClosingDay from '../../../../libs/domains/entitis/counter-closing-day';
import AcceptableCount from '../../../../libs/domains/entitis/acceptable-count';
import ReceptionTime from '../../../../libs/domains/entitis/reception-time';
import SettingsReceptionTimeUsecase from '../../../../libs/usecases/interfaces/settings-reception-time.usecase.interface';
import SettingsAcceptableCountUsecase from '../../../../libs/usecases/interfaces/settings-acceptable-count.usecase.interface';
import SettingsCounterClosingDaysUsecase from '../../../../libs/usecases/interfaces/settings-counter-closing-days.usecase.interface';
import SettingsCounterUniqueClosingDaysUsecase from '../../../../libs/usecases/interfaces/settings-counter-unique-closing-days.usecase.interface';
import TabPanel, { a11yProps } from '../../molecule/tab-panel';
import { useIsUpdateLocationSettingsContext } from '../../../contexts/is-update-location-settings-context';
import GuideSettingsReflection from '../../organism/guide-settings-reflection';

const { QUERY_KEYS } = PageUrl;

const LocationSettingsPage = () => {
  const buttonWidth = 300;
  const container = useContainer();
  const [searchParams] = useSearchParams();

  // 自治体コードを取得
  const publicEntityCode = searchParams.get(QUERY_KEYS.PUBLIC_ENTITY_CODE) ?? '';

  // 来庁場所
  const settingsLocationUsecase = container.get<SettingsLocationUsecase>(TYPES.SettingsLocation);
  const [locations, setLocations] = useState<string[]>([]);
  const [location, setLocation] = useState('');

  // タブ切り替え管理
  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  // 初期化管理
  const [isInitializationComplete, setIsInitializationComplete] = useState<boolean>(false);

  // 場所別設定の変更の有無
  const { setIsUpdateLocationSettings: setIsUpdate, needDiscardLocationSettins } =
    useIsUpdateLocationSettingsContext();

  // 場所の変更の有無
  const [isChangeLocation, setIsChangeLocation] = useState<boolean>(false);

  // ▼▼▼▼各種設定項目
  // ------曜日毎の受付時間設定
  const [receptionTimes, setReceptionTimes] = useState<ReceptionTime[] | null>(null);
  // ------受付組数
  const [acceptableCountData, setAcceptableCountData] = useState<AcceptableCount[] | null>(null);
  // ------窓口閉鎖と営業日を管理(曜日指定)
  const [counterClosingDays, setCounterClosingDays] = useState<CounterClosingDay[] | null>(null);
  // ------窓口閉鎖と営業日を管理(日指定)
  const [counterUniqueClosingDays, setCounterUniqueClosingDays] = useState<
    CounterUniqueClosingDay[] | null
  >(null);
  // ▲▲▲▲▲ここまで

  // ▼▼▼▼設定毎のレポジトリ
  // ------曜日毎の受付時間設定
  const settingsReceptionTimeUsecase = useMemo(
    () => container.get<SettingsReceptionTimeUsecase>(TYPES.SettingsReceptionTime),
    [container]
  );
  // ------受付組数
  const settingsAcceptableCountUsecase = useMemo(
    () => container.get<SettingsAcceptableCountUsecase>(TYPES.SettingsAcceptableCount),
    [container]
  );
  // ------窓口閉鎖と営業日(曜日指定)
  const settingsCounterClosingDaysUsecase = useMemo(
    () => container.get<SettingsCounterClosingDaysUsecase>(TYPES.SettingsCounterClosingDays),
    [container]
  );
  // ------窓口閉鎖と営業日(日指定)
  const settingsCounterUniqueClosingDaysUsecase = useMemo(
    () =>
      container.get<SettingsCounterUniqueClosingDaysUsecase>(
        TYPES.SettingsCounterUniqueClosingDays
      ),
    [container]
  );
  // ▲▲▲▲▲ここまで

  // 読み込み中
  const { setIsWaiting } = useIsWaitingContext();

  // 設定の初期化
  const initializeSettings = useCallback(() => {
    setReceptionTimes(null);
    setAcceptableCountData(null);
    setCounterClosingDays(null);
    setCounterUniqueClosingDays(null);
  }, [setReceptionTimes, setAcceptableCountData, setCounterClosingDays]);

  // 来庁場所変更時の処理
  const handleLocationChange = useCallback(
    (event: SelectChangeEvent) => {
      if (!needDiscardLocationSettins()) {
        setLocation((prev) => prev);
        return;
      }

      // 設定の再読み込み
      initializeSettings();
      // 場所設定の変更
      setLocation(event.target.value as string);
      setIsChangeLocation(true);
    },
    [setLocation, initializeSettings, needDiscardLocationSettins, setIsChangeLocation]
  );

  // 設定の保存
  const onSave = useCallback(async () => {
    try {
      setIsWaiting(true);
      // 曜日毎の受付時間設定
      if (receptionTimes != null) {
        if (
          !(await settingsReceptionTimeUsecase.update(publicEntityCode, location, receptionTimes))
        ) {
          throw new Error('曜日毎の受付時間設定に失敗しました');
        }
      }
      // 受付組数
      if (acceptableCountData != null) {
        if (
          !(await settingsAcceptableCountUsecase.update(
            publicEntityCode,
            location,
            acceptableCountData
          ))
        ) {
          throw new Error('受付組数設定に失敗しました');
        }
      }
      // 窓口閉鎖と営業日(曜日指定)
      if (counterClosingDays != null) {
        if (
          !(await settingsCounterClosingDaysUsecase.update(
            publicEntityCode,
            location,
            counterClosingDays
          ))
        ) {
          throw new Error('曜日毎の窓口閉鎖と営業日設定に失敗しました');
        }
      }
      // 窓口閉鎖と営業日(日指定)
      if (counterUniqueClosingDays != null) {
        if (
          !(await settingsCounterUniqueClosingDaysUsecase.update(
            publicEntityCode,
            location,
            counterUniqueClosingDays
          ))
        ) {
          throw new Error('窓口閉鎖と営業日設定に失敗しました');
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      }
    } finally {
      setIsWaiting(false);
      setIsUpdate(false);
    }
  }, [
    location,
    publicEntityCode,
    setIsWaiting,
    setIsUpdate,
    acceptableCountData,
    counterClosingDays,
    counterUniqueClosingDays,
    receptionTimes,
    settingsAcceptableCountUsecase,
    settingsCounterClosingDaysUsecase,
    settingsCounterUniqueClosingDaysUsecase,
    settingsReceptionTimeUsecase,
  ]);

  // 初期化処理
  useEffect(() => {
    setIsWaiting(true);
    settingsLocationUsecase
      .fetch(publicEntityCode)
      .then(() => {
        const readlocations = settingsLocationUsecase.read();
        setLocations(readlocations);
        if (readlocations.length > 0) {
          setLocation(readlocations[0]);
        }
      })
      .catch(() => [])
      .finally(() => {
        setIsUpdate(false);
        setIsWaiting(false);
        setIsInitializationComplete(true);
      });
  }, [
    setIsWaiting,
    setLocations,
    setLocation,
    setIsUpdate,
    settingsLocationUsecase,
    publicEntityCode,
  ]);

  return (
    <>
      <Stack className={`${style.base}`} justifyContent="center" alignItems="center">
        <Stack className={`${style.headar} grow-0`} justifyContent="center" alignItems="center">
          <Stack className={`w-3/5 max-w-md`} spacing={1}>
            <InputLabel>来庁場所</InputLabel>
            <Select value={location} onChange={handleLocationChange} size="small">
              {locations.map((location, index) => (
                <MenuItem key={index} value={location}>
                  {location}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <GuideSettingsReflection location={location} />
        </Stack>

        <Stack className={`${style.body} grow`} justifyContent="flex-start" alignItems="center">
          {isInitializationComplete && (
            <Stack className="w-full" justifyContent="center" alignItems="center" spacing={2}>
              <Box className="" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Stack justifyContent="center" alignItems="center">
                  <Tabs value={tabValue} onChange={handleChange}>
                    <Tab label="受付時間設定" {...a11yProps(0)} />
                    <Tab label="受付組数設定" {...a11yProps(1)} />
                    <Tab label="窓口閉鎖曜日設定" {...a11yProps(2)} />
                    <Tab label="窓口閉鎖日設定" {...a11yProps(3)} />
                  </Tabs>
                </Stack>
              </Box>
              <TabPanel value={tabValue} index={0}>
                <ReceptionTimesSetting
                  publicEntityCode={publicEntityCode}
                  location={location}
                  settings={receptionTimes}
                  setSettings={setReceptionTimes}
                  setIsUpdate={setIsUpdate}
                  isChangeLocation={isChangeLocation}
                  setIsChangeLocation={setIsChangeLocation}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <AcceptableCountSetting
                  publicEntityCode={publicEntityCode}
                  location={location}
                  settings={acceptableCountData}
                  setSettings={setAcceptableCountData}
                  setIsUpdate={setIsUpdate}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <CounterClosingDaysSetting
                  publicEntityCode={publicEntityCode}
                  location={location}
                  settings={counterClosingDays}
                  setSettings={setCounterClosingDays}
                  setIsUpdate={setIsUpdate}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <CounterUniqueClosingDaysSetting
                  publicEntityCode={publicEntityCode}
                  location={location}
                  settings={counterUniqueClosingDays}
                  setSettings={setCounterUniqueClosingDays}
                  setIsUpdate={setIsUpdate}
                />
              </TabPanel>
            </Stack>
          )}
        </Stack>
        <Stack
          className={`${style.footer} grow-0 border-t`}
          justifyContent="center"
          alignItems="center"
        >
          <ExecuteButton text="保存" onClick={onSave} width={buttonWidth} />
        </Stack>
      </Stack>
    </>
  );
};

export default LocationSettingsPage;
