import { Checkbox, Divider, Stack, Button } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useContainer } from 'inversify-react';
import ExecuteButton from '../../molecule/execute-button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import { TYPES } from '../../../../types';
import SettingsReceptionTimeUsecase from '../../../../libs/usecases/interfaces/settings-reception-time.usecase.interface';
import ReceptionTime from '../../../../libs/domains/entitis/reception-time';
import { useIsWaitingContext } from '../../../contexts/is-waiting-context';
import NowLoading from '../../molecule/now-loading';

interface PropsType {
  publicEntityCode: string;
  location: string;
  settings: ReceptionTime[] | null;
  setSettings: Dispatch<SetStateAction<ReceptionTime[] | null>>;
  setIsUpdate: Dispatch<SetStateAction<boolean>>;
  isChangeLocation: boolean;
  setIsChangeLocation: Dispatch<SetStateAction<boolean>>;
}

interface AddingWeekDaysType {
  dow: string;
  isAdding: boolean;
}

const ReceptionTimesSetting = ({
  publicEntityCode,
  location,
  settings,
  setSettings,
  setIsUpdate,
  isChangeLocation,
  setIsChangeLocation,
}: PropsType) => {
  const container = useContainer();

  // 曜日毎の受付時間設定
  const settingsReceptionTimeUsecase = useMemo(
    () => container.get<SettingsReceptionTimeUsecase>(TYPES.SettingsReceptionTime),
    [container]
  );

  // 追加したい時間
  const initialAddingReceptionTime: string = useMemo(() => '09:00', []);
  const [addingReceptionTime, setAddingReceptionTime] = useState(initialAddingReceptionTime);
  // 読み込み中
  const { setIsWaiting } = useIsWaitingContext();

  // 追加したい曜日
  const initialAddingWeekDays: AddingWeekDaysType[] = useMemo(() => {
    return [
      { dow: '日', isAdding: false },
      { dow: '月', isAdding: false },
      { dow: '火', isAdding: false },
      { dow: '水', isAdding: false },
      { dow: '木', isAdding: false },
      { dow: '金', isAdding: false },
      { dow: '土', isAdding: false },
      { dow: '祝', isAdding: false },
    ];
  }, []);
  const [addingWeekDays, setAddingaWeekDays] = useState<AddingWeekDaysType[]>([
    ...initialAddingWeekDays,
  ]);

  // 曜日を選んだ時の動作
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    setAddingaWeekDays((days) => {
      const day = days[index];
      if (day == null) {
        return days;
      }
      days[index] = { ...day, isAdding: event.target.checked };
      return [...days];
    });
  };

  // 追加ボタン押下字の動作
  const onClick = () => {
    setSettings((prev) => {
      if (prev == null) {
        return prev;
      }
      const newReceptionTimes = prev.map((receptionTime, index) => {
        const isAdd = addingWeekDays[index].isAdding;
        if (!isAdd) {
          return receptionTime;
        }
        if (receptionTime.times.some((time) => time === addingReceptionTime)) {
          return receptionTime;
        }

        const times = receptionTime.times;
        times.push(addingReceptionTime);
        times.sort();
        receptionTime.times = times;
        return ReceptionTime.copy(receptionTime);
      });
      setIsUpdate(true);
      return newReceptionTimes;
    });
  };

  // 指定した時間を削除する
  const onTrashClick = (target: string, index: number) => {
    setSettings((prev) => {
      if (prev == null) {
        return prev;
      }
      const times = prev[index].times.filter((time) => time !== target);
      prev[index].times = times;
      setIsUpdate(true);
      return [...prev];
    });
  };
  useEffect(() => {
    if (!isChangeLocation) {
      return;
    }
    // 追加したい曜日と時間を初期化
    setAddingReceptionTime(initialAddingReceptionTime);
    setAddingaWeekDays([...initialAddingWeekDays]);
    setIsChangeLocation(false);
  }, [isChangeLocation, setIsChangeLocation, initialAddingReceptionTime, initialAddingWeekDays]);

  useEffect(() => {
    if (settings != null) {
      return;
    }

    setIsWaiting(true);
    settingsReceptionTimeUsecase
      .fetch(publicEntityCode, location)
      .then(() => {
        const receptionTimes = settingsReceptionTimeUsecase.read();
        setSettings(receptionTimes);
        setIsUpdate(false);
      })
      .catch(() => [])
      .finally(() => setIsWaiting(false));
  }, [
    setIsWaiting,
    location,
    publicEntityCode,
    settings,
    setSettings,
    settingsReceptionTimeUsecase,
    setIsUpdate,
  ]);

  return (
    <NowLoading isLoading={settings == null}>
      <Stack spacing={1}>
        {/* 設定内容 */}
        <Stack spacing={1} alignItems="center" justifyContent="center">
          <Stack className="text-sm">
            <div>対象の曜日をチェックし、受付開始時間を設定後、追加ボタンをクリックすると、</div>
            <div>対象の曜日の指定時間に予約の開始時間が設定されます。</div>
            <div>ゴミ箱マークをクリックすると、対象の曜日×時間の予約開始時間が削除されます。</div>
          </Stack>

          {/* 受付曜日 */}
          <Stack spacing={0.25} alignItems="center" justifyContent="center">
            <Stack direction="row">
              <Stack className="border-y" direction="row" spacing={1}>
                <Divider orientation="vertical" flexItem />
                {addingWeekDays.map((addingWeekDay, index) => (
                  <div key={index}>
                    <Stack alignItems="center" justifyContent="center">
                      <Stack
                        className="p-1 pb-0 w-full"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {addingWeekDay.dow}
                      </Stack>
                      <div className="p-1 py-0">
                        <Checkbox
                          value={addingWeekDay.isAdding}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            handleChange(event, index)
                          }
                        />
                      </div>
                    </Stack>
                    <Divider orientation="vertical" flexItem />
                  </div>
                ))}
                <Divider orientation="vertical" flexItem />
              </Stack>
            </Stack>
          </Stack>

          {/* 受付時間 */}
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
            <TimePicker
              label="受付時間の入力"
              ampm={false}
              defaultValue={moment(new Date().setHours(9, 0, 0, 0))}
              timeSteps={{ minutes: 30 }}
              onChange={(time: any) =>
                time != null ? setAddingReceptionTime(time.format('HH:mm')) : ''
              }
            />
            <ExecuteButton
              isDisabled={addingReceptionTime === '' || !addingWeekDays.some((day) => day.isAdding)}
              isPrimary={false}
              text="追加"
              onClick={onClick}
            />
          </Stack>
        </Stack>
        <Stack alignItems="center" justifyContent="center">
          <ArrowDropDownIcon />
        </Stack>

        {/* 設定結果 */}

        <Stack className="border-x" spacing={0.2}>
          <Divider flexItem />
          {settings?.map((receptionTime, index) => (
            <div key={index}>
              <Stack alignItems="center" justifyContent="flex-start" direction="row">
                <Stack className="p-1 pb-0 w-12" alignItems="center" justifyContent="center">
                  {receptionTime.dow}
                </Stack>
                <Divider orientation="vertical" flexItem />
                <Stack className="p-1 py-0" direction="row" spacing={1}>
                  {receptionTime.times.map((time, timesIndex) => (
                    <Stack
                      key={timesIndex}
                      className="bg-gray-50 shadow-sm text-sm text-gray-600"
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <Button
                        variant="text"
                        color="inherit"
                        onClick={() => onTrashClick(time, index)}
                      >
                        <div>{time}</div>
                        <DeleteIcon color="inherit" />
                      </Button>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
              <Divider flexItem />
            </div>
          ))}
        </Stack>
      </Stack>
    </NowLoading>
  );
};

export default ReceptionTimesSetting;
