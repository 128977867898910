import { injectable } from 'inversify';
import RemarkUsecase from '../../usecases/interfaces/remark-usecase.interface';
import { HttpRequest } from '../../utils/http-request';
import { ErrorHandler } from '../../domains/services/error-handler';

@injectable()
export default class RemarkController implements RemarkUsecase {
  async update(publicEntityCode: string, reserveNumber: string, value: string): Promise<boolean> {
    const response = await HttpRequest.requestPut<null>(
      HttpRequest.generateUrl(`reservation-remark`),
      {
        publicEntityCode,
        number: reserveNumber,
        remark: value,
      }
    );
    return !(
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    );
  }
}
