import { createContext, ReactNode, useContext, useState, Dispatch, SetStateAction } from 'react';

interface UserAuthenticatedContextType {
  userAuthenticated: string | null;
  setUserAuthenticated: Dispatch<SetStateAction<string | null>>;
}

/**
 * 作成したコンテキストは一意の名称とする
 */
const userAuthenticatedContext = createContext<UserAuthenticatedContextType>(
  {} as UserAuthenticatedContextType
);

/**
 * 外部から設定値を取り出す用
 */
export const useUserAuthenticatedContext = () => useContext(userAuthenticatedContext);

/**
 * コンテキストを提供する
 */
export const UserAuthenticatedProvider = ({ children }: { children: ReactNode }) => {
  const [userAuthenticated, setUserAuthenticated] = useState<string | null>(null);
  const { Provider } = userAuthenticatedContext;

  const value = {
    userAuthenticated,
    setUserAuthenticated,
  };

  return <Provider value={value}>{children}</Provider>;
};
