import { Stack, TextField } from '@mui/material';
import { useContainer } from 'inversify-react';
import React, { useEffect, useState } from 'react';
import { TYPES } from '../../../../types';
import { useIsWaitingContext } from '../../../contexts/is-waiting-context';
import SettingsReservationableTimeUsecase from '../../../../libs/usecases/interfaces/settings-reservationable-time.usecase.interface';
import ExecuteButton from '../../molecule/execute-button';
import WaitAMoment from '../../molecule/wait-a-moment';

interface PropsType {
  publicEntityCode: string;
}

const max = 99;
const min = 0;
const textWidth = 80;

const RereservationableTimeSetting = ({ publicEntityCode }: PropsType) => {
  const container = useContainer();

  // 予約可能期間
  const settingsRereservationableTimeUsecase = container.get<SettingsReservationableTimeUsecase>(
    TYPES.SettingsReservationableTime
  );

  // 初期化完了
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  // 現在の予約可能期間の設定を取得する
  const [startDate, setStartDate] = useState(min);
  const [endDate, setEndDate] = useState(min);

  // 読み込み中
  const { setIsWaiting } = useIsWaitingContext();

  // 予約可能期間を更新
  const handleOnclick = () => {
    setIsWaiting(true);

    settingsRereservationableTimeUsecase
      .update(publicEntityCode, startDate, endDate)
      .then(() => {})
      .finally(() => setIsWaiting(false));
  };

  // ボタンの有効をチェック
  const isDisabled = () => {
    const isMaxOver = startDate > max || endDate > max;
    const isMinSmaller = startDate < min || endDate < min;
    const isNone = startDate == null || endDate == null;
    const isStartGreaterThanEnd = startDate > endDate;
    return isMaxOver || isMinSmaller || isNone || isStartGreaterThanEnd;
  };

  useEffect(() => {
    settingsRereservationableTimeUsecase
      .fetch(publicEntityCode)
      .then(() => {
        const reservationableTime = settingsRereservationableTimeUsecase.read();
        setStartDate(reservationableTime.startDate);
        setEndDate(reservationableTime.endDate);
      })
      .catch(() => [])
      .finally(() => setIsInitialized(true));
  }, [
    setIsWaiting,
    setStartDate,
    setEndDate,
    settingsRereservationableTimeUsecase,
    publicEntityCode,
    setIsInitialized,
  ]);
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
        <WaitAMoment isLoading={!isInitialized}>
          <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1}>
            <TextField
              sx={{ width: textWidth }}
              type="number"
              InputProps={{ inputProps: { min, max } }}
              value={startDate}
              onChange={(event: any) => setStartDate(Number(event.target.value))}
            />
            <div className="text-sm">営業日後から</div>
            <TextField
              sx={{ width: textWidth }}
              type="number"
              InputProps={{ inputProps: { min, max } }}
              value={endDate}
              onChange={(event: any) => setEndDate(Number(event.target.value))}
            />
            <div className="text-sm">営業日後まで</div>
          </Stack>
          <ExecuteButton text="保存" onClick={handleOnclick} isDisabled={isDisabled()} />
        </WaitAMoment>
      </Stack>
    </>
  );
};

export default RereservationableTimeSetting;
