import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { PageUrl } from '../../../../libs/domains/services/page-url';
import { Stack } from '@mui/material';
import { useContainer } from 'inversify-react';
import PreApplicationUsecase from '../../../../libs/usecases/interfaces/pre-application.usecase.interface';
import { TYPES } from '../../../../types';
import { useIsWaitingContext } from '../../../contexts/is-waiting-context';
const { QUERY_KEYS } = PageUrl;

const DummyPreApplicationPage = () => {
  const container = useContainer();
  const preApplicationUsecase = useMemo(
    () => container.get<PreApplicationUsecase>(TYPES.PreApplication),
    [container]
  );

  const [searchParams] = useSearchParams();

  // 予約画面の表示/非表示
  const [isShowReservationPage, setIsShowReservationPage] = useState(false);

  // 読み込み中
  const { setIsWaiting } = useIsWaitingContext();

  const publicEntityCode = searchParams.get(QUERY_KEYS.PUBLIC_ENTITY_CODE) ?? '';
  const userId = searchParams.get(QUERY_KEYS.USER_ID) ?? '';
  const publicEntutyCodeQuery = { key: QUERY_KEYS.PUBLIC_ENTITY_CODE, value: publicEntityCode };
  const userIdQuery = { key: QUERY_KEYS.USER_ID, value: userId };

  const changeMailUrl = PageUrl.generate(
    '/change-reservation-page',
    publicEntutyCodeQuery,
    userIdQuery
  );

  useEffect(() => {}, [userId, preApplicationUsecase]);

  // iFrameからユーザーIDを受け取って画面遷移
  const handler = useCallback((event: MessageEvent<any>) => {
    console.log(event);
    if (event.data?.type !== 'close') {
      return;
    }
    setIsShowReservationPage(false);
  }, []);
  useEffect(() => {
    // iFrameから受信するイベントを設定
    window.addEventListener('message', handler);

    // ダミーの事前申請情報を登録
    const preApplicationDetail =
      'T0d1Un5RsXJrUg8HkiQ4HQRsnDizes2kVUMCrs1hTvs40rVjV9VOigkDc8mu8XC6TohsCMbUmEuTYYzBBHPOyxjMsbXZOWq5DtNahrOW03c2StIfGVUVqcaXX7DZ2UE1KRT4LAwo0AL3hwbYblptePc0ZK/q5jIL10DY/JRAZt10R53rgf4Zm/bm7vjeW33SYWqRw7RbRXyauRVGblOEBCfhbXv7GwaQl3i7AoAspE5jAmrwKA/hPXY/5MgFFOtxPDdI3Ioi5d31zl7K1EGyUVYFYlwpfFU4oWQy4tL9Y+phlRU76WtWKTL7IVFKN69G1whEUYD+C9ey2mYIloWalQppjeyVLC8Ai23nAqdoM1Dkq9a+zoWj9Cdrt2QuzNQLqfwvq6m6MtrcM3vVgUsZ+F1FiPMhltaiCnBSz2GCS6rAUtHGwlqU4Wk54/JreHp+TLLktnkQajg4V3MSzdxA65HfBTF+OB7jQ6IosNrL9fkP4YI/Oq9JXbleLTMQzwJ7kXpttt4Fy6q+cre6j1xYtb71oHdy9Z/Z9hzEPHrEPBax1+P48UzXclNVu4cfFAwBAW8P//SAYvFpqbhJ14Kq0bdBdubXlImnMpr7LVA2dlnxb6vNIJ+L4JWhg7Mhyf2PXQxDThlBeHR5u4AtqJvHk523Bv33Nxv1/JPB0NaarBVk9B1urezT5BEDVgi2G7dFSaIVW8kJDEiyKMQv0kCGV8EO7cUyIEsBSiXPICzMv19/YDkOnRZQ1/QY5Wnf+xrr4/eCRQ0sAtqOW7jEFzaA48grZOCcuZ3D8KYX6qQGmtUyR+64hgZYh8lNjb1CzZkCK28Q3j8S5OfF9k+hwaso4E2S78aPM5m7u8IWZM5esJdmMmmkTIcKryx6BlDke3wV6Q8vIf+xQqTtMa05VPD536ZqUavXNvtkzIc1ChOzf9Tx4ipAKh2V27I5fUw6jpktE/UWubJyVksLnjJOZedC7Pc6t1qtolPsrBsART8g1Yckk2q0zNxZT0k61pjaBNI3aV+8f9DXQGq7ldreS+VE0vWf5SdSoXU89W5yCCS5qKChuvUPq8mEQ92ydcjT2qBsF2e7a+meCJSKBrfDXSxiu/7nnH6Nwas8Dx34oh1AmFPG8ccq66jmrc2Av4MuLzVUm5uYg8QmeiIa1pgqjwn7V1ZSGxLUTW7X057evGCEbf3n187TnmD/zRRbIt62R0YNTj7K1p1/g9k3UnUfGhs7wzgn0IhLyq+bQhO4R04ym7FhTdUVQYqpEDLhWZCfKr4KNpkm3UorF6eMsUHdBpSHzxk6v8eUCk8XY5UcIpXth4njjPlCcObxM+UQVUIpSPO/B+WAB9bNJNWk9HXRZr4yqtRA+FXE2/zwcfRAOukEvqq4AJZNE373hCRob2idru+qr5vUpdyDaldwH7Gd0g+EhJFGhhB1IzyH+QIji0uJNaQ=';
    setIsWaiting(true);
    preApplicationUsecase
      .add(userId, preApplicationDetail)
      .then((result) => {
        if (result) {
          console.log('事前申請の仮登録に成功');
        } else {
          console.log('事前申請の仮登録に失敗');
        }
      })
      .catch(() => console.log('事前申請の仮登録に失敗'))
      .finally(() => setIsWaiting(false));

    return () => {
      // イベント解除
      window.removeEventListener('message', handler);
    };
  }, [handler, userId, preApplicationUsecase, setIsWaiting]);

  return (
    <div className="w-screen h-screen">
      {!isShowReservationPage && (
        <div className="p-8">
          <div>事前申請システム</div>
          <div>利用規約画面～申請完了までの代わり</div>
          <div>{userId}</div>
          <Stack>
            <div
              className="text-blue-600 hover:text-blue-900 hover:cursor-pointer"
              onClick={() => setIsShowReservationPage(true)}
            >
              予約開始
            </div>
            <div>予約変更・キャンセルは下から</div>
            <div>
              <Link className="text-blue-600 hover:text-blue-900" to={changeMailUrl}>
                {changeMailUrl}
              </Link>
            </div>
          </Stack>
        </div>
      )}
      {isShowReservationPage && (
        <iframe
          title="reservation"
          width="100%"
          height="100%"
          src={`${PageUrl.generate('/reservation-page', publicEntutyCodeQuery, userIdQuery)}`}
        ></iframe>
      )}
    </div>
  );
};

export default DummyPreApplicationPage;
