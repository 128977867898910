import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PageUrl } from '../../../../libs/domains/services/page-url';
import { InputLabel, Stack, TextField } from '@mui/material';
import ExecuteButton from '../../molecule/execute-button';
import { useContainer } from 'inversify-react';
import ReservationUsecase from '../../../../libs/usecases/interfaces/reservation.usecase.interface';
import { TYPES } from '../../../../types';
import CommonDialog from '../../molecule/common-dialog';
import ReserveCancel from '../../organism/reserve-cancel';
import ReservationUserUsecase from '../../../../libs/usecases/interfaces/reservation-user.usecase.interface';
import { useIsWaitingContext } from '../../../contexts/is-waiting-context';
import { useReservationContext } from '../../../contexts/revervation-context';
import Reservation from '../../../../libs/domains/entitis/reservation';
import { useUserAuthenticatedContext } from '../../../contexts/user-authenticated-context';
import { useIsChangeReservationContext } from '../../../contexts/is-change-reservation-context';
import { usePreReservationContext } from '../../../contexts/pre-revervation-context';
import { useAgentIdContext } from '../../../contexts/agent-id-context';
import { useSelectedManagementPageViewContext } from '../../../contexts/selected-managemaent-page-view-context';
import User from '../../../../libs/domains/value-objects/user';
import NowLoading from '../../molecule/now-loading';
import { ErrorHandler } from '../../../../libs/domains/services/error-handler';
import SessionControl from '../../organism/session-control';
import { Session } from '../../../../libs/domains/entitis/session';

const { QUERY_KEYS, ENDPOINT } = PageUrl;

interface ReservationViewModel {
  reservationNumber: string;
  location: string;
  visitDateTime: string;
}

const ChangeReservationPage = () => {
  const container = useContainer();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // セッション管理
  const sessionManager = useMemo(() => Session.create(false), []);

  // 予約情報
  const { reservation, setReservation } = useReservationContext();
  const { setPreReservation } = usePreReservationContext();

  // 読み込み中
  const { setIsWaiting } = useIsWaitingContext();

  // 認証済みユーザーかどうか
  const { userAuthenticated, setUserAuthenticated } = useUserAuthenticatedContext();

  // 再予約かどうか
  const { isChangeReservation, setIsChangeReservation } = useIsChangeReservationContext();

  // ユースケース取得
  const reservationUsecase = useMemo(
    () => container.get<ReservationUsecase>(TYPES.Reservation),
    [container]
  );
  const reservationUserUsecase = useMemo(
    () => container.get<ReservationUserUsecase>(TYPES.ReservationUser),
    [container]
  );

  // 代理予約者
  const { agentId } = useAgentIdContext();

  // 管理画面の表示内容
  const { setSelectedView } = useSelectedManagementPageViewContext();

  // 自治体コードとユーザーIDを取得
  const publicEntityCode = searchParams.get(QUERY_KEYS.PUBLIC_ENTITY_CODE) ?? '';
  const userId = searchParams.get(QUERY_KEYS.USER_ID) ?? agentId ?? '';
  // 不正なリクエストパラメータ
  if (userId === '' || publicEntityCode === '') {
    navigate(ENDPOINT.NOT_FOUND, { replace: true });
  }

  // 画面表示用の情報
  const [viewReservation, setViewReservation] = useState<ReservationViewModel>({
    reservationNumber: '-',
    location: '-',
    visitDateTime: '-',
  });

  // 認証ダイアログ表示
  const [isAuthDialogOpen, setIsAuthDialogOpen] = useState(false);
  const openAuthDialog = () => {
    setIsAuthDialogOpen(true);
  };

  const closeAuthDialog = (event: React.MouseEvent<HTMLInputElement>, reason: 'backdropClick') => {
    if (reason === 'backdropClick') {
      // バックドロップのクリックを無効化
      return;
    }
    setIsAuthDialogOpen(false);
  };

  // 遷移先URLの作成
  const generateNextURL = (endpoint: PageUrl.Endpoint, email: string) => {
    return PageUrl.generate(
      endpoint,
      {
        key: QUERY_KEYS.PUBLIC_ENTITY_CODE,
        value: publicEntityCode,
      },
      {
        key: QUERY_KEYS.USER_ID,
        value: userId,
      },
      {
        key: QUERY_KEYS.EMAIL,
        value: email,
      },
      {
        key: QUERY_KEYS.IS_CHANGE_RESERVATION,
        value: `${isChangeReservation}`,
      }
    );
  };

  // 予約変更ページを開始
  const startChangeReservation = useCallback(
    (athenticated: string) => {
      if (agentId == null) {
        sessionManager.updateValidityTime(userId, new Date());
      }
      setUserAuthenticated(athenticated);
    },
    [userId, agentId, sessionManager, setUserAuthenticated]
  );

  // iFrameから認証情報を受け取る
  const handler = useCallback(
    (event: MessageEvent<any>) => {
      console.log(event);
      if (event.data?.type !== 'userAuthenticated') {
        return;
      }
      if (event.data?.data == null) {
        return;
      }
      setIsAuthDialogOpen(false);
      startChangeReservation(event.data.data);
    },
    [setIsAuthDialogOpen, startChangeReservation]
  );

  useEffect(() => {
    window.addEventListener('message', handler);
    return () => {
      window.removeEventListener('message', handler);
    };
  }, [handler]);

  // 画面読み込み時の処理
  useEffect(() => {
    setIsChangeReservation(true);
    setIsWaiting(true);
    const run = async () => {
      try {
        // ユーザー情報取得
        const user =
          (await reservationUserUsecase.fetch(userId)) ?? User.create({ userId, mailadress: '' });
        if (user == null) {
          // 存在しないユーザー
          if (agentId == null) {
            navigate(ENDPOINT.RESERVATION_NOT_FOUND, { replace: true });
          } else {
            setSelectedView('reservation-not-found-view');
          }
          return;
        }

        // 予約情報取得
        const current = await reservationUsecase.fetch(user);
        if (current == null) {
          // 存在しない予約
          if (agentId == null) {
            navigate(ENDPOINT.RESERVATION_NOT_FOUND, { replace: true });
          } else {
            setSelectedView('reservation-not-found-view');
          }
          return;
        }

        if (current.isExpired() && agentId == null) {
          // 期限切れ
          navigate(ENDPOINT.RESERVATION_EXPRIED, { replace: true });
          return;
        }

        // 画面に表示
        setViewReservation({
          reservationNumber: current?.reservationNumber ?? '',
          location: current?.location ?? '',
          visitDateTime: current?.createViewDateTimeText() ?? '',
        });

        // 予約情報を保持
        setReservation(Reservation.copy(current));
        setPreReservation(Reservation.copy(current));

        // TODO: 後で設定で認証する/しないを変えられるようにする
        if (true) {
          // 予約変更ページを開始
          if (agentId == null) {
            // セッション追加
            sessionManager.updateToken(user.userId, user.userId);
          }
          startChangeReservation(user.userId);
        } else {
          // 未認証なら認証ダイアログを開く
          if (userAuthenticated == null) {
            openAuthDialog();
          }
        }
      } finally {
        setIsWaiting(false);
      }
    };
    run();
  }, [
    reservationUserUsecase,
    reservationUsecase,
    userId,
    agentId,
    sessionManager,
    userAuthenticated,
    navigate,
    setIsWaiting,
    setReservation,
    setPreReservation,
    setIsChangeReservation,
    setSelectedView,
    startChangeReservation,
  ]);

  return (
    <>
      {/* 予約情報 */}
      {userAuthenticated ? (
        <ChangeReservation viewReservation={viewReservation} />
      ) : (
        <Stack justifyContent="center" alignItems="center">
          <NowLoading isLoading={true} />
        </Stack>
      )}

      {/* 認証ダイアログ */}
      <CommonDialog
        open={isAuthDialogOpen}
        onClose={closeAuthDialog}
        content={
          reservation?.user?.mailadress && (
            <Stack width="100%" height="420px" justifyContent="center" alignItems="center">
              <iframe
                title="reservation"
                width="100%"
                height="100%"
                src={generateNextURL(ENDPOINT.AUTHETICATION, reservation.user.mailadress)}
              ></iframe>
            </Stack>
          )
        }
      />
    </>
  );
};

const ChangeReservation = ({ viewReservation }: { viewReservation: ReservationViewModel }) => {
  const buttonWidth = 220;
  const container = useContainer();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // 代理予約者
  const { agentId } = useAgentIdContext();

  // 管理画面の表示内容
  const { setSelectedView } = useSelectedManagementPageViewContext();

  // 読み込み中
  const { setIsWaiting } = useIsWaitingContext();

  // 自治体コードとユーザーIDを取得
  const publicEntityCode = searchParams.get(QUERY_KEYS.PUBLIC_ENTITY_CODE) ?? '';
  const userId = searchParams.get(QUERY_KEYS.USER_ID) ?? '';

  // 予約変更画面のURLを作成
  const publicEntityCodeQuery = { key: QUERY_KEYS.PUBLIC_ENTITY_CODE, value: publicEntityCode };
  const userIdQuery = { key: QUERY_KEYS.USER_ID, value: userId };
  const changeNextPageUrl = PageUrl.generate(
    ENDPOINT.RESERVATION,
    publicEntityCodeQuery,
    userIdQuery
  );

  const reservationUsecase = useMemo(
    () => container.get<ReservationUsecase>(TYPES.Reservation),
    [container]
  );

  const { reservation } = useReservationContext();

  // 遷移先URLを作成
  const generateURL = useCallback(
    (path: PageUrl.Endpoint) => {
      // リクエストパラメータを作成
      const publicEntutyCodeQuery = { key: QUERY_KEYS.PUBLIC_ENTITY_CODE, value: publicEntityCode };
      const userIdQuery = { key: QUERY_KEYS.USER_ID, value: userId };

      return PageUrl.generate(path, publicEntutyCodeQuery, userIdQuery);
    },
    [publicEntityCode, userId]
  );

  // 予約取り消し確認ダイアログ表示
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openDialog = () => {
    setIsDialogOpen(true);
  };
  const closeDialog = () => {
    setIsDialogOpen(false);
  };
  const onCancel = () => {
    const reservationNumber = reservation?.reservationNumber ?? '';
    setIsWaiting(true);

    reservationUsecase
      .cancel(reservationNumber)
      .then((result) => {
        if (result) {
          if (agentId == null) {
            navigate(generateURL(ENDPOINT.RESERVATION_CANCEL_CONFIRM), { replace: true });
          } else {
            setSelectedView('reservation-cancel-confirm-view');
          }
        } else {
          alert(ErrorHandler.getErrorMessage(ErrorHandler.CODE.FAILED_RESERVATION));
        }
      })
      .finally(() => {
        setIsWaiting(false);
        closeDialog();
      });
  };

  return (
    <>
      {/* 予約内容確認 */}
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={8}>
        <Stack className="mt-4" justifyContent="center" alignItems="center">
          <div> 以下の内容で、来庁予約されております。</div>
        </Stack>
        <Stack className="w-4/5 max-w-md" spacing={1}>
          <InputLabel>予約番号</InputLabel>
          <TextField
            className="bg-blue-200"
            fullWidth
            value={viewReservation.reservationNumber}
            InputProps={{
              readOnly: true,
            }}
          />

          <InputLabel>来庁場所</InputLabel>
          <TextField
            className="bg-blue-200"
            fullWidth
            value={viewReservation.location}
            InputProps={{
              readOnly: true,
            }}
          />

          <InputLabel>来庁日時</InputLabel>
          <TextField
            className="bg-blue-200"
            fullWidth
            value={viewReservation.visitDateTime}
            InputProps={{
              readOnly: true,
            }}
          />
        </Stack>
        <Stack
          className="w-full"
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Stack
            className="w-full flex-wrap"
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <ExecuteButton
              className="mx-2 my-1"
              text="予約を取り消す"
              onClick={openDialog}
              isPrimary={false}
              width={buttonWidth}
            />
            <ExecuteButton
              className="mx-2 my-1"
              text="別の日時に変更する"
              onClick={() => {
                if (agentId == null) {
                  navigate(changeNextPageUrl, { replace: true });
                } else {
                  setSelectedView('reservation-view');
                }
              }}
              isPrimary={true}
              type="button"
              width={buttonWidth}
            />
          </Stack>
          {agentId && (
            <Stack
              className="w-full flex-wrap"
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <ExecuteButton
                className="mx-2 my-1"
                text="戻る"
                onClick={() => setSelectedView('reservation-serch-view')}
                isPrimary={false}
                width={buttonWidth}
              />
            </Stack>
          )}
        </Stack>
      </Stack>

      {/* キャンセルダイアログ */}
      <CommonDialog
        open={isDialogOpen}
        onClose={closeDialog}
        content={
          <ReserveCancel
            onCancel={onCancel}
            onNoCancel={closeDialog}
            visitDateTime={viewReservation.visitDateTime}
          />
        }
      />
      {/* セッション管理 */}
      {agentId == null && <SessionControl />}
    </>
  );
};

export default ChangeReservationPage;
