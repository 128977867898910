import { InputLabel, Stack, TextField } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import ExecuteButton from '../../molecule/execute-button';
import { useContainer } from 'inversify-react';
import PreApplicationUsecase from '../../../../libs/usecases/interfaces/pre-application.usecase.interface';
import { TYPES } from '../../../../types';
import { useIsWaitingContext } from '../../../contexts/is-waiting-context';
import { useAgentIdContext } from '../../../contexts/agent-id-context';
import { useSelectedManagementPageViewContext } from '../../../contexts/selected-managemaent-page-view-context';
import { ErrorHandler } from '../../../../libs/domains/services/error-handler';
import { useSearchParams } from 'react-router-dom';
import { PageUrl } from '../../../../libs/domains/services/page-url';
import User from '../../../../libs/domains/value-objects/user';
import { buttonWidth } from '../../../styles/size';
const { QUERY_KEYS } = PageUrl;

const CreateReservationAgentPage = () => {
  const container = useContainer();

  const [searchParams] = useSearchParams();
  const publicEntityCode = searchParams.get(QUERY_KEYS.PUBLIC_ENTITY_CODE) ?? '';

  const preApplicationUsecase = useMemo(
    () => container.get<PreApplicationUsecase>(TYPES.PreApplication),
    [container]
  );

  // 管理画面の表示内容
  const { setSelectedView } = useSelectedManagementPageViewContext();

  // 代理予約者
  const { setAgentId } = useAgentIdContext();

  // 代理予約必須事項
  const [name, setName] = useState<string>('');
  const [phoneNumnber, setPhoneNumnber] = useState<string>('');
  const [isPhoneNumber, setIsPhoneNumber] = useState<boolean>(false);

  // 読み込み中
  const { setIsWaiting } = useIsWaitingContext();

  const handleInputPhoneNumber = (event: any) => {
    const inputPhoneNumber = event.target.value;
    if (/^[0-9-]*$/.test(inputPhoneNumber)) {
      setPhoneNumnber(inputPhoneNumber);
    } else {
      // 入力を無効にする
      setPhoneNumnber((prev) => prev);
    }
  };

  // 電話番号かどうかチェック
  useEffect(() => {
    setIsPhoneNumber(
      /^\d{1,5}-\d{1,4}-\d{3,5}$/.test(phoneNumnber) || /^0\d{9,10}$/.test(phoneNumnber)
    );
  }, [phoneNumnber]);

  // 代理予約開始
  const onClick = async () => {
    setIsWaiting(true);
    // 事前申請を登録
    try {
      const id = User.generateAgentId();
      if (!(await preApplicationUsecase.addAgent(publicEntityCode, id, name, phoneNumnber))) {
        alert(ErrorHandler.getErrorMessage(ErrorHandler.CODE.FAILED_START_PROXY_RESERVATION));
        return;
      }
      setAgentId(id);
      setSelectedView('reservation-view');
    } finally {
      setIsWaiting(false);
    }
  };

  // 代理予約開始の時点で代理予約者がいない状態にする
  useEffect(() => {
    setAgentId(null);
  }, [setAgentId]);

  return (
    <>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={8}
        component="form"
        noValidate
      >
        <Stack
          className="mt-8"
          width="100%"
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={4}
          component="form"
          noValidate
        >
          <Stack spacing={1} justifyContent="center" alignItems="center">
            <div>代理予約を開始します。</div>
            <div>来庁者氏名と電話番号を入力してください。</div>
          </Stack>

          <Stack className="w-3/5 max-w-md" spacing={1}>
            <InputLabel>来庁者氏名</InputLabel>
            <TextField fullWidth onChange={(event) => setName(event.target.value)} />
            <InputLabel>電話番号</InputLabel>
            <TextField
              fullWidth
              error={!isPhoneNumber}
              value={phoneNumnber}
              onChange={handleInputPhoneNumber}
              helperText={
                !isPhoneNumber &&
                '電話番号の形式で入力してください。例) 010-2234-2212 or 01022342212'
              }
            />
          </Stack>
        </Stack>
        <Stack
          className="w-full"
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={6}
        >
          <ExecuteButton
            text="代理予約開始"
            onClick={onClick}
            type="button"
            isPrimary={true}
            width={buttonWidth}
            isDisabled={name === '' || phoneNumnber === '' || !isPhoneNumber}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default CreateReservationAgentPage;
