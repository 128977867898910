import { Observable, BehaviorSubject } from 'rxjs';
import { injectable } from 'inversify';
import NoticeServiceUsecase from '../../usecases/interfaces/notice-service.usecase.interface';
import Notice, { NoticeType } from '../../domains/entitis/notice';
import { HttpRequest } from '../../utils/http-request';
import { ErrorHandler } from '../../domains/services/error-handler';

interface StaffResponse {
  id: string;
}

interface NoticesResponse {
  id: number;
  noticeType: string;
  contents: string;
  deleteDate: string;
  read: boolean;
  reservationNumber: string;
  isDone?: boolean;
}

@injectable()
export default class NoticeServiceRepository implements NoticeServiceUsecase {
  private _notices: Notice[] = [];

  // 職員ID
  private _staffId: string | null = null;
  // 自治体コード
  private _publicEntityCode: string | null = null;
  // 周期タイマー
  private _timer: number | null = null;

  // 取得周期時間
  private readonly _cycleTime = 5000;

  // 配信用
  private readonly _noticesSubject = new BehaviorSubject<Notice[]>([]);
  get notice$(): Observable<Notice[]> {
    return this._noticesSubject.asObservable();
  }

  start(id: string, entityCode: string): void {
    this._staffId = id;
    this._publicEntityCode = entityCode;
    this._notices = [];
    this.publish().then(() => {
      this._timer = window.setInterval(async () => {
        await this.publish();
      }, this._cycleTime);
    });
  }

  /**
   * 条件に一致する場合
   */
  private async publish(): Promise<void> {
    if (this._staffId == null || this._publicEntityCode == null) {
      return;
    }

    // 通知を取得
    const requestUrl = HttpRequest.generateUrl('notice');
    HttpRequest.addUrlParamater(requestUrl, 'public-entity-code', this._publicEntityCode);
    HttpRequest.addUrlParamater(requestUrl, 'staff-id', this._staffId);
    const response = await HttpRequest.requestGet<NoticesResponse[]>(requestUrl);
    if (ErrorHandler.isErrorResponse(response.status)) {
      return;
    }
    if (ErrorHandler.isErrorResponseCode(response.data.code) || response.data?.data == null) {
      return;
    }

    // 変換
    this._notices = response.data.data
      ?.map((notice) => {
        return Notice.create({
          id: notice.id,
          type: notice.noticeType as NoticeType,
          contents: notice.contents,
          deleteDate: new Date(notice.deleteDate),
          isRead: notice.read,
          reservationNumber: notice.reservationNumber,
          isDone: notice.isDone ?? false,
        });
      })
      .sort((a, b) => b.id - a.id);

    // 配信
    this._noticesSubject.next(this._notices);
  }

  stop(): void {
    this._notices = [];
    this._noticesSubject.next([]);
    this._staffId = null;
    this._publicEntityCode = null;
    if (this._timer != null) {
      window.clearInterval(this._timer);
      this._timer = null;
    }
  }

  async add(
    publicEntityCode: string,
    type: string,
    contents: string,
    deleteDate: string,
    reservationNumber: string
  ): Promise<boolean> {
    // 職員のID一覧を作成
    const requestUrl = HttpRequest.generateUrl('staff');
    const responseStaff = await HttpRequest.requestGet<StaffResponse[]>(requestUrl);
    if (responseStaff.status !== 200) {
      return false;
    }
    if (responseStaff.data.code !== 0) {
      return false;
    }
    const staffList = responseStaff.data?.data?.map((staff) => staff.id) ?? [];

    // 通知を作成
    const response = await HttpRequest.requestPost<null>(HttpRequest.generateUrl(`notice`), {
      publicEntityCode,
      noticeType: type,
      contents,
      deleteDate,
      reservationNumber,
      staffList,
    });

    return !(
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    );
  }

  async doneToggle(isDone: boolean, id: number): Promise<void> {
    // ステータス（完了、未完）を更新
    await HttpRequest.requestPut<null>(HttpRequest.generateUrl(`notice`), {
      publicEntityCode: this._publicEntityCode,
      id,
      isDone,
    });
    // 即時更新
    await this.publish();
  }

  readUnreadCount(): number {
    const count = this._noticesSubject.value.reduce((prev, item) => {
      return prev + (item.isRead ? 0 : 1);
    }, 0);
    return count;
  }
  async doMarkAllAsRead(): Promise<boolean> {
    if (this._staffId == null || this._publicEntityCode == null) {
      return false;
    }

    // 全部既読にする
    const response = await HttpRequest.requestPut<null>(HttpRequest.generateUrl(`read-notice`), {
      publicEntityCode: this._publicEntityCode,
      staffId: this._staffId,
    });
    if (
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    ) {
      return false;
    }

    // 完全同期ではないが、一旦ローカルを更新しておく
    this._notices = this._notices.map((notice) => {
      notice.isRead = true;
      return notice;
    });
    this._noticesSubject.next(this._notices);
    return true;
  }
}
