import React from 'react';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { useContainer } from 'inversify-react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Stack, TextField } from '@mui/material';

import { TYPES } from '../../../../types';
import { Validation } from '../../../../libs/domains/services/validation';
import ExecuteButton from '../../molecule/execute-button/ExecuteButton';
import ReservationUserUsecase from '../../../../libs/usecases/interfaces/reservation-user.usecase.interface';
import { PageUrl } from '../../../../libs/domains/services/page-url';
import { useIsWaitingContext } from '../../../contexts/is-waiting-context';
import { ErrorHandler } from '../../../../libs/domains/services/error-handler';
import ProcedureCaution from '../../organism/procedure-caution/ProcedureCaution';
import { ToParent } from '../../../../libs/domains/services/to-parent';

const { QUERY_KEYS, ENDPOINT } = PageUrl;

const InputEmailPage = () => {
  const navigate = useNavigate();

  // 読み込み中
  const { setIsWaiting } = useIsWaitingContext();

  // 自治体コードを取得
  const [searchParams] = useSearchParams();
  const publicEntityCode = searchParams.get(QUERY_KEYS.PUBLIC_ENTITY_CODE) ?? '';
  // 不正なリクエストパラメータ
  if (publicEntityCode === '') {
    navigate(ENDPOINT.NOT_FOUND, { replace: true });
  }

  // ステータス保持
  const container = useContainer();
  const reservationUserUsecase = container.get<ReservationUserUsecase>(TYPES.ReservationUser);

  // 入力内容のチェック
  const { control, handleSubmit } = useForm<Validation.EmailInputs>(Validation.DEFAULTVALUES.email);

  // 遷移先URLの作成
  const generateNextURL = (endpoint: PageUrl.Endpoint, userId: string, email: string) => {
    return PageUrl.generate(
      endpoint,
      {
        key: QUERY_KEYS.PUBLIC_ENTITY_CODE,
        value: publicEntityCode,
      },
      {
        key: QUERY_KEYS.USER_ID,
        value: userId,
      },
      {
        key: QUERY_KEYS.EMAIL,
        value: email,
      }
    );
  };

  // ユーザー新規作成
  const onSubmit: SubmitHandler<Validation.EmailInputs> = (data: Validation.EmailInputs) => {
    setIsWaiting(true);
    reservationUserUsecase
      .add(data.email, publicEntityCode)
      .then((userId) => {
        if (userId === '') {
          throw new Error();
        }
        navigate(generateNextURL(ENDPOINT.AUTHETICATION, userId, data.email), { replace: true });
      })
      .catch(() => {
        alert(ErrorHandler.getErrorMessage(ErrorHandler.CODE.FAILED_USER_SIGNUP));
      })
      .finally(() => setIsWaiting(false));
  };

  // 直前のページに戻る処理
  const onCancel = () => {
    // 親にユーザーIDがとれなかったことを通知する
    ToParent.sendUserId();
  };

  return (
    <>
      <Stack
        className="h-screen w-screen py-2"
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack className="w-5/6 max-w-md" spacing={3}>
          <Stack justifyContent="flex-start" alignItems="flex-start">
            <div>ご家族がお亡くなりになった後の市役所での</div>
            <div>必要な手続きについて、1か所の窓口で案内する</div>
            <div>「おくやみ窓口」を開設しています(事前予約制)。</div>
          </Stack>
          <div>予約を行う場合は、メールアドレスを入力してください。</div>
          <Stack className="relative w-full" spacing={1}>
            <Controller
              name={Validation.NAMES.email}
              control={control}
              rules={Validation.RULES.email}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  fullWidth
                  label="メールアドレス"
                  placeholder="xxx@example.com"
                  focused
                />
              )}
            />
            <Stack
              className="w-full text-xs"
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <div className="text-red-500">
                ※発行した予約番号を送付するためにメールアドレスの入力が必要です。
              </div>
            </Stack>
          </Stack>
        </Stack>

        <ExecuteButton
          className="mx-2 my-1"
          text="メールアドレスを送信する"
          isPrimary={true}
          applyResponsiveWidthStyle={true}
        />

        <Stack>
          <div className={`mx-4 mb-4`}>
            現在の予約状況は
            <Link
              className="text-blue-600 hover:text-blue-900"
              to={PageUrl.generate(ENDPOINT.VIEW_RESERVATION_STATUS, {
                key: QUERY_KEYS.PUBLIC_ENTITY_CODE,
                value: publicEntityCode,
              })}
              target="_blank"
            >
              こちら
            </Link>
          </div>
          <Stack>
            <Stack direction="column" justifyContent="flex-start" alignItems="flex-start">
              <div className={`mx-4`}>
                <div>・守山市在住の方が亡くなられた場合の手続きが対象です。</div>
                <div>・お亡くなりになられてから、7日後以降のご予約をおすすめしています。</div>
              </div>
            </Stack>
            <ProcedureCaution />
          </Stack>
        </Stack>
        <Stack direction="column" justifyContent="center" alignItems="center" className="w-screen">
          <ExecuteButton
            className="mx-2"
            text="予約を行わず、手続きの案内にすすむ"
            onClick={onCancel}
            isPrimary={false}
            type="button"
            applyResponsiveWidthStyle={true}
          />
          <div className="text-sm my-3">
            <div>{'〇来庁予約を行わず質問事項に回答し、'}</div>
            <div>{'　必要な手続き、持ち物の案内をすることも可能です。'}</div>
          </div>
        </Stack>
      </Stack>
    </>
  );
};

export default InputEmailPage;
