import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField } from '@mui/material';
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';
import { TYPES } from '../../../../types';
import SettingsLocationUsecase from '../../../../libs/usecases/interfaces/settings-location.usecase.interface';
import { PageUrl } from '../../../../libs/domains/services/page-url';
import { useIsWaitingContext } from '../../../contexts/is-waiting-context';
import { useContainer } from 'inversify-react';
import ExecuteButton from '../../molecule/execute-button';
import ReservationUsecase from '../../../../libs/usecases/interfaces/reservation.usecase.interface';
import ReservationDetailPage from '../reservation-detail-page';
import { useAgentIdContext } from '../../../contexts/agent-id-context';
import CommonDialog from '../../molecule/common-dialog';
import { useReactToPrint } from 'react-to-print';
import { buttonWidth, dateTimePickerWidth } from '../../../styles/size';
import { TimeControl } from '../../../../libs/utils/time-control';
import { useReservationContext } from '../../../contexts/revervation-context';
import { useReservationSearchConditionContext } from '../../../contexts/revervation-search-condition-context';
import { ReservationSearchCondition } from '../../../../libs/domains/services/reservation-search-condition';
const { QUERY_KEYS } = PageUrl;

/**
 * 表の行
 */
interface RowType {
  id: string;
  location: string;
  visitDateTime: string;
  name: string;
}

const ReservationSearchPage = () => {
  const container = useContainer();
  const [searchParams] = useSearchParams();

  // 列ヘッダーの情報
  const columns: GridColDef[] = [
    { field: 'id', headerName: '予約番号', width: 120 },
    { field: 'location', headerName: '来庁場所', width: 150 },
    { field: 'visitDateTime', headerName: '来庁日時', width: 250 },
    { field: 'name', headerName: '来庁者氏名', width: 170 },
  ];

  // 自治体コードを取得
  const publicEntityCode = searchParams.get(QUERY_KEYS.PUBLIC_ENTITY_CODE) ?? '';

  // 読み込み中
  const { setIsWaiting } = useIsWaitingContext();

  // 詳細画面表示
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // 予約枠表示データ
  const [viewRows, setViewRows] = useState<RowType[]>([]);

  // 選択された予約の番号
  const [selectedReservationNumber, setSelectedReservationNumber] = useState('');

  // 代理予約者
  const { setAgentId } = useAgentIdContext();

  // 予約情報
  const { reservation } = useReservationContext();

  // 予約詳細画面を開く
  const openDetailView = (id: string) => {
    setSelectedReservationNumber(id);
    setIsOpen(true);
  };

  // 予約をクリック時の処理
  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    openDetailView(params.row.id);
  };

  // データ無し時のメッセージ
  const defaultNoRowsMessage = '検索条件を入力して検索してください。';
  const [noRowsMessage, setNoRowsMessage] = useState<string>(defaultNoRowsMessage);

  // 来庁場所（検索条件）
  const settingsLocationUsecase = useMemo(
    () => container.get<SettingsLocationUsecase>(TYPES.SettingsLocation),
    [container]
  );
  const [locations, setLocations] = useState<string[]>([]);
  const [location, setLocation] = useState<string>('');

  // 時間範囲（検索条件）
  const [dateFrom, setDateFrom] = useState<string>('');
  const [dateTo, setDateTo] = useState<string>('');

  // 予約番号（検索条件）
  const [reservationNumber, setReservationNumber] = useState<string>('');

  // 検索条件の画面間連携
  const { reservationSearchCondition, setReservationSearchCondition } =
    useReservationSearchConditionContext();

  // 予約情報
  const reservationUsecase = useMemo(
    () => container.get<ReservationUsecase>(TYPES.Reservation),
    [container]
  );

  // 印刷ダイアログ表示
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openAuthDialog = () => {
    setIsDialogOpen(true);
  };
  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  // 印刷処理
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: '[予約システム]: 予約者一覧',
    pageStyle: '@page { size: A4; margin: 0; }',
  });

  // 検索処理
  const onSearch = useCallback(
    async (reservationSearchCondition?: ReservationSearchCondition) => {
      const searchLocation = reservationSearchCondition?.location ?? location;
      const searchDateFrom = reservationSearchCondition?.dateFrom ?? dateFrom;
      const searchDateTo = reservationSearchCondition?.dateTo ?? dateTo;
      const searchReservationNumber =
        reservationSearchCondition?.reservationNumber ?? reservationNumber;

      const dateRange = {
        dateFrom: searchDateFrom === '' ? undefined : new Date(searchDateFrom + 'T00:00:00'),
        dateTo: searchDateTo === '' ? undefined : new Date(searchDateTo + 'T23:59:00'),
      };

      const fillter = {
        location: searchLocation === '' ? undefined : searchLocation,
        dateRange,
        reservationNumber: searchReservationNumber === '' ? undefined : searchReservationNumber,
      };

      setIsWaiting(true);
      const reservations = await reservationUsecase
        .fetchList(publicEntityCode, fillter)
        .catch(() => []);
      const views: RowType[] = reservations.map((reservation) => {
        return {
          id: reservation.reservationNumber ?? '',
          location: reservation.location,
          name: reservation.preApplication?.userName ?? '',
          visitDateTime: reservation.createViewDateTimeText(),
        };
      });
      setNoRowsMessage(
        views.length > 0 ? defaultNoRowsMessage : '指定の条件には予約がありません。'
      );
      setViewRows(views);
      setIsWaiting(false);

      // 検索情報保持
      setReservationSearchCondition({
        location,
        dateFrom,
        dateTo,
        reservationNumber,
      });
    },
    [
      dateFrom,
      dateTo,
      location,
      publicEntityCode,
      reservationNumber,
      reservationUsecase,
      setIsWaiting,
      setReservationSearchCondition,
    ]
  );

  // 来庁場所変更時の処理
  const handleLocationChange = (event: SelectChangeEvent) => {
    setLocation(event.target.value as string);
  };

  // 予約一覧の作成
  const createlist = (height?: number) => {
    const width = 725;

    return (
      <div style={{ height, width }}>
        <DataGrid
          rows={viewRows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: height == null ? 18 : 5 },
            },
          }}
          pageSizeOptions={height == null ? [18] : [5, 10, 25, 50, 100]}
          rowBuffer={height == null ? 18 : 3}
          onRowClick={handleRowClick}
          slots={{
            noRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                {noRowsMessage}
              </Stack>
            ),
          }}
        />
      </div>
    );
  };

  // 画面初期化処理
  useEffect(() => {
    if (locations.length > 0) {
      // 初期化済みの場合は何もしない
      return;
    }

    const run = async () => {
      // 詳細表示取得
      if (reservation?.reservationNumber != null) {
        openDetailView(reservation.reservationNumber);
      }

      // 来庁場所一覧取得
      setIsWaiting(true);
      await settingsLocationUsecase.fetch(publicEntityCode).catch(() => []);
      const readlocations = settingsLocationUsecase.read();
      readlocations.unshift('');
      setLocations(readlocations);
      setIsWaiting(false);

      if (reservationSearchCondition != null) {
        setLocation(reservationSearchCondition.location);
        setDateFrom(reservationSearchCondition.dateFrom);
        setDateTo(reservationSearchCondition.dateTo);
        setReservationNumber(reservationSearchCondition.reservationNumber);
        // 検索処理
        await onSearch(reservationSearchCondition);
      }
    };
    run();
  }, [
    setIsWaiting,
    setLocations,
    setLocation,
    onSearch,
    settingsLocationUsecase,
    publicEntityCode,
    reservation,
    locations,
    reservationSearchCondition,
  ]);

  // 代理予約する可能性があるので画面遷移直後に初期化
  useEffect(() => {
    setAgentId(null);
  }, [setAgentId]);

  return isOpen ? (
    <>
      {/* 予約内容詳細画面 */}
      <ReservationDetailPage
        reservationNumber={selectedReservationNumber}
        onReturn={() => {
          setIsOpen(false);
        }}
      />
    </>
  ) : (
    <>
      {/* 予約検索画面 */}
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
        <Stack className="w-3/5 max-w-md">
          <InputLabel>来庁場所</InputLabel>
          <Select value={location} onChange={handleLocationChange}>
            {locations.map((location, index) => (
              <MenuItem key={index} value={location}>
                {location}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        <Stack className="w-3/5 max-w-md">
          <InputLabel>日付</InputLabel>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <TextField
              id="date-from"
              type="date"
              onChange={(event: any) => setDateFrom(event.target.value)}
              defaultValue={dateFrom}
              sx={{ width: dateTimePickerWidth }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div>～</div>
            <TextField
              id="date-to"
              type="date"
              onChange={(event: any) => setDateTo(event.target.value)}
              defaultValue={dateTo}
              sx={{ width: dateTimePickerWidth }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Stack>
        </Stack>
        <Stack className="w-3/5 max-w-md">
          <InputLabel>予約番号</InputLabel>
          <TextField
            defaultValue={reservationNumber}
            onChange={(event: any) => setReservationNumber(event.target.value)}
          />
        </Stack>

        <ExecuteButton
          text="検索"
          onClick={onSearch}
          isPrimary={true}
          type="submit"
          width={buttonWidth}
        />
        {createlist(400)}
        <ExecuteButton
          text="一覧を印刷する"
          onClick={openAuthDialog}
          isPrimary={false}
          type="submit"
          width={buttonWidth}
          isDisabled={viewRows.length <= 0}
        />
        <Stack className="pb-8 pt-4" justifyContent="center" alignItems="center">
          <div>{`${TimeControl.createGoBackInDateText(7)}以前の予約情報が本日削除されます。`}</div>
          <div>保管したい場合は、事前に出力作業をお願い致します。</div>
        </Stack>
      </Stack>

      <CommonDialog
        open={isDialogOpen}
        isSlideIn={true}
        onClose={closeDialog}
        content={
          <Stack
            className="h-full w-full"
            justifyContent="flex-start"
            alignItems="center"
            spacing={3}
          >
            <Stack direction="row" spacing={6}>
              <ExecuteButton
                text="キャンセル"
                onClick={closeDialog}
                isPrimary={false}
                type="submit"
                width={buttonWidth}
              />
              <ExecuteButton
                text="印刷"
                width={buttonWidth}
                onClick={handlePrint}
                isPrimary={true}
                type="submit"
              />
            </Stack>
            <div>下記表示内容を印刷します。</div>
            <Stack
              className="p-4 pt-8"
              justifyContent="flex-start"
              alignItems="center"
              ref={componentRef}
            >
              {createlist()}
            </Stack>
          </Stack>
        }
      />
    </>
  );
};

export default ReservationSearchPage;
