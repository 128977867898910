import React, { ReactNode } from 'react';
import { Dialog, DialogTitle, DialogContent, ModalProps, Slide } from '@mui/material';
import style from './CommonDialog.module.scss';
import { TransitionProps } from '@mui/material/transitions';

interface PropsType {
  open: boolean; // ダイアログを開くかどうか
  isSlideIn?: boolean;
  onClose?: ModalProps['onClose']; // ダイアログを閉じるときの処理
  title?: string; // ダイアログのタイトル
  content: ReactNode; // ダイアログ
}

// スライドイン設定
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CommonDialog = ({ open, isSlideIn, onClose, title, content }: PropsType) => {
  return (
    <Dialog
      fullScreen={isSlideIn}
      open={open}
      onClose={onClose}
      TransitionComponent={isSlideIn ? Transition : undefined}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={style['dialog-content']}>{content}</DialogContent>
    </Dialog>
  );
};

export default CommonDialog;
