import React from 'react';
import { RouteObject } from 'react-router-dom';
import { PageUrl } from './libs/domains/services/page-url';
import { addOutsideFrame, addInsideFrame } from './views/utils/add-frame';
import App from './views/components/pages/app-page';
import InputEmailPage from './views/components/pages/input-email-page';
import AuthenticationPage from './views/components/pages/authentication-page';
import ReservationPage from './views/components/pages/reservation-page';
import ReservationEntryConfirmationPage from './views/components/pages/reservation-entry-confirmation-page';
import ReservationCompletionPage from './views/components/pages/reservation-completion-page';
import ChangeReservationPage from './views/components/pages/change-reservation-page';
import ViewReservationStatusPage from './views/components/pages/view-reservation-status-page';
import ManagementPage from './views/components/pages/management-page';
import NotFound404Page from './views/components/pages/not-found-404-page';
import DummyPreApplicationPage from './views/components/pages/dummy-pre-application-page';
import LoginPage from './views/components/pages/login-page';
import ReservationCancelConfirmPage from './views/components/pages/reservation-cancel-confirm-page';
import ReservationEditPage from './views/components/pages/reservation-edit-page';
import ReservationNotFoundPage from './views/components/pages/reservation-not-found-page';
import ReservationExpiredPage from './views/components/pages/reservation-expired-page';
import ReservationSessionTimeoutPage from './views/components/pages/reservation-session-timeout-page';

const { ENDPOINT } = PageUrl;

// 環境変数で公開可能なページを制御
const isPublishGeneral: boolean = process.env.REACT_APP_PUBLISH_GENERAL === 'enable';
const isPublishManagement: boolean = process.env.REACT_APP_PUBLISH_MANEGEMENT === 'enable';
const isPublisDebug: boolean = process.env.REACT_APP_PUBLISH_DEBUG === 'enable';

// 予約ページ一覧
const generalPages: RouteObject[] = [
  {
    path: ENDPOINT.INPUT_EMAIL,
    element: <InputEmailPage />,
  },
  {
    path: ENDPOINT.AUTHETICATION,
    element: <AuthenticationPage />,
  },
  {
    path: ENDPOINT.RESERVATION,
    element: addInsideFrame(<ReservationPage />),
  },
  {
    path: ENDPOINT.RESERVATION_EDIT,
    element: addInsideFrame(<ReservationEditPage />),
  },
  {
    path: ENDPOINT.RESERVATION_ENTRY_CONFIRMATION,
    element: addInsideFrame(<ReservationEntryConfirmationPage />),
  },
  {
    path: ENDPOINT.RESERVATION_COMPLETION,
    element: addInsideFrame(<ReservationCompletionPage />),
  },
  {
    path: ENDPOINT.CHANGE_RESERVATION,
    element: addInsideFrame(<ChangeReservationPage />),
  },
  {
    path: ENDPOINT.RESERVATION_NOT_FOUND,
    element: addInsideFrame(<ReservationNotFoundPage />),
  },
  {
    path: ENDPOINT.RESERVATION_EXPRIED,
    element: addInsideFrame(<ReservationExpiredPage />),
  },
  {
    path: ENDPOINT.RESERVATION_SESSION_TIMEOUT,
    element: addInsideFrame(<ReservationSessionTimeoutPage />),
  },
  {
    path: ENDPOINT.RESERVATION_CANCEL_CONFIRM,
    element: addInsideFrame(<ReservationCancelConfirmPage />),
  },
  {
    path: ENDPOINT.VIEW_RESERVATION_STATUS,
    element: addInsideFrame(<ViewReservationStatusPage />),
  },
];

// 管理ページ一覧
const manageMentPages: RouteObject[] = [
  {
    path: ENDPOINT.LOGIN,
    element: addInsideFrame(<LoginPage />),
  },
  {
    path: ENDPOINT.MANAGEMENT,
    element: <ManagementPage />,
  },
];

// デバッグページ一覧
const degugPages: RouteObject[] = [
  {
    path: ENDPOINT.ROOT,
    element: addOutsideFrame(<App />),
  },
  {
    path: ENDPOINT.DUMMY_PRE_APPLICATION,
    element: <DummyPreApplicationPage />,
  },
];

// 公開可能なページURLとコンポーネントを紐づけ
const pages: RouteObject[] = [
  {
    path: '/*',
    element: addInsideFrame(<NotFound404Page />),
  },
];
if (isPublishGeneral) {
  pages.push(...generalPages);
}
if (isPublishManagement) {
  pages.push(...manageMentPages);
}
if (isPublisDebug) {
  pages.push(...degugPages);
}

export default pages;
