import React, { useEffect, useState } from 'react';
import { Stack, TextField } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ExecuteButton from '../../molecule/execute-button';
import { usePreStaffInformationContext } from '../../../contexts/pre-staff-information-context';
import { Role, Staff } from '../../../../libs/domains/entitis/staff';
import moment from 'moment';
import { useNewStaffInformationContext } from '../../../contexts/new-staff-information-context';
import { useSearchParams } from 'react-router-dom';
import { PageUrl } from '../../../../libs/domains/services/page-url';

const { QUERY_KEYS } = PageUrl;
interface PropsType {
  onCancel: () => void; // 取り消した場合の動作
  onSave: () => void; // 保存した場合の動作
  isEdit: boolean;
}

const StaffCreate = ({ onCancel, onSave, isEdit }: PropsType) => {
  const [searchParams] = useSearchParams();
  // 自治体コードを取得
  const publicEntityCode = searchParams.get(QUERY_KEYS.PUBLIC_ENTITY_CODE) ?? '';

  const { preStaff } = usePreStaffInformationContext();
  const { setNewStaff } = useNewStaffInformationContext();
  const width = 320;
  const preStaffPermission: Role = preStaff?.permission ?? 'general';
  const buttonWidth = 200;
  // ログインID
  const [loginId, setLoginId] = useState<string>(preStaff?.loginId ?? '');
  // パスワード
  const [password, setPassword] = useState<string>(preStaff?.password ?? '');
  // 権限
  const [permission, setPermission] = useState<Role>(preStaffPermission);
  // 有効期限開始日時
  const [expirationFrom, setExpirationFrom] = useState<string>(
    preStaff == null
      ? moment(new Date()).format('YYYY-MM-DD')
      : preStaff.createExpirationFromDateViewText()
  );
  // 有効期限終了日時
  const [expirationTo, setExpirationTo] = useState<string>(
    preStaff == null
      ? moment(new Date()).add(30, 'days').format('YYYY-MM-DD')
      : preStaff.createExpirationToDateViewText()
  );

  const handleChange = (event: SelectChangeEvent) => {
    setPermission(event.target.value as Role);
  };

  useEffect(() => {
    setNewStaff(
      Staff.create({
        userName: Staff.generateUserName(publicEntityCode, loginId),
        password: password,
        permission: permission,
        publicEntityCode: publicEntityCode,
        expirationFrom: new Date(expirationFrom),
        expirationTo: new Date(expirationTo),
      })
    );
  }, [setNewStaff, loginId, password, publicEntityCode, permission, expirationFrom, expirationTo]);

  return (
    <>
      <Stack justifyContent="flex-start" alignItems="center" spacing={2}>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Stack className="grow-0 w-20">ID</Stack>
          <TextField
            className="grow"
            sx={{ width: width }}
            id="id"
            defaultValue={loginId}
            onChange={(event: any) => setLoginId(event.target.value)}
            disabled={isEdit}
          />
        </Stack>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Stack className="grow-0 w-20">パスワード</Stack>
          <TextField
            className="grow"
            sx={{ width: width }}
            id="password"
            defaultValue={password}
            onChange={(event: any) => setPassword(event.target.value)}
          />
        </Stack>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Stack className="grow-0 w-20">権限</Stack>
          <Select
            sx={{ width: width }}
            labelId="demo-simple-select-label"
            id="permission"
            value={permission}
            onChange={handleChange}
          >
            <MenuItem value={'general'}>general</MenuItem>
            <MenuItem value={'administrator'}>administrator</MenuItem>
          </Select>
        </Stack>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Stack className="grow-0 w-20">開始日</Stack>
          <TextField
            className="grow"
            sx={{ width: width }}
            id="expirationDateStartTime"
            type="date"
            defaultValue={expirationFrom}
            onChange={(event: any) => setExpirationFrom(event.target.value)}
          />
        </Stack>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Stack className="grow-0 w-20">終了日</Stack>
          <TextField
            className="grow"
            sx={{ width: width }}
            id="expirationDateEndTime"
            type="date"
            defaultValue={expirationTo}
            onChange={(event: any) => setExpirationTo(event.target.value)}
          />
        </Stack>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <ExecuteButton
            text="キャンセル"
            onClick={onCancel}
            isPrimary={false}
            width={buttonWidth}
          />
          <ExecuteButton
            text="保存"
            isDisabled={!isEdit && (loginId === '' || password === '')}
            onClick={onSave}
            width={buttonWidth}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default StaffCreate;
