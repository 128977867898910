import { CircularProgress, Stack } from '@mui/material';
import React, { ReactNode } from 'react';

interface PropsType {
  isLoading: boolean;
  children?: ReactNode;
}
const WaitAMoment = ({ isLoading, children }: PropsType) => {
  return (
    <>
      {isLoading ? (
        <Stack>
          <CircularProgress color="inherit" />
        </Stack>
      ) : (
        children
      )}
    </>
  );
};

export default WaitAMoment;
