import { Role } from '../entitis/staff';

/**
 * ログイン中の職員
 */
export interface LoginStaff {
  loginId: string;
  userName: string;
  accessToken: string;
  refreshToken: string;
  permission: Role;
  updatetime: Date;
}

/**
 * トークンの更新が必要かどうか
 * @param updatetime 前に更新された時間
 */
export const needStaffTokenUpdate = (updatetime: Date) => {
  const now = new Date();
  const diff = now.getTime() - updatetime.getTime();

  // TODO: Cognitoのアクセストークンの有効期限に依存する（60分の場合なので45分を超えている場合にしている）
  return diff > 2700000;
  // return diff > 30000;
};
