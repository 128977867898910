import { LinearProgress, Stack } from '@mui/material';
import React, { ReactNode } from 'react';

interface PropsType {
  isLoading: boolean;
  children?: ReactNode;
}
const NowLoading = ({ isLoading, children }: PropsType) => {
  return (
    <>
      {isLoading ? (
        <Stack>
          <div>読み込み中</div>
          <LinearProgress />
        </Stack>
      ) : (
        children
      )}
    </>
  );
};

export default NowLoading;
