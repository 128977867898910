/**
 * 事前申請情報のプロパティ
 */
export interface PreApplicationProperties {
  userName: string;
  phoneNumber: string;
  procedureType: string;
  belongings: string;
  preApplicationDetail: string;
}

/**
 * 事前申請情報
 */
export default class PreApplication {
  private constructor(
    private readonly _userName: string, // 申請者名
    private readonly _phoneNumber: string, // 電話番号
    private readonly _procedureType: string, // 申請種別
    private readonly _belongings: string, // 持ち物
    private readonly _preApplicationDetail: string // 事前申請情報詳細
  ) {}

  /**
   * 同一オブジェクトとの比較
   * @param other 比較対象
   * @returns 一致:true、不一致:false
   */
  equals(other?: PreApplication | null): boolean {
    if (other == null) {
      return false;
    }
    return (
      this._userName === other.userName &&
      this._phoneNumber === other.phoneNumber &&
      this._procedureType === other.procedureType &&
      this._belongings === other.belongings
    );
  }

  /**
   * 申請者名
   */
  get userName(): string {
    return this._userName;
  }
  /**
   * 電話番号
   */
  get phoneNumber(): string {
    return this._phoneNumber;
  }
  /**
   * 申請種別
   */
  get procedureType(): string {
    return this._procedureType;
  }
  /**
   * 持ち物
   */
  get belongings(): string {
    return this._belongings;
  }
  /**
   * 事前申請情報詳細
   */
  get preApplicationDetail(): string {
    return this._preApplicationDetail;
  }

  /**
   * 新規作成
   */
  static create(properties: PreApplicationProperties) {
    return new PreApplication(
      properties.userName,
      properties.phoneNumber,
      properties.procedureType,
      properties.belongings,
      properties.preApplicationDetail
    );
  }

  /**
   * コピーの作成
   */
  static copy(previos: PreApplication) {
    return new PreApplication(
      previos.userName,
      previos.phoneNumber,
      previos.procedureType,
      previos.belongings,
      previos.preApplicationDetail
    );
  }
}
