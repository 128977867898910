import { Checkbox, Divider, Stack } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { TYPES } from '../../../../types';
import SettingsCounterClosingDaysUsecase from '../../../../libs/usecases/interfaces/settings-counter-closing-days.usecase.interface';
import { useContainer } from 'inversify-react';
import CounterClosingDay from '../../../../libs/domains/entitis/counter-closing-day';
import { useIsWaitingContext } from '../../../contexts/is-waiting-context';
import NowLoading from '../../molecule/now-loading';

interface PropsType {
  publicEntityCode: string;
  location: string;
  settings: CounterClosingDay[] | null;
  setSettings: Dispatch<SetStateAction<CounterClosingDay[] | null>>;
  setIsUpdate: Dispatch<SetStateAction<boolean>>;
}

const CounterClosingDaysSetting = ({
  publicEntityCode,
  location,
  settings,
  setSettings,
  setIsUpdate,
}: PropsType) => {
  const container = useContainer();

  // 窓口閉鎖と営業日
  const settingsCounterClosingDaysUsecase = useMemo(
    () => container.get<SettingsCounterClosingDaysUsecase>(TYPES.SettingsCounterClosingDays),
    [container]
  );

  // 読み込み中
  const { setIsWaiting } = useIsWaitingContext();

  // 曜日を選んだ時の動作
  const handleChangeClose = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    setSettings((prev) => {
      if (prev == null) {
        return prev;
      }
      const day = prev[index];
      if (day == null) {
        return prev;
      }
      prev[index].isCounterClose = event.target.checked;
      // 窓口解放日は営業日とする
      if (!event.target.checked) {
        prev[index].isBusinessDay = true;
      }
      setIsUpdate(true);
      return prev.map((day) => CounterClosingDay.copy(day));
    });
  };

  const handleChangeBusinessDay = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    setSettings((prev) => {
      if (prev == null) {
        return prev;
      }
      const day = prev[index];
      if (day == null) {
        return prev;
      }
      prev[index].isBusinessDay = event.target.checked;
      // 休業日は窓口閉鎖日とする
      if (!event.target.checked) {
        prev[index].isCounterClose = true;
      }
      setIsUpdate(true);
      return prev.map((day) => CounterClosingDay.copy(day));
    });
  };

  // 窓口閉鎖と営業日を取得する
  useEffect(() => {
    if (settings != null) {
      return;
    }
    setIsWaiting(true);
    settingsCounterClosingDaysUsecase
      .fetch(publicEntityCode, location)
      .then(() => {
        const data = settingsCounterClosingDaysUsecase.read();
        setSettings(data);
        setIsUpdate(false);
      })
      .catch(() => [])
      .finally(() => setIsWaiting(false));
  }, [
    location,
    publicEntityCode,
    settingsCounterClosingDaysUsecase,
    settings,
    setSettings,
    setIsWaiting,
    setIsUpdate,
  ]);

  return (
    <NowLoading isLoading={settings == null}>
      <Stack spacing={2} alignItems="center" justifyContent="center">
        <Stack className="text-sm">
          <div>窓口閉鎖日にチェックを入れると、</div>
          <div>開庁閉庁にかかわらず、窓口予約を受け付けない曜日となります。</div>
          <div>営業日は、予約の受付可否にかかわらず、</div>
          <div>開庁している曜日にチェックをするようにしてください。</div>
          <br />
          <div>窓口閉鎖日と営業日の両方にチェックがある場合、</div>
          <div>開庁しているが利用者からの予約を受け付けない状態となります。</div>
          <div>この場合、代理予約から職員様側での予約が可能となります。</div>
        </Stack>

        <Stack className="border-y" direction="row" spacing={1}>
          <Divider orientation="vertical" flexItem />
          <Stack>
            <br />
            <Stack alignItems="flex-start" justifyContent="center" className="p-1 py-3">
              窓口閉鎖
            </Stack>
            <Stack alignItems="flex-start" justifyContent="center" className="p-1 py-2">
              営業日
            </Stack>
          </Stack>

          <Divider orientation="vertical" flexItem />
          {settings?.map((addingWeekDay, index) => (
            <div key={index}>
              <Stack alignItems="center" justifyContent="center">
                <Stack className="p-1 pb-0 w-full" alignItems="center" justifyContent="center">
                  {addingWeekDay.dow}
                </Stack>
                <div className="p-1 py-0">
                  <Checkbox
                    checked={addingWeekDay.isCounterClose}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeClose(event, index)
                    }
                  />
                </div>
                <div className="p-1 py-0">
                  <Checkbox
                    checked={addingWeekDay.isBusinessDay}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeBusinessDay(event, index)
                    }
                  />
                </div>
              </Stack>
              <Divider orientation="vertical" flexItem />
            </div>
          ))}
          <Divider orientation="vertical" flexItem />
        </Stack>
      </Stack>
    </NowLoading>
  );
};

export default CounterClosingDaysSetting;
