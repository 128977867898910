import { createContext, ReactNode, useContext, useState, Dispatch, SetStateAction } from 'react';
import { LoginStaff } from '../../libs/domains/services/login-staff';

interface LoginStaffContextType {
  loginStaff: LoginStaff | null;
  setLoginStaff: Dispatch<SetStateAction<LoginStaff | null>>;
}

/**
 * 作成したコンテキストは一意の名称とする
 */
const loginStaffContext = createContext<LoginStaffContextType>({} as LoginStaffContextType);

/**
 * 外部から設定値を取り出す用
 */
export const useLoginStaffContext = () => useContext(loginStaffContext);

/**
 * コンテキストを提供する
 */
export const LoginStaffProvider = ({ children }: { children: ReactNode }) => {
  const [loginStaff, setLoginStaff] = useState<LoginStaff | null>(null);
  const { Provider } = loginStaffContext;

  const value = {
    loginStaff,
    setLoginStaff,
  };

  return <Provider value={value}>{children}</Provider>;
};
