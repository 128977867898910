import { Stack } from '@mui/material';
import React from 'react';
import ExecuteButton from '../../molecule/execute-button';

interface PropsType {
  onCancel: () => void; // キャンセル押下時の動作
  onOk: () => void; // OK押下時の動作
}

const StaffDelete = ({ onCancel, onOk }: PropsType) => {
  const buttonWidth = 300;
  return (
    <Stack className="w-full h-full" justifyContent="space-between" alignItems="center">
      <Stack className="w-full h-36" justifyContent="center" alignItems="center">
        削除しますか？
      </Stack>

      <Stack
        className="w-full"
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={6}
      >
        <ExecuteButton text="キャンセル" onClick={onCancel} isPrimary={false} width={buttonWidth} />
        <ExecuteButton text="OK" onClick={onOk} isPrimary={true} width={buttonWidth} />
      </Stack>
    </Stack>
  );
};

export default StaffDelete;
