import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { useIsWaitingContext } from '../../../contexts/is-waiting-context';

const WaitingSpin = () => {
  // 予約情報
  const { isWaiting } = useIsWaitingContext();

  return (
    <>
      {isWaiting && (
        <Backdrop open={isWaiting} style={{ zIndex: 9999, color: '#fff' }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  );
};
export default WaitingSpin;
