import { createContext, ReactNode, useContext, useState, Dispatch, SetStateAction } from 'react';
import Reservation from '../../libs/domains/entitis/reservation';

interface PreReservationContextType {
  preReservation: Reservation | null;
  setPreReservation: Dispatch<SetStateAction<Reservation | null>>;
}

/**
 * 作成したコンテキストは一意の名称とする
 */
const PreReservationContext = createContext<PreReservationContextType>(
  {} as PreReservationContextType
);

/**
 * 外部から設定値を取り出す用
 */
export const usePreReservationContext = () => useContext(PreReservationContext);

/**
 * コンテキストを提供する
 */
export const PreReservationProvider = ({ children }: { children: ReactNode }) => {
  const [preReservation, setPreReservation] = useState<Reservation | null>(null);
  const { Provider } = PreReservationContext;

  const value = {
    preReservation,
    setPreReservation,
  };

  return <Provider value={value}>{children}</Provider>;
};
