import 'reflect-metadata';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'inversify-react';
import { createTheme, ThemeProvider } from '@mui/material';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { rootContainer } from './inversify.config';
import reportWebVitals from './reportWebVitals';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import './index.scss';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import pages from './pages';
import WaitingSpin from './views/components/organism/waiting-spin/WaitingSpin';
import buildProvidersTree from './views/utils/build-providers-tree';

// コンテンツプロバイダー
import { ReservationProvider } from './views/contexts/revervation-context';
import { IsWaitingProvider } from './views/contexts/is-waiting-context';
import { IsChangeReservationProvider } from './views/contexts/is-change-reservation-context';
import { UserAuthenticatedProvider } from './views/contexts/user-authenticated-context';
import { PreReservationProvider } from './views/contexts/pre-revervation-context';
import { LoginStaffProvider } from './views/contexts/login-staff-context';
import { PreStaffInformationProvider } from './views/contexts/pre-staff-information-context';
import { SelectedManagementPageViewStaffProvider } from './views/contexts/selected-managemaent-page-view-context';
import { AgentIdProvider } from './views/contexts/agent-id-context';
import { NewStaffInformationProvider } from './views/contexts/new-staff-information-context';
import { IsUpdateLocationSettingsContextProvider } from './views/contexts/is-update-location-settings-context';
import { IsPreparedReservationProvider } from './views/contexts/is-prepared-reservation-context';
import { ReservationSearchConditionProvider } from './views/contexts/revervation-search-condition-context';

const ProviderTree = buildProvidersTree([
  ReservationProvider,
  IsWaitingProvider,
  IsChangeReservationProvider,
  UserAuthenticatedProvider,
  PreReservationProvider,
  LoginStaffProvider,
  PreStaffInformationProvider,
  NewStaffInformationProvider,
  SelectedManagementPageViewStaffProvider,
  AgentIdProvider,
  IsUpdateLocationSettingsContextProvider,
  IsPreparedReservationProvider,
  ReservationSearchConditionProvider,
]);

// muiのテーマ設定
const theme = createTheme({
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});

// htmlと紐づけ
const router = createBrowserRouter(pages);
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <>
    <Provider container={rootContainer}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ProviderTree>
            <RouterProvider router={router} />
            <WaitingSpin />
          </ProviderTree>
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
