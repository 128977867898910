import { injectable } from 'inversify';
import SettingsReceptionTimeUsecase from '../../usecases/interfaces/settings-reception-time.usecase.interface';
import ReceptionTime from '../../domains/entitis/reception-time';
import { HttpRequest } from '../../utils/http-request';
import { ErrorHandler } from '../../domains/services/error-handler';

interface ResponseType {
  dow: string;
  startTimes: string[];
}

@injectable()
export default class SettingsReceptionTimeRepository implements SettingsReceptionTimeUsecase {
  private readonly _endpoint = 'reception-time';
  private readonly _defaultReceptionTimes: ReceptionTime[] = [
    ReceptionTime.create({ dow: '日', times: [] }),
    ReceptionTime.create({ dow: '月', times: [] }),
    ReceptionTime.create({ dow: '火', times: [] }),
    ReceptionTime.create({ dow: '水', times: [] }),
    ReceptionTime.create({ dow: '木', times: [] }),
    ReceptionTime.create({ dow: '金', times: [] }),
    ReceptionTime.create({ dow: '土', times: [] }),
    ReceptionTime.create({ dow: '祝', times: [] }),
  ];

  private _receptionTimes: ReceptionTime[] = [];

  async fetch(entityCode: string, location: string): Promise<void> {
    const requestUrl = HttpRequest.generateUrl(this._endpoint);
    HttpRequest.addUrlParamater(requestUrl, 'public-entity-code', entityCode);
    HttpRequest.addUrlParamater(requestUrl, 'location', location);
    const response = await HttpRequest.requestGet<ResponseType[]>(requestUrl);

    // 一旦初期化
    this._receptionTimes = this._defaultReceptionTimes.map((times) => {
      return ReceptionTime.copy(times);
    });

    if (
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    ) {
      return;
    }

    // 取得データに差し替え
    const receptionTimes =
      response.data?.data == null
        ? this._receptionTimes.map((receptionTime) =>
            ReceptionTime.create({
              dow: receptionTime.dow,
              times: [],
            })
          )
        : response.data.data.map((receptionTime) =>
            ReceptionTime.create({
              dow: receptionTime.dow,
              times: receptionTime.startTimes,
            })
          );
    for (let receptionTime of receptionTimes) {
      const index = this._receptionTimes.findIndex((row) => row.dow === receptionTime.dow);
      if (index < 0) {
        continue;
      }
      this._receptionTimes[index].times = receptionTime.times;
    }

    return;
  }

  read(): ReceptionTime[] {
    return this._receptionTimes.map((receptionTime) => ReceptionTime.copy(receptionTime));
  }

  async update(
    entityCode: string,
    location: string,
    receptionTimes: ReceptionTime[]
  ): Promise<boolean> {
    const dows = receptionTimes.map((receptionTime) => {
      return {
        dow: receptionTime.dow,
        startTimes: receptionTime.times,
      };
    });

    const requestUrl = HttpRequest.generateUrl(this._endpoint);
    const response = await HttpRequest.requestPut<null>(requestUrl, {
      publicEntityCode: entityCode,
      location,
      dows,
    });

    return !(
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    );
  }
}
