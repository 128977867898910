import { createContext, ReactNode, useContext, useState, Dispatch, SetStateAction } from 'react';
import { ViewName } from '../utils/switching-managemant-page-view';

interface SelectedManagementPageViewContextType {
  selectedView: ViewName | null;
  setSelectedView: Dispatch<SetStateAction<ViewName | null>>;
}

/**
 * 作成したコンテキストは一意の名称とする
 */
const selectedManagementPageViewContext = createContext<SelectedManagementPageViewContextType>(
  {} as SelectedManagementPageViewContextType
);

/**
 * 外部から設定値を取り出す用
 */
export const useSelectedManagementPageViewContext = () =>
  useContext(selectedManagementPageViewContext);

/**
 * コンテキストを提供する
 */
export const SelectedManagementPageViewStaffProvider = ({ children }: { children: ReactNode }) => {
  const [selectedView, setSelectedView] = useState<ViewName | null>(null);
  const { Provider } = selectedManagementPageViewContext;

  const value = {
    selectedView,
    setSelectedView,
  };

  return <Provider value={value}>{children}</Provider>;
};
