import React from 'react';
import { Stack } from '@mui/material';
import LocationSetting from '../../organism/location-setting';
import { useSearchParams } from 'react-router-dom';
import { PageUrl } from '../../../../libs/domains/services/page-url';
import RereservationableTimeSetting from '../../organism/reservationable-time-setting';
import GuideSettingsReflection from '../../organism/guide-settings-reflection';

const { QUERY_KEYS } = PageUrl;

const SettingsPage = () => {
  const [searchParams] = useSearchParams();

  // リクエストパラメータから自治体コードを取得
  const publicEntityCode = searchParams.get(QUERY_KEYS.PUBLIC_ENTITY_CODE) ?? '';

  return (
    <Stack alignItems="center" justifyContent="center" spacing={8}>
      <GuideSettingsReflection />
      <Stack direction="column" spacing={8}>
        <Stack>
          <div>予約可能期間</div>
          <RereservationableTimeSetting publicEntityCode={publicEntityCode} />
        </Stack>
        <Stack>
          <div>来庁場所</div>
          <LocationSetting publicEntityCode={publicEntityCode} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SettingsPage;
