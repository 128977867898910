import React, { useState } from 'react';
import { InputLabel, Stack, TextField } from '@mui/material';
import { useContainer } from 'inversify-react';
import ExecuteButton from '../../molecule/execute-button';
import { useReservationContext } from '../../../contexts/revervation-context';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PageUrl } from '../../../../libs/domains/services/page-url';
import ReservationSlotUsecase from '../../../../libs/usecases/interfaces/reservation-slot.usecase.interface';
import { useIsWaitingContext } from '../../../contexts/is-waiting-context';
import { TYPES } from '../../../../types';
import CommonDialog from '../../molecule/common-dialog';
import { usePreReservationContext } from '../../../contexts/pre-revervation-context';
import { useAgentIdContext } from '../../../contexts/agent-id-context';
import { useSelectedManagementPageViewContext } from '../../../contexts/selected-managemaent-page-view-context';
import { ErrorHandler } from '../../../../libs/domains/services/error-handler';
import { buttonWidth } from '../../../styles/size';
import SessionControl from '../../organism/session-control';
import RemarkField from '../../molecule/remark-field';
const { QUERY_KEYS, ENDPOINT } = PageUrl;

const ReservationEntryConfirmationPage = () => {
  const container = useContainer();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // 代理予約者
  const { agentId } = useAgentIdContext();

  // リクエストパラメータからユーザーIDと自治体コードを取得
  const userId = searchParams.get(QUERY_KEYS.USER_ID) ?? agentId ?? '';
  const publicEntityCode = searchParams.get(QUERY_KEYS.PUBLIC_ENTITY_CODE) ?? '';
  // 不正なリクエストパラメータ
  if (userId === '' || publicEntityCode === '') {
    navigate(ENDPOINT.NOT_FOUND, { replace: true });
  }

  // URLパラメータ作成
  const queryPublicEntityCode = {
    key: QUERY_KEYS.PUBLIC_ENTITY_CODE,
    value: publicEntityCode,
  };
  const queryUserId = {
    key: QUERY_KEYS.USER_ID,
    value: userId,
  };

  const reservationSlotUsecase = container.get<ReservationSlotUsecase>(TYPES.ReservationSlot);

  // 予約情報を取得
  const { reservation } = useReservationContext();
  const { preReservation } = usePreReservationContext();

  // 管理画面の表示内容
  const { setSelectedView } = useSelectedManagementPageViewContext();

  // 予約エラーダイアログ表示
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openDialog = () => {
    setIsDialogOpen(true);
  };
  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  // 読み込み中
  const { setIsWaiting } = useIsWaitingContext();

  // 次のページへ移動する
  const moveNextPage = () => {
    if (reservation == null) {
      return;
    }

    const preReservationNumber = preReservation?.reservationNumber ?? undefined;
    setIsWaiting(true);
    reservationSlotUsecase
      .isCapacityOver(
        publicEntityCode,
        reservation.location,
        reservation.visitDateTime,
        preReservationNumber
      )
      .then((result) => {
        if (result) {
          openDialog();
          return;
        }
        if (agentId == null) {
          // 予約完了画面へ遷移
          navigate(
            PageUrl.generate(ENDPOINT.RESERVATION_COMPLETION, queryPublicEntityCode, queryUserId),
            {
              replace: true,
            }
          );
        } else {
          // 管理画面の予約完了画面へ遷移
          setSelectedView('reservation-completion-view');
        }
      })
      .finally(() => setIsWaiting(false));
  };

  return (
    <>
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={8}>
        <Stack className="mt-4" justifyContent="center" alignItems="center">
          <div>この内容で予約しますか？</div>
        </Stack>
        <Stack className="w-4/5 max-w-md" spacing={1}>
          <InputLabel>来庁場所</InputLabel>
          <TextField
            className="bg-blue-200"
            fullWidth
            defaultValue={reservation?.location}
            InputProps={{
              readOnly: true,
            }}
          />

          <InputLabel>来庁日時</InputLabel>
          <TextField
            className="bg-blue-200"
            fullWidth
            defaultValue={reservation?.createViewDateTimeText()}
            InputProps={{
              readOnly: true,
            }}
          />

          {/* 備考 */}
          {agentId != null && <RemarkField text={reservation?.remark ?? ''} />}
        </Stack>
        <Stack
          className="w-full flex-wrap"
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <ExecuteButton
            className="mx-2 my-1"
            text="修正する"
            onClick={() => {
              if (agentId == null) {
                navigate(
                  PageUrl.generate(ENDPOINT.RESERVATION_EDIT, queryPublicEntityCode, queryUserId),
                  {
                    replace: true,
                  }
                );
              } else {
                // 管理画面の予約完了画面へ遷移
                setSelectedView('reservation-edit-view');
              }
            }}
            isPrimary={false}
            type="button"
            width={buttonWidth}
          />
          <ExecuteButton
            className="mx-2 my-1"
            text="予約を確定する"
            onClick={() => moveNextPage()}
            isPrimary={true}
            width={buttonWidth}
          />
        </Stack>

        {/* エラー表示ダイアログ */}
        <CommonDialog
          open={isDialogOpen}
          onClose={closeDialog}
          content={
            <Stack height={200} justifyContent="center" alignItems="center" spacing={8}>
              <div>{ErrorHandler.getErrorMessage(ErrorHandler.CODE.RESERVATION_OVER_CAPACITY)}</div>
              <ExecuteButton text="閉じる" onClick={closeDialog} isPrimary={true} />
            </Stack>
          }
        />
      </Stack>
      {/* セッション管理 */}
      {agentId == null && <SessionControl />}
    </>
  );
};

export default ReservationEntryConfirmationPage;
