import moment from 'moment';
import { DifferentialUtil } from '../../utils/differential-utils';

/**
 * 予約枠のプロパティ
 */
export interface ReservationSlotProperties {
  visitDateTime: Date;
  location: string;
  deleteDate: Date;
  slotNumber: number;
  reservationNumber: string | null;
  isStaffOnly: boolean;
}

/**
 * 予約枠
 */
class ReservationSlot {
  /**
   * コンストラクタ
   * @param _visitDateTime 来庁日時
   * @param _location 来庁場所
   * @param _deleteDate 削除予定日
   * @param _slotNumber 枠番号
   * @param _reservationNumber 予約番号。nullの場合は予約なし
   * @param _isStaffOnly 職員専用かどうか
   */
  private constructor(
    private readonly _visitDateTime: Date, // 来庁日時
    private readonly _location: string, // 来庁場所
    private readonly _deleteDate: Date, // 削除予定日
    private readonly _slotNumber: number, // 枠番号
    private _reservationNumber: string | null, // 予約番号
    private readonly _isStaffOnly: boolean // 職員専用かどうか
  ) {}

  /**
   * 同一オブジェクトとの比較
   * @param other 比較対象
   * @returns 一致:true、不一致:false
   */
  equals(other?: ReservationSlot | null): boolean {
    if (other == null) {
      return false;
    }
    const exactRequired =
      this._visitDateTime.getTime() === other.visitDateTime.getTime() &&
      this._location === other.location &&
      this._deleteDate.getTime() === other.deleteDate.getTime() &&
      this._slotNumber === other.slotNumber;
    const exactOptional = DifferentialUtil.exactOptional(
      this._reservationNumber,
      other.reservationNumber
    );
    return exactRequired && exactOptional;
  }

  /**
   * 来庁日時
   */
  get visitDateTime(): Date {
    return this._visitDateTime;
  }
  /**
   * 来庁日だけを文字列で取得する
   */
  createDateText(): string {
    return moment(this._visitDateTime.getTime()).format('yyyy-MM-DD');
  }

  /**
   * 来庁時間だけを文字列で取得する
   */
  createTimeText(): string {
    return moment(this._visitDateTime.getTime()).format('HH:mm');
  }

  /**
   * 来庁日時＋タイムゾーンで文字列で取得する
   */
  createAddDateTimeText(): string {
    return `${moment(this._visitDateTime.getTime()).format('yyyy-MM-DD HH:mm Z')}`;
  }

  /**
   * 来庁場所
   */
  get location(): string {
    return this._location;
  }

  /**
   * 予約番号
   */
  get reservationNumber(): string | null {
    return this._reservationNumber;
  }

  /**
   * 予約番号の更新
   */
  set reservationNumber(reservationNumber: string | null) {
    this._reservationNumber = reservationNumber;
  }

  /**
   * 削除予定日
   */
  get deleteDate(): Date {
    return this._deleteDate;
  }
  /**
   * 枠番号
   */
  get slotNumber(): number {
    return this._slotNumber;
  }
  /**
   * 職員専用かどうか
   */
  get isStaffOnly(): boolean {
    return this._isStaffOnly;
  }

  static create(properties: ReservationSlotProperties) {
    return new ReservationSlot(
      properties.visitDateTime,
      properties.location,
      properties.deleteDate,
      properties.slotNumber,
      properties.reservationNumber,
      properties.isStaffOnly
    );
  }
}

export default ReservationSlot;
