import React from 'react';
import { InputLabel, Stack, TextField, Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import style from './RemarkField.module.scss';

interface PropsType {
  text: string;
  setText?: (test: string) => void;
  canSave?: boolean;
  isReadOnly?: boolean;
  doSave?: () => void;
}

const RemarkField = ({
  text,
  canSave: isViewSaveButton = false,
  isReadOnly = true,
  doSave,
  setText,
}: PropsType) => {
  return (
    <Stack>
      <Stack
        className="p-1 w-full max-w-md flex-wrap"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <InputLabel>備考</InputLabel>
        {/* 保存ボタン */}
        {isViewSaveButton && (
          <div className={`${style['nomal-remark']}`}>
            <Button
              size="small"
              variant="contained"
              color="info"
              disableElevation
              startIcon={<SaveIcon />}
              onClick={doSave}
            >
              保存
            </Button>
          </div>
        )}
      </Stack>
      <div className={`${style['nomal-remark']}`}>
        <TextField
          multiline
          rows={5}
          fullWidth
          value={text}
          onChange={(event) => {
            if (setText == null) {
              return;
            }
            setText(event.target.value);
          }}
          className={`${isReadOnly ? 'bg-blue-200' : ''}`}
          InputProps={{
            readOnly: isReadOnly,
          }}
        />
      </div>
      {/* 印刷用備考欄 */}
      <div
        className={`${style['print-remark']} border border-gray-200 `}
        style={{ whiteSpace: 'pre-line' }}
      >
        {text}
      </div>
    </Stack>
  );
};

export default RemarkField;
