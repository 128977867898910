import { injectable } from 'inversify';
import ReservationUserUsecase from '../../usecases/interfaces/reservation-user.usecase.interface';
import User from '../../domains/value-objects/user';
import { HttpRequest } from '../../utils/http-request';
import { ErrorHandler } from '../../domains/services/error-handler';

interface ResponseType {
  userId: string;
}

@injectable()
export default class ReservationUserController implements ReservationUserUsecase {
  async add(mailadress: string, entityCode: string): Promise<string> {
    const response = await HttpRequest.requestPost<ResponseType>(HttpRequest.generateUrl('user'), {
      email: mailadress,
      publicEntityCode: entityCode,
    });
    if (
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    ) {
      return '';
    }
    return response.data?.data?.userId ?? '';
  }
  async fetch(userId: string): Promise<User | null> {
    const requestUrl = HttpRequest.generateUrl('user');
    requestUrl.searchParams.set('user-id', userId);
    const response = await HttpRequest.requestGet<{ email: string }>(requestUrl);

    if (
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    ) {
      return null;
    }
    const responseData = response.data?.data ?? null;
    if (responseData == null) {
      return null;
    }
    const mailadress = response.data.data?.email ?? '';
    return User.create({ userId, mailadress });
  }
  remove(userId: string): Promise<boolean> {
    // いまのところ使っていない
    throw new Error('Method not implemented.');
  }
}
