import { createContext, ReactNode, useContext, useState, Dispatch, SetStateAction } from 'react';
import { Staff } from '../../libs/domains/entitis/staff';

type NewStaffInformationContextType = {
  newStaff: Staff | null;
  setNewStaff: Dispatch<SetStateAction<Staff | null>>;
};

/**
 * 作成したコンテキストは一意の名称とする
 */
const newStaffInformationContext = createContext<NewStaffInformationContextType>(
  {} as NewStaffInformationContextType
);

/**
 * 外部から設定値を取り出す用
 */
export const useNewStaffInformationContext = () => useContext(newStaffInformationContext);

/**
 * コンテキストを提供する
 */
export const NewStaffInformationProvider = ({ children }: { children: ReactNode }) => {
  const [newStaff, setNewStaff] = useState<Staff | null>(null);
  const { Provider } = newStaffInformationContext;

  const value = {
    newStaff,
    setNewStaff,
  };
  return <Provider value={value}>{children}</Provider>;
};
