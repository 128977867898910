export namespace ErrorHandler {
  export interface InformError {
    timeout?: (code?: Code) => void;
    option?: (code?: Code) => void;
  }
  export interface ErrorHandle {
    code?: Code;
    run?: (code?: Code) => void;
  }

  /**
   * エラーコード
   */
  export const CODE = {
    NONE: 0,
    GENERAL: -1,
    RESERVATION_OVER_CAPACITY: 100,
    FAILED_AUTHENTICATION: 200,
    FAILED_USER_SIGNUP: 201,
    FAILED_SEND_CODE: 210,
    FAILED_RESEND_CODE: 220,
    FAILED_RESERVATION: 300,
    FAILED_START_PROXY_RESERVATION: 400,
    FAILED_SETTINGS_GENERAL: 500,
    FAILED_SETTINGS_RECEPTION_TIME: 600,
    FAILED_SETTINGS_ACCEPTABLE_COUNT: 610,
    FAILED_SETTINGS_CLOSING_DAYS: 620,
    FAILED_SETTINGS_UNIQUE_CLOSING_DAYS: 630,
    FAILED_STAFF_ADD: 700,
    FAILED_STAFF_SAVE: 710,
    FAILED_STAFF_REMOVE: 720,
    FAILED_STAFF_LOGIN: 730,
    FAILED_COMMUNICATE: 10000,
    TIMEOUT_COMMUNICATE: 10010,
  } as const;
  export type Code = (typeof CODE)[keyof typeof CODE];

  /**
   * エラーメッセージ一覧
   * ※エラーコードに対応したマップ
   */
  export const Messages = new Map<Code, string>([
    [CODE.RESERVATION_OVER_CAPACITY, 'すでに空いていないため別の日を選んでください。'],
    [
      CODE.FAILED_AUTHENTICATION,
      '入力したコードで認証できませんでした。認証コードを再送してください。',
    ],
    [CODE.FAILED_USER_SIGNUP, '通信に失敗しました。'],
    [CODE.FAILED_SEND_CODE, '認証コードの送信に失敗しました。認証コードを再送してください。'],
    [CODE.FAILED_RESEND_CODE, '認証コードの再送に失敗しました。'],
    [CODE.FAILED_RESERVATION, '予約に失敗しました。'],
    [CODE.FAILED_START_PROXY_RESERVATION, '代理予約の開始に失敗しました。やり直してください。'],
    [CODE.FAILED_SETTINGS_GENERAL, '基本設定に失敗しました。'],
    [CODE.FAILED_SETTINGS_RECEPTION_TIME, '曜日毎の受付時間設定に失敗しました。'],
    [CODE.FAILED_SETTINGS_ACCEPTABLE_COUNT, '受付組数設定に失敗しました。'],
    [CODE.FAILED_SETTINGS_CLOSING_DAYS, '曜日毎の窓口閉鎖と営業日設定に失敗しました。'],
    [CODE.FAILED_SETTINGS_UNIQUE_CLOSING_DAYS, '窓口閉鎖と営業日設定に失敗しました。'],
    [CODE.FAILED_STAFF_ADD, '追加に失敗しました。'],
    [CODE.FAILED_STAFF_SAVE, '保存に失敗しました。'],
    [CODE.FAILED_STAFF_REMOVE, '削除に失敗しました。'],
    [CODE.FAILED_STAFF_LOGIN, 'ログインできませんでした。'],
    [CODE.FAILED_COMMUNICATE, '通信に失敗しました。'],
    [CODE.TIMEOUT_COMMUNICATE, '通信がタイムアウトしました。'],
  ]);

  /**
   * ステータスコードがエラーかどうかを判定する
   * @param status ステータスコード
   * @returns true: エラー、False:正常
   */
  export const isErrorResponse = (status: number): boolean => {
    return status < 200 || status >= 400;
  };

  /**
   * 処理結果コードから処理成功か失敗かを判定する
   * @param code レスポンスデータに含まれる処理結果コード
   * @returns true: 失敗、false: 成功
   */
  export const isErrorResponseCode = (code: number): boolean => {
    return code !== 0;
  };

  /**
   * エラー時のふるまいを指定する
   * @param errorHandle エラーコードと処理内容
   */
  export const doErrorBehavior = (errorHandle: ErrorHandle): void => {
    const { code, run } = errorHandle;
    if (run == null) {
      return;
    }
    run(code);
  };

  /**
   * エラーコードからエラーメッセージを取得する
   * @param code エラーコード
   * @returns エラーメッセージ
   */
  export const getErrorMessage = (code?: Code): string => {
    if (!code) {
      return '';
    }
    const message = Messages.get(code);
    return message == null ? '' : message;
  };
}
