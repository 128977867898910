import { Card, Paper, Stack, TextField } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import AcceptableCount from '../../../../libs/domains/entitis/acceptable-count';
import { useContainer } from 'inversify-react';
import SettingsAcceptableCountUsecase from '../../../../libs/usecases/interfaces/settings-acceptable-count.usecase.interface';
import { TYPES } from '../../../../types';
import { useIsWaitingContext } from '../../../contexts/is-waiting-context';
import NowLoading from '../../molecule/now-loading';

const max = 99;
const min = 0;
const textWidth = 80;

interface PropsType {
  publicEntityCode: string;
  location: string;
  settings: AcceptableCount[] | null;
  setSettings: Dispatch<SetStateAction<AcceptableCount[] | null>>;
  setIsUpdate: Dispatch<SetStateAction<boolean>>;
}

// 受付組数の設定
const AcceptableCountSetting = ({
  publicEntityCode,
  location,
  settings,
  setSettings,
  setIsUpdate,
}: PropsType) => {
  const container = useContainer();

  // 受付組数
  const settingsAcceptableCountUsecase = useMemo(
    () => container.get<SettingsAcceptableCountUsecase>(TYPES.SettingsAcceptableCount),
    [container]
  );

  // 読み込み中
  const { setIsWaiting } = useIsWaitingContext();

  // 受付組数を変更する
  const handleAcceptableCountData = (dow: string, newAcceptableCount: number) => {
    if (settings == null) {
      return;
    }
    const newAcceptableCountData = settings.map((item) => {
      if (item.dow === dow) {
        item.acceptableCount = newAcceptableCount;
      }
      return AcceptableCount.copy(item);
    });
    setIsUpdate(true);
    setSettings(newAcceptableCountData);
  };

  // 受付組数を取得する
  useEffect(() => {
    if (settings != null) {
      return;
    }
    setIsWaiting(true);
    settingsAcceptableCountUsecase
      .fetch(publicEntityCode, location)
      .then(() => {
        const acceptableCounts = settingsAcceptableCountUsecase.read();
        setSettings(acceptableCounts);
        setIsUpdate(false);
      })
      .catch(() => [])
      .finally(() => setIsWaiting(false));
  }, [
    location,
    settingsAcceptableCountUsecase,
    settings,
    setSettings,
    publicEntityCode,
    setIsWaiting,
    setIsUpdate,
  ]);

  return (
    <NowLoading isLoading={settings == null}>
      <Stack spacing={2} alignItems="center" justifyContent="center">
        <Stack className="text-sm">
          <div>各曜日の予約時間ごとの受付上限数の設定となります。</div>
          <div>0としている場合には、予約の受付ができない曜日となります。</div>
        </Stack>

        <Card className="p-4" component={Paper}>
          <Stack direction="column" spacing={2}>
            <Stack spacing={1} alignItems="center" justifyContent="center">
              {settings?.map((row, index) => (
                <Stack
                  key={index}
                  direction="row"
                  spacing={6}
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <div>{row.dow}</div>

                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <TextField
                      type="number"
                      InputProps={{ inputProps: { min, max } }}
                      sx={{ width: textWidth }}
                      size="small"
                      value={row.acceptableCount}
                      onChange={(event: any) =>
                        handleAcceptableCountData(row.dow, Number(event.target.value))
                      }
                    />
                    <div>組</div>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Card>
      </Stack>
    </NowLoading>
  );
};

export default AcceptableCountSetting;
