import { createContext, ReactNode, useContext, useState, Dispatch, SetStateAction } from 'react';

interface IsWaitingContextType {
  isWaiting: boolean;
  setIsWaiting: Dispatch<SetStateAction<boolean>>;
}

/**
 * 作成したコンテキストは一意の名称とする
 */
const IsWaitingContext = createContext<IsWaitingContextType>({} as IsWaitingContextType);

/**
 * 外部から設定値を取り出す用
 */
export const useIsWaitingContext = () => useContext(IsWaitingContext);

/**
 * コンテキストを提供する
 */
export const IsWaitingProvider = ({ children }: { children: ReactNode }) => {
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const { Provider } = IsWaitingContext;

  const value = {
    isWaiting,
    setIsWaiting,
  };

  return <Provider value={value}>{children}</Provider>;
};
