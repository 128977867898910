/**
 * メール設定のプロパティ
 */
export interface MailSettingProperties {
  publicEntityCode: string;
  emailSender: string;
  authenticationEmailSubject: string;
  authenticationEmailMessage: string;
  reservationCompletionSubject: string;
  reservationCompletionMessage: string;
  reservationChangeSubject: string;
  reservationChangeMessage: string;
  reservationCancelSubject: string;
  reservationCancelMessage: string;
  remindEmailSubject: string;
  remindEmailMessage: string;
  contactInformation: string;
}

/**
 * メール設定
 */
export default class MailSetting {
  private constructor(
    private readonly _publicEntityCode: string, // 自治体コード
    private readonly _emailSender: string, // メール送信者
    private _authenticationEmailSubject: string, // 認証メール件名
    private _authenticationEmailMessage: string, // 認証メール本文
    private _reservationCompletionSubject: string, // 予約完了件名
    private _reservationCompletionMessage: string, // 予約完了本文
    private _reservationChangeSubject: string, // 予約変更件名
    private _reservationChangeMessage: string, // 予約変更本文
    private _reservationCancelSubject: string, // 予約取消件名
    private _reservationCancelMessage: string, // 予約取消本文
    private _remindEmailSubject: string, // リマインドメール件名
    private _remindEmailMessage: string, // リマインドメール本文
    private _contactInformation: string // 問い合わせ先
  ) {}

  /**
   * 同一オブジェクトとの比較
   * @param other 比較対象
   * @returns 一致:true、不一致:false
   */
  equals(other?: MailSetting | null): boolean {
    if (other == null) {
      return false;
    }
    return (
      this._publicEntityCode === other.publicEntityCode &&
      this._emailSender === other.emailSender &&
      this._authenticationEmailSubject === other.authenticationEmailSubject &&
      this._authenticationEmailMessage === other.authenticationEmailMessage &&
      this._reservationCompletionSubject === other.reservationCompletionSubject &&
      this._reservationCompletionMessage === other.reservationCompletionMessage &&
      this._reservationChangeSubject === other.reservationChangeSubject &&
      this._reservationChangeMessage === other.reservationChangeMessage &&
      this._reservationCancelSubject === other.reservationCancelSubject &&
      this._reservationCancelMessage === other.reservationCancelMessage &&
      this._remindEmailSubject === other.remindEmailSubject &&
      this._remindEmailMessage === other.remindEmailMessage &&
      this._contactInformation === other.contactInformation
    );
  }

  /**
   * 自治体コード
   */
  get publicEntityCode(): string {
    return this._publicEntityCode;
  }
  /**
   * メール送信者
   */
  get emailSender(): string {
    return this._emailSender;
  }
  /**
   * 認証メール件名
   */
  get authenticationEmailSubject(): string {
    return this._authenticationEmailSubject;
  }
  set authenticationEmailSubject(authenticationEmailSubject: string) {
    this._authenticationEmailSubject = authenticationEmailSubject;
  }
  /**
   * 認証メール本文
   */
  get authenticationEmailMessage(): string {
    return this._authenticationEmailMessage;
  }
  set authenticationEmailMessage(authenticationEmailMessage: string) {
    this._authenticationEmailMessage = authenticationEmailMessage;
  }
  /**
   * 予約完了件名
   */
  get reservationCompletionSubject(): string {
    return this._reservationCompletionSubject;
  }
  set reservationCompletionSubject(reservationCompletionSubject: string) {
    this._reservationCompletionSubject = reservationCompletionSubject;
  }
  /**
   * 予約完了本文
   */
  get reservationCompletionMessage(): string {
    return this._reservationCompletionMessage;
  }
  set reservationCompletionMessage(reservationCompletionMessage: string) {
    this._reservationCompletionMessage = reservationCompletionMessage;
  }
  /**
   * 予約変更件名
   */
  get reservationChangeSubject(): string {
    return this._reservationChangeSubject;
  }
  set reservationChangeSubject(reservationChangeSubject: string) {
    this._reservationChangeSubject = reservationChangeSubject;
  }
  /**
   * 予約変更本文
   */
  get reservationChangeMessage(): string {
    return this._reservationChangeMessage;
  }
  set reservationChangeMessage(reservationChangeMessage: string) {
    this._reservationChangeMessage = reservationChangeMessage;
  }
  /**
   * 予約取消件名
   */
  get reservationCancelSubject(): string {
    return this._reservationCancelSubject;
  }
  set reservationCancelSubject(reservationCancelSubject: string) {
    this._reservationCancelSubject = reservationCancelSubject;
  }
  /**
   * 予約取取消本文
   */
  get reservationCancelMessage(): string {
    return this._reservationCancelMessage;
  }
  set reservationCancelMessage(reservationCancelMessage: string) {
    this._reservationCancelMessage = reservationCancelMessage;
  }
  /**
   * リマインドメール件名
   */
  get remindEmailSubject(): string {
    return this._remindEmailSubject;
  }
  set remindEmailSubject(remindEmailSubject: string) {
    this._remindEmailSubject = remindEmailSubject;
  }
  /**
   * リマインドメール本文
   */
  get remindEmailMessage(): string {
    return this._remindEmailMessage;
  }
  set remindEmailMessage(remindEmailMessage: string) {
    this._remindEmailMessage = remindEmailMessage;
  }
  /**
   * 問い合わせ先
   */
  get contactInformation(): string {
    return this._contactInformation;
  }
  set contactInformation(contactInformation: string) {
    this._contactInformation = contactInformation;
  }

  /**
   * ドメイン名
   */
  get domainName(): string {
    const domainName = this._excerptDomainName(this._emailSender);
    return domainName ?? '';
  }

  /**
   * 新規作成
   */
  static create(properties: MailSettingProperties) {
    return new MailSetting(
      properties.publicEntityCode,
      properties.emailSender,
      properties.authenticationEmailSubject,
      properties.authenticationEmailMessage,
      properties.reservationCompletionSubject,
      properties.reservationCompletionMessage,
      properties.reservationChangeSubject,
      properties.reservationChangeMessage,
      properties.reservationCancelSubject,
      properties.reservationCancelMessage,
      properties.remindEmailSubject,
      properties.remindEmailMessage,
      properties.contactInformation
    );
  }

  /**
   * コピーの作成
   */
  static copy(previos: MailSetting) {
    return new MailSetting(
      previos.publicEntityCode,
      previos.emailSender,
      previos.authenticationEmailSubject,
      previos.authenticationEmailMessage,
      previos.reservationCompletionSubject,
      previos.reservationCompletionMessage,
      previos.reservationChangeSubject,
      previos.reservationChangeMessage,
      previos.reservationCancelSubject,
      previos.reservationCancelMessage,
      previos.remindEmailSubject,
      previos.remindEmailMessage,
      previos.contactInformation
    );
  }

  /**
   * ドメイン名を抜き出す
   */
  private _excerptDomainName(emailAddress: string): string | null {
    const index = emailAddress.indexOf('@');
    if (index < 0) {
      return null;
    }
    const domainName = emailAddress.substring(emailAddress.indexOf('@'));
    return domainName;
  }
}
