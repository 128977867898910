import { injectable } from 'inversify';
import LoginUsecase from '../../usecases/interfaces/login.usecase.interface';
import { HttpRequest } from '../../utils/http-request';
import { Role, Staff } from '../../domains/entitis/staff';
import { ErrorHandler } from '../../domains/services/error-handler';
import { LoginStaff } from '../../domains/services/login-staff';

interface PutResponseType {
  id: string;
  accessToken: string;
  refreshToken: string;
  permission: Role;
}
interface PutResponseType {
  accessToken: string;
}

@injectable()
export default class LoginController implements LoginUsecase {
  private _endpoint = 'staff-login';

  async verify(userName: string, password: string): Promise<LoginStaff | null> {
    const response = await HttpRequest.requestPost<PutResponseType>(
      HttpRequest.generateUrl(this._endpoint),
      {
        id: userName,
        password,
      }
    );

    if (
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code) ||
      response.data?.data == null
    ) {
      return null;
    }

    return {
      loginId: Staff.extractionLoginIdFromUserName(response.data.data.id),
      userName: response.data.data.id,
      accessToken: response.data.data.accessToken,
      refreshToken: response.data.data.refreshToken,
      permission: response.data.data.permission,
      updatetime: new Date(),
    };
  }
  async revoke(refreshToken: string): Promise<boolean> {
    const requestUrl = HttpRequest.generateUrl(this._endpoint);
    HttpRequest.addUrlParamater(requestUrl, 'refresh-token', refreshToken);
    const response = await HttpRequest.requestDelete(requestUrl);
    return !(
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    );
  }
  async refresh(loggedInStaff: LoginStaff | null): Promise<LoginStaff | null> {
    if (loggedInStaff == null) {
      return loggedInStaff;
    }

    const { refreshToken, loginId, userName, permission } = loggedInStaff;

    const response = await HttpRequest.requestPut<PutResponseType>(
      HttpRequest.generateUrl(this._endpoint),
      {
        refreshToken,
      }
    );

    if (
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code) ||
      response.data?.data == null
    ) {
      return null;
    }

    return {
      loginId,
      userName,
      permission,
      accessToken: response.data.data.accessToken,
      refreshToken,
      updatetime: new Date(),
    };
  }
}
