import { injectable } from 'inversify';
import ReservationSlotUsecase from '../../usecases/interfaces/reservation-slot.usecase.interface';
import ReservationSlot from '../../domains/entitis/reservation-slot';
import { HttpRequest } from '../../utils/http-request';
import moment from 'moment';
import * as _ from 'lodash';
import { ErrorHandler } from '../../domains/services/error-handler';
import { TimeControl } from '../../utils/time-control';

/**
 * データベースから読み出したときのデータ型
 */
interface ResponseType {
  visitDateTime: string; // 来庁日時
  location: string; // 来庁場所
  deleteDate: string; // 削除予定日
  slotNumber: number; // 枠番号
  reservationNumber: string | null; // 予約番号
  staffOnly: boolean; // 職員専用の予約枠
}

@injectable()
export default class ReservationSlotRepository implements ReservationSlotUsecase {
  private readonly _endpointOne = 'reservation-slot';
  private readonly _endpointAll = 'reservation-slots';

  private _slots: ReservationSlot[] = [];

  add(entityCode: string): Promise<boolean> {
    // TODO:いまのところ使っていない
    throw new Error('Method not implemented.');
  }

  async fetchSlots(entityCode: string, location?: string): Promise<void> {
    const requestUrl = HttpRequest.generateUrl(this._endpointAll);
    HttpRequest.addUrlParamater(requestUrl, 'public-entity-code', entityCode);
    HttpRequest.addUrlParamater(requestUrl, 'location', location);
    const response = await HttpRequest.requestGet<ResponseType[]>(requestUrl);
    if (
      ErrorHandler.isErrorResponse(response.status) ||
      ErrorHandler.isErrorResponseCode(response.data.code)
    ) {
      return;
    }
    this._slots =
      response.data.data?.map((slot) => {
        const {
          visitDateTime: visitDateTimeText,
          location,
          deleteDate: deleteDateText,
          reservationNumber,
          slotNumber,
          staffOnly: isStaffOnly,
        } = slot;
        const visitDateTime = new Date(visitDateTimeText);
        const deleteDate = new Date(deleteDateText);

        return ReservationSlot.create({
          visitDateTime,
          location,
          deleteDate,
          slotNumber,
          reservationNumber,
          isStaffOnly,
        });
      }) ?? [];
  }
  readSlots(location?: string): ReservationSlot[] {
    return _.cloneDeep(
      this._slots.filter((slot) => (location ? slot.location === location : true))
    );
  }

  readEnableReceptionTime(location: string, visitDate: Date): string[] {
    // 日付を文字列に変換
    const visitDateText = moment(visitDate.getTime()).format('YYYY/MM/DD');
    const today = new Date().getTime();
    const todayText = moment(today).format('YYYY/MM/DD');

    // 取得する範囲
    // 当日は現在時刻以降を取得範囲とする
    const startTime =
      visitDateText === todayText ? today : new Date(`${visitDateText} 00:00`).getTime();
    const startEnd = new Date(`${visitDateText} 23:59`).getTime();

    // 有効かつ範囲内の予約枠を抽出
    const slots = this.readSlots(location).filter((slot) => {
      const visitDateTime = slot.visitDateTime.getTime();
      return (
        slot.reservationNumber == null && visitDateTime >= startTime && visitDateTime <= startEnd
      );
    });

    // 時間の一覧を返却
    return Array.from(new Set(slots.map((slot) => slot.createTimeText()))).sort();
  }

  replacement(slots: ReservationSlot[]): void {
    this._slots = slots;
  }
  async isCapacityOver(
    entityCode: string,
    location: string,
    visitDateTime: Date,
    reservationNumber?: string | undefined
  ): Promise<boolean> {
    const requestUrl = HttpRequest.generateUrl(this._endpointOne);
    HttpRequest.addUrlParamater(requestUrl, 'public-entity-code', entityCode);
    HttpRequest.addUrlParamater(requestUrl, 'location', location);
    HttpRequest.addUrlParamater(
      requestUrl,
      'visit-date-time',
      TimeControl.createDateTimeTimeZoneText(visitDateTime)
    );
    HttpRequest.addUrlParamater(requestUrl, 'reservation-number', reservationNumber);
    const response = await HttpRequest.requestGet<ResponseType>(requestUrl);

    if (ErrorHandler.isErrorResponse(response.status)) {
      return false;
    }

    return response.data.code === ErrorHandler.CODE.RESERVATION_OVER_CAPACITY;
  }

  minReserveDay(location?: string): Date {
    const slots = this.readSlots(location);
    const days = slots.map((slot) => slot.visitDateTime.getTime());
    if (days.length <= 0) {
      return new Date();
    }
    return new Date(Math.min.apply(null, days));
  }

  maxReserveDay(location?: string): Date {
    const slots = this.readSlots(location);
    const days = slots.map((slot) => slot.visitDateTime.getTime());
    if (days.length <= 0) {
      return new Date();
    }
    return new Date(Math.max.apply(null, days));
  }

  extractionUnreservableDays(startDate: number, location?: string): string[] {
    const slots = this.readSlots(location);
    const today = new Date(`${moment().format('YYYY-MM-DD')} 00:00:00`);
    return Array.from(new Set(slots.map((slot) => slot.createDateText())))
      .filter((day) => new Date(day).getTime() >= today.getTime())
      .sort()
      .splice(0, startDate);
  }

  isDeprecationDate(today: Date): boolean {
    const todaySlots = this._slots.filter(
      (slot) => new Date(slot.visitDateTime).setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0)
    );
    return todaySlots.some((slot) => slot.isStaffOnly);
  }
}
