import { useContainer } from 'inversify-react';
import React, { useEffect, useState } from 'react';
import SettingsLocationUsecase from '../../../../libs/usecases/interfaces/settings-location.usecase.interface';
import { TYPES } from '../../../../types';
import { useIsWaitingContext } from '../../../contexts/is-waiting-context';
import { IconButton, List, ListItem, ListItemText, Stack, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExecuteButton from '../../molecule/execute-button';
import WaitAMoment from '../../molecule/wait-a-moment';

interface PropsType {
  publicEntityCode: string;
}
const LocationSetting = ({ publicEntityCode }: PropsType) => {
  const container = useContainer();
  // 来庁場所
  const settingsLocationUsecase = container.get<SettingsLocationUsecase>(TYPES.SettingsLocation);
  const [locations, setLocations] = useState<string[]>([]);
  const [location, setLocation] = useState('');
  // 読み込み中
  const { setIsWaiting } = useIsWaitingContext();

  // 初期化完了
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const onAdd = async () => {
    setIsWaiting(true);
    try {
      if (!(await settingsLocationUsecase.add(publicEntityCode, location))) {
        return;
      }
      await settingsLocationUsecase.fetch(publicEntityCode);
      setLocations(settingsLocationUsecase.read());
    } finally {
      setIsWaiting(false);
    }
  };
  const onDelete = async (locationName: string) => {
    setIsWaiting(true);
    try {
      if (!(await settingsLocationUsecase.remove(publicEntityCode, locationName))) {
        return;
      }
      await settingsLocationUsecase.fetch(publicEntityCode);
      setLocations(settingsLocationUsecase.read());
    } finally {
      setIsWaiting(false);
    }
  };

  // 来庁場所一覧取得
  useEffect(() => {
    settingsLocationUsecase
      .fetch(publicEntityCode)
      .then(() => {
        const readlocations = settingsLocationUsecase.read();
        setLocations(readlocations);
      })
      .catch(() => [])
      .finally(() => setIsInitialized(true));
  }, [setIsWaiting, setLocations, setLocation, settingsLocationUsecase, publicEntityCode]);

  return (
    <Stack spacing={2}>
      <WaitAMoment isLoading={!isInitialized}>
        <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={2}>
          <TextField onChange={(event: any) => setLocation(event.target.value)} />
          <ExecuteButton text="追加" onClick={onAdd} />
        </Stack>
        <List
          sx={{
            border: 1,
            width: '100%',
            maxWidth: 360,
            position: 'relative',
            overflow: 'auto',
            minHeight: 300,
            maxHeight: 300,
            '& ul': { padding: 0 },
          }}
        >
          {locations.map((locationName, index) => {
            return (
              <ListItem
                key={index}
                secondaryAction={
                  <IconButton edge="end" aria-label="delete" onClick={() => onDelete(locationName)}>
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemText primary={locationName} />
              </ListItem>
            );
          })}
        </List>
      </WaitAMoment>
    </Stack>
  );
};

export default LocationSetting;
