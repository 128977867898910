import React, { useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import './App.scss';
import { PageUrl } from '../../../../libs/domains/services/page-url';
import { HttpRequest } from '../../../../libs/utils/http-request';
import { Stack } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
const { QUERY_KEYS, ENDPOINT } = PageUrl;

function App() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // 自治体コードを取得
  const publicEntityCode = searchParams.get(QUERY_KEYS.PUBLIC_ENTITY_CODE) ?? '12345';

  // ダイアログ操作
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // APIコールテスト
  const [response, setResponse] = useState('');
  const getApiTest = (api: string) => {
    const testRquest = async () => {
      const result = await HttpRequest.requestGet<any>(HttpRequest.generateUrl(api));
      setResponse(JSON.stringify(result));
    };
    testRquest();
  };
  const postApiTest = (api: string, arg: any) => {
    const testRquest = async () => {
      const result = await HttpRequest.requestPost<any>(HttpRequest.generateUrl(api), arg);
      setResponse(JSON.stringify(result));
    };
    testRquest();
  };

  // 遷移先URLを作成
  const generateURL = useCallback(
    (path: PageUrl.Endpoint, userId: string) => {
      // リクエストパラメータを作成
      const publicEntutyCodeQuery = { key: QUERY_KEYS.PUBLIC_ENTITY_CODE, value: publicEntityCode };
      const userIdQuery = { key: QUERY_KEYS.USER_ID, value: userId };
      return PageUrl.generate(path, publicEntutyCodeQuery, userIdQuery);
    },
    [publicEntityCode]
  );

  // iFrameからユーザーIDを受け取って画面遷移
  const handler = useCallback(
    (event: MessageEvent<any>) => {
      console.log(event);
      if (event.data?.type !== 'userId') {
        return;
      }

      if (event.data?.data == null) {
        handleClose();
        return;
      }

      const userId = event.data.data as string;
      navigate(generateURL(ENDPOINT.DUMMY_PRE_APPLICATION, userId), { replace: true });
    },
    [generateURL, navigate]
  );
  useEffect(() => {
    window.addEventListener('message', handler);
    return () => {
      window.removeEventListener('message', handler);
    };
  }, [handler]);

  return (
    <div>
      <div>事前申請システム</div>
      <Button variant="outlined" onClick={handleClickOpen}>
        おくやみ
      </Button>
      <hr />
      <div>APIコールテスト</div>
      <Stack justifyContent="flex-start" alignItems="flex-start">
        <Button variant="outlined" onClick={() => getApiTest('api')}>
          GETリクエスト(/api)
        </Button>
        <Button variant="outlined" onClick={() => getApiTest('api?code=123&text=iiiia')}>
          GETリクエスト(/api/?code=123&text=iiiia)
        </Button>
        <Button
          variant="outlined"
          onClick={() =>
            postApiTest('api', {
              test: 123,
              aaa: 'post test',
            })
          }
        >
          POSTリクエスト(/api)
        </Button>
        <Button
          variant="outlined"
          onClick={() =>
            postApiTest('/sendmail', {
              body: { message: 'hello reservation system.', email: 'Nezu.Takashi@technopro.com' },
            })
          }
        >
          POSTリクエスト(/sendmail)
        </Button>
        <Button variant="outlined" onClick={() => getApiTest('test')}>
          GETリクエスト(/test)※クロスオリジンで拒否
        </Button>

        <Button variant="outlined" onClick={() => postApiTest('need-users', {})}>
          POSTリクエスト(/need-users)
        </Button>

        <div>{response}</div>
      </Stack>
      <Dialog open={open} onClose={handleClose} fullWidth={true}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Stack width="100%" height="640px" justifyContent="center" alignItems="center">
              <iframe
                title="reservation"
                width="100%"
                height="100%"
                src={`${PageUrl.generate('/input-email-page', {
                  key: QUERY_KEYS.PUBLIC_ENTITY_CODE,
                  value: publicEntityCode,
                })}`}
              ></iframe>
            </Stack>
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}

export default App;
