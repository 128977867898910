import React, { useCallback } from 'react';
import CommonDialog from '../../molecule/common-dialog';
import { Stack } from '@mui/material';
import ExecuteButton from '../../molecule/execute-button';
import { buttonWidth } from '../../../styles/size';

interface PropsType {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const ClosePageDaialog = ({ isOpen, setIsOpen }: PropsType) => {
  const onClose = useCallback(
    () => (event: React.MouseEvent<HTMLInputElement>, reason: 'backdropClick') => {
      if (reason === 'backdropClick') {
        // バックドロップのクリックを無効化
        return;
      }
    },
    []
  );

  return (
    <>
      <CommonDialog
        open={isOpen}
        onClose={onClose}
        content={
          <Stack
            className="w-full h-full pt-8"
            justifyContent="center"
            alignItems="center"
            spacing={6}
          >
            <Stack spacing={1}>
              <div>ブラウザの×ボタンで閉じてください</div>
            </Stack>
            <Stack
              className="w-full flex-wrap"
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={6}
            >
              <ExecuteButton
                className="mx-2 my-1"
                text="はい"
                onClick={() => setIsOpen(false)}
                isPrimary={true}
                width={buttonWidth}
              />
            </Stack>
          </Stack>
        }
      />
    </>
  );
};

export default ClosePageDaialog;
