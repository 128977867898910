export namespace PageUrl {
  /**
   * 各ページのエンドポイントの定義
   */
  export const ENDPOINT = {
    ROOT: '/',
    NOT_FOUND: '/404',
    DUMMY_PRE_APPLICATION: '/dummy-pre-application-page',
    INPUT_EMAIL: '/input-email-page',
    AUTHETICATION: '/authentication-page',
    RESERVATION: '/reservation-page',
    RESERVATION_EDIT: '/reservation-edit-page',
    CHANGE_RESERVATION: '/change-reservation-page',
    RESERVATION_CANCEL_CONFIRM: '/reservation-cancel-confirm-page',
    RESERVATION_ENTRY_CONFIRMATION: '/reservation-entry-confirmation-page',
    RESERVATION_COMPLETION: '/reservation-completion-page',
    RESERVATION_NOT_FOUND: '/reservation-not-found-page',
    RESERVATION_EXPRIED: '/reservation-expired-page',
    RESERVATION_SESSION_TIMEOUT: '/reservation-session-timeout-page',
    MANAGEMENT: '/management-page',
    VIEW_RESERVATION_STATUS: '/view-reservation-status-page',
    LOGIN: '/login-page',
  } as const;

  /**
   * エンドポイントの種別
   */
  export type Endpoint = (typeof ENDPOINT)[keyof typeof ENDPOINT];

  /**
   * クエリパラメターの定義
   */
  export const QUERY_KEYS = {
    PUBLIC_ENTITY_CODE: 'public-entity-code', // 自治体コード
    USER_ID: 'user-id', // ユーザーID
    EMAIL: 'email', // メールアドレス
    IS_CHANGE_RESERVATION: 'is-change-reservation', // 再予約かどうか
  } as const;

  /**
   * クエリパラメータの種別
   */
  export type QueryKeys = (typeof QUERY_KEYS)[keyof typeof QUERY_KEYS];

  /**
   * 指定したクエリパラメータを追加した相対パスを作成する
   * @param endpoint 相対パス
   * @param querys クエリパラメータ（0個以上）
   * @returns クエリパラメータが追加された相対パス
   */
  export const generate = (
    endpoint: Endpoint,
    ...querys: { key: QueryKeys; value: string }[]
  ): string => {
    const url = new URL(endpoint, window.location.href);
    if (querys) {
      // クエリパラメータの追加
      for (let query of querys) {
        url.searchParams.set(query.key, query.value);
      }
    }
    return `${url.pathname}${url.search}`;
  };
}
