import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Tooltip } from '@mui/material';
import Calendar from 'react-calendar';
import { Value } from 'react-calendar/dist/cjs/shared/types';
import { useReservationContext } from '../../../contexts/revervation-context';
import ReservationSlot from '../../../../libs/domains/entitis/reservation-slot';
import { useIsWaitingContext } from '../../../contexts/is-waiting-context';
import { useContainer } from 'inversify-react';
import ReservationSlotUsecase from '../../../../libs/usecases/interfaces/reservation-slot.usecase.interface';
import { TYPES } from '../../../../types';
import { useSearchParams } from 'react-router-dom';
import { PageUrl } from '../../../../libs/domains/services/page-url';
import SettingsLocationUsecase from '../../../../libs/usecases/interfaces/settings-location.usecase.interface';
import Reservation from '../../../../libs/domains/entitis/reservation';
import { useSelectedManagementPageViewContext } from '../../../contexts/selected-managemaent-page-view-context';
import { usePreReservationContext } from '../../../contexts/pre-revervation-context';
import { useIsChangeReservationContext } from '../../../contexts/is-change-reservation-context';
import style from './DashboardPage.module.scss';
import ReservationUsecase from '../../../../libs/usecases/interfaces/reservation.usecase.interface';
import { TimeControl } from '../../../../libs/utils/time-control';
import WaitAMoment from '../../molecule/wait-a-moment';
import moment from 'moment';
import { useLoginStaffContext } from '../../../contexts/login-staff-context';
import SettingsReservationableTimeUsecase from '../../../../libs/usecases/interfaces/settings-reservationable-time.usecase.interface';
import SettingsCounterUniqueClosingDaysUsecase from '../../../../libs/usecases/interfaces/settings-counter-unique-closing-days.usecase.interface';

const { QUERY_KEYS } = PageUrl;

const DashboardPage = () => {
  const [searchParams] = useSearchParams();

  // ログインユーザー
  const { loginStaff } = useLoginStaffContext();

  // 自治体コードを取得
  const publicEntityCode = searchParams.get(QUERY_KEYS.PUBLIC_ENTITY_CODE) ?? '';
  const container = useContainer();

  // 予約情報
  const { setReservation } = useReservationContext();
  const { setPreReservation } = usePreReservationContext();

  // 再予約かどうか
  const { setIsChangeReservation } = useIsChangeReservationContext();

  // 来庁場所
  const settingsLocationUsecase = container.get<SettingsLocationUsecase>(TYPES.SettingsLocation);
  const [locations, setLocations] = useState<string[]>([]);
  const [location, setLocation] = useState<string | null>(null);

  // 読み込み中
  const { setIsWaiting } = useIsWaitingContext();

  // 予約枠
  const [slots, setSlots] = useState<ReservationSlot[] | null>(null);
  const [allSlots, setAllSlots] = useState<ReservationSlot[] | null>(null);
  const reservationSlotUsecase = useMemo(
    () => container.get<ReservationSlotUsecase>(TYPES.ReservationSlot),
    [container]
  );

  // 本日の予約数
  const [todayReserveCount, setTodayReserveCount] = useState<number | null>(null);
  const reservationUsecase = useMemo(
    () => container.get<ReservationUsecase>(TYPES.Reservation),
    [container]
  );

  // 予約可能期間
  const settingsRereservationableTimeUsecase = useMemo(
    () => container.get<SettingsReservationableTimeUsecase>(TYPES.SettingsReservationableTime),
    [container]
  );
  const [startDate, setStartDate] = useState<number>(-1);

  // 窓口閉鎖と営業日
  const settingsCounterUniqueClosingDaysUsecase = useMemo(
    () =>
      container.get<SettingsCounterUniqueClosingDaysUsecase>(
        TYPES.SettingsCounterUniqueClosingDays
      ),
    [container]
  );

  // 選択された日付
  const [selectedDay, setSelectedDay] = useState<Value | null>(null);

  // 予約可能で一番近い日
  const [activeStartDate, setActiveStartDate] = useState<Date>(new Date());

  // 管理画面の画面遷移
  const { setSelectedView } = useSelectedManagementPageViewContext();

  // カレンダー変更時に選びなおす
  const onChange = (nextValue: Value) => {
    if (loginStaff?.permission !== 'administrator') {
      return;
    }
    // 履歴情報を初期化
    setPreReservation(null);
    setIsChangeReservation(false);

    // 選択した予約情報の受け渡し
    setSelectedDay(nextValue);
    const visitDateTime = new Date(nextValue as Date);
    setReservation(Reservation.create({ visitDateTime, location: location ?? '' }));

    // 代理予約を開始
    setSelectedView('create-reservation-agent-view');
  };
  // 来庁場所変更時の処理
  const handleLocationChange = (event: SelectChangeEvent) => {
    setReservation(null);
    const target = event.target.value as string;

    setIsWaiting(true);
    settingsCounterUniqueClosingDaysUsecase
      .fetch(publicEntityCode, target)
      .catch(() => {})
      .finally(() => {
        setLocation(target);
        setSlots(reservationSlotUsecase.readSlots(target));
        setIsWaiting(false);
      });
  };

  // 指定した日付の空き枠時間を取得
  const getEmptyReservationTimes = (date: Date, targetSlots: ReservationSlot[]) => {
    // 空き枠を取得
    const emptySlots = targetSlots
      .filter((emptySlot) => emptySlot.reservationNumber == null)
      .filter((emptySlot) => emptySlot.visitDateTime.getTime() >= new Date().getTime());
    // .map((emptySlot) => new Date(emptySlot.visitDateTime).setHours(0, 0, 0, 0));

    const emptySlotByDateTimes = emptySlots
      .filter(
        (emptySlot) =>
          new Date(emptySlot.visitDateTime).setHours(0, 0, 0, 0) === date.setHours(0, 0, 0, 0)
      )
      .map((emptySlot) => moment(new Date(emptySlot.visitDateTime).getTime()).format('HH:mm'));

    return emptySlotByDateTimes;
  };

  // 非推奨な予約枠かどうか
  const isDeprecationDate = (today: Date) => {
    const closingDays = settingsCounterUniqueClosingDaysUsecase.closingDays;
    const skipDays = reservationSlotUsecase.extractionUnreservableDays(
      settingsRereservationableTimeUsecase.startDate,
      location ?? ''
    );

    const targetDate = TimeControl.createDateText(today);

    return (
      reservationSlotUsecase.isDeprecationDate(today) ||
      skipDays.includes(targetDate) ||
      closingDays.includes(targetDate)
    );
  };

  // 初期化
  useEffect(() => {
    const run = async () => {
      try {
        // 来庁場所一覧取得
        await settingsLocationUsecase.fetch(publicEntityCode).catch(() => {});
        const readlocations = settingsLocationUsecase.read();
        setLocations(readlocations);
        const defaultLocation = readlocations.length > 0 ? readlocations[0] : undefined;
        setLocation(defaultLocation ?? '');

        // 閉庁日取得
        await settingsCounterUniqueClosingDaysUsecase
          .fetch(publicEntityCode, defaultLocation ?? '')
          .catch(() => {});

        // 予約開始可能期間の開始営業日数を取得
        await settingsRereservationableTimeUsecase.fetch(publicEntityCode).catch(() => {});
        setStartDate(settingsRereservationableTimeUsecase.startDate);

        // 予約枠の取得
        await reservationSlotUsecase.fetchSlots(publicEntityCode).catch(() => {});
        setSlots(reservationSlotUsecase.readSlots(defaultLocation));
        setAllSlots(reservationSlotUsecase.readSlots());

        // 本日の予約数
        const reservations = await reservationUsecase
          .fetchList(publicEntityCode, {
            dateRange: {
              dateFrom: TimeControl.createDateTimeTimeZoneStartDate(new Date()),
              dateTo: TimeControl.createDateTimeTimeZoneEndDate(new Date()),
            },
          })
          .catch(() => []);
        setTodayReserveCount(reservations.length);
      } finally {
      }
    };
    run();
  }, [
    setIsWaiting,
    setLocations,
    setLocation,
    setAllSlots,
    setStartDate,
    publicEntityCode,
    reservationUsecase,
    settingsLocationUsecase,
    reservationSlotUsecase,
    settingsRereservationableTimeUsecase,
    settingsCounterUniqueClosingDaysUsecase,
  ]);

  // 最小大表示日の更新
  useEffect(() => {
    setActiveStartDate(reservationSlotUsecase.minReserveDay());
  }, [slots, setActiveStartDate, reservationSlotUsecase]);

  const isPageLoading = useCallback(() => {
    return (
      todayReserveCount == null ||
      allSlots == null ||
      slots == null ||
      location == null ||
      startDate < 0
    );
  }, [todayReserveCount, allSlots, slots, location, startDate]);

  return (
    <>
      <Stack className="mt-4" justifyContent="center" alignItems="center" spacing={10}>
        <Stack
          className="w-full"
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={26}
        >
          <Stack justifyContent="center" alignItems="center" spacing={1}>
            <div>本日の予約数</div>
            <div className={style.box}>
              <WaitAMoment isLoading={todayReserveCount == null}>
                <div className={`text-4xl ${style.reserve}`}>{todayReserveCount}</div>
              </WaitAMoment>
            </div>
          </Stack>
          <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
            <div>本日の残り空枠</div>
            <div className={style.box}>
              <WaitAMoment isLoading={allSlots == null}>
                <div className={`text-4xl ${style.empty}`}>
                  {getEmptyReservationTimes(new Date(), allSlots ?? []).length}
                </div>
              </WaitAMoment>
            </div>
          </Stack>
        </Stack>

        <Stack className="w-full" justifyContent="center" alignItems="center" spacing={4}>
          <WaitAMoment isLoading={isPageLoading()}>
            <Stack className="w-3/5 max-w-sm min-w-fit" spacing={1}>
              <InputLabel>来庁場所</InputLabel>
              <Select value={location ?? ''} onChange={handleLocationChange}>
                {locations.map((location, index) => (
                  <MenuItem key={index} value={location}>
                    {location}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </WaitAMoment>
          <Stack justifyContent="center" alignItems="center" spacing={2}>
            <WaitAMoment isLoading={isPageLoading()}>
              <Stack justifyContent="center" alignItems="center">
                {loginStaff?.permission === 'administrator' &&
                  'カレンダー内の日付をクリックすると代理予約を開始します。'}
              </Stack>
              <Stack justifyContent="center" alignItems="center">
                ※グレー表示の日は、開庁しているが原則予約不可としている日です。
              </Stack>
              <div>
                <Calendar
                  locale="jp"
                  calendarType="gregory"
                  onChange={onChange}
                  prev2Label={null}
                  next2Label={null}
                  minDetail="month"
                  defaultActiveStartDate={activeStartDate}
                  minDate={activeStartDate}
                  value={selectedDay}
                  formatDay={(locale, date) => date.getDate().toString()}
                  tileContent={({ date, view }) => {
                    if (view !== 'month') {
                      return;
                    }
                    const times = getEmptyReservationTimes(date, slots ?? []);
                    if (times.length <= 0) {
                      return <p>×</p>;
                    }

                    return (
                      <Tooltip title={`${times.join(' ')}`}>
                        <p
                          className={`${isDeprecationDate(date) && 'text-gray-300'}`}
                        >{`残${times.length}件`}</p>
                      </Tooltip>
                    );
                  }}
                  tileDisabled={({ date, view }) =>
                    view === 'month' && !getEmptyReservationTimes(date, slots ?? []).length
                  }
                />
              </div>
            </WaitAMoment>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default DashboardPage;
